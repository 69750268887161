import React from 'react';
import TwoAppoinment from '../Appoinment/TwoAppoinment';
import ClickToScrollTop from '../../../components/ClickToScrollTop';

const Home = () => {
    return (
        <div>
            <TwoAppoinment />
            <ClickToScrollTop />
        </div>
    );
};

export default Home;