import React from 'react'
import addNew from '../assets/icon/add_new.png'
import { engToBanPage } from '../utils/helpers'

const Header = ({ allApplicationCount }) => {
    return (
        <div className="sm:flex sm:items-center sm:justify-between mx-5">
            <div>
                <div className="flex items-center gap-x-3">
                    <h2 className="text-lg font-medium text-gray-800 ">মোট আবেদন</h2>

                    <span className=" flex items-center w-full px-4 py-2 text-sm font-medium tracking-wide text-blue-600  transition-colors duration-300 transform bg-blue-100 rounded-md sm:w-auto border border-blue-300"> {engToBanPage(allApplicationCount)} টি</span>
                </div>

            </div>

            <div className="flex items-center mt-4 gap-x-3 ">
                <a className="flex items-center w-full px-4 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-300 transform bg-blue-600 rounded-md sm:w-auto sm:mt-0 hover:bg-blue-700 "
                    href='https://program.ahmadullah.info/'
                    target={'_blank'}
                >
                    <img
                        src={addNew}
                        className='h-5 w-5 mr-3'
                    />

                    <span className='text-sm'>আবেদন ফরম</span>
                </a>
            </div>
        </div>
    )
}

export default Header