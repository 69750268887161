import React, { useState } from 'react';
import logo from '../assets/ahmadullah.png';
import { HiDotsHorizontal } from "react-icons/hi";
import { IoIosSend } from "react-icons/io";
import { FaFacebookF } from "react-icons/fa";
import { AiFillYoutube } from "react-icons/ai";
import { FiMenu } from "react-icons/fi";
import { BsTwitter } from "react-icons/bs";
import { AiFillCaretDown } from "react-icons/ai";
import { ImSearch } from "react-icons/im";
import { useLocation } from 'react-router-dom';
import { HiOutlineXMark } from "react-icons/hi2";
import './Navbar.css';


const NewNavbar = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [isIcon, setIcon] = useState(false)

    const navigation = [
        { name: 'হোম', href: 'https://ahmadullah.info/', current: true },
        { name: 'পরিচিতি', href: 'https://ahmadullah.info/biography', current: false },
        { name: 'ভিডিও', href: 'https://ahmadullah.info/video', current: false },
        { name: 'ব্লগ', href: 'https://ahmadullah.info/blog', current: false },
        { name: 'পারিবারিক পরামর্শ', href: 'https://ahmadullah.info/family-counseling', current: false },
        {
            name: 'প্রোগ্রাম', current: false,
            item: [
                { name: 'আসন্ন প্রোগ্রাম', href: 'https://ahmadullah.info/upcoming-program', current: false },
                { name: 'যেসব প্রোগ্রাম সম্পন্ন হয়েছে', href: 'https://ahmadullah.info/completed-upcoming-program', current: false },
                { name: 'হালাকাহর আবেদন', href: 'https://program.ahmadullah.info/', current: false }
            ]
        },
        { name: 'বইসমূহ', href: 'https://ahmadullah.info/books', current: false },
        { name: 'উক্তি', href: 'https://ahmadullah.info/quotes', current: false },
        { name: 'সংবাদ', href: 'https://ahmadullah.info/news', current: false },
    ]

    const [isProgramExpanded, setIsProgramExpanded] = useState(false);

    const handleProgramClick = () => {
        setIsProgramExpanded(!isProgramExpanded);
    };

    const location = useLocation();
    const isSigninRoute = location.pathname === '/signin';

    return (
        <header className={`pattern-1 header-3 smallMen ${isSigninRoute && 'hidden'}`}>
            <div className="max-w-[1320px] px-3 -py-2 md:py-3 lg:py-0  xl:px-20 2xl:px-0 mx-auto relative">
                <div className="lg:flex flex-col xl:flex-row lg:items-start justify-between xl:justify-between relative h-auto w-full ">
                    <div className="flex items-center justify-between ">

                        {/* btn for menu */}
                        <div className="flex lg:hidden">
                            <button type="button" className="text-gray-500  hover:text-gray-600 focus:outline-none focus:text-gray-600 " aria-label="toggle menu"
                                onClick={() => setIsOpen(!isOpen)}
                            >

                                {
                                    isOpen ?
                                        <HiOutlineXMark className='text-3xl !text-white ic' />
                                        :
                                        <FiMenu className='text-2xl text-white' />
                                }


                            </button>
                        </div>
                        <a href="https://ahmadullah.info/" target={'_blank'}>
                            <img
                                className="hidden h-20 lg:h-[151px] w-auto lg:block z-50"
                                src={logo}
                                alt=""
                            />
                            <img
                                className=" h-[85px] md:h-40  w-auto lg:hidden"
                                src={logo}
                                alt=""
                            />
                        </a>

                        <HiDotsHorizontal
                            onClick={() => setIcon(!isIcon)}
                            className='text-2xl md:text-4xl text-white lg:hidden'
                        />

                    </div>

                    <div className={` overflow-hidden mt-1 flex items-center justify-center gap-6 transition-all lg:hidden duration-500 ease-in-out transform ${isIcon ? 'h-9' : 'h-0'}`}>
                        <div className={`flex items-center gap-6 text-white `}>
                            <a href='https://www.facebook.com/sheikhahmadullahofficial/' target='_blank'>
                                <FaFacebookF
                                    className='bg-white p-[2px] text-gray-700 text-lg hover:bg-lime-600 transition-all duration-500 ease-in-out'
                                />
                            </a>
                            <a href='https://www.youtube.com/@sheikhahmadullahofficial' target='_blank'>
                                <AiFillYoutube
                                    className='p-1 text- text-3xl hover:text-lime-600 transition-all duration-500 ease-in-out'
                                />
                            </a>
                            <a href='#' target='_blank'>
                                <BsTwitter
                                    className='text-xl text-white hover:text-lime-600 transition-all duration-500 ease-in-out'
                                />
                            </a>

                        </div>

                        <ImSearch
                            className='text-lg text-white font-semibold'
                        />

                    </div>



                    <div className={`absolute z-20 flex-1 px-6 py-0 xl:pt-9 transition-all duration-500 ease-in-out bg-white  lg:mt-0 justify-end md:pt-0 lg:top-0 lg:relative lg:bg-transparent lg:w-auto lg:opacity-100 lg:translate-x-0 lg:flex lg:items-center ${isOpen ? 'translate-x0 opacity--100 top-[85px] h-auto left-0 right-0' : 'opacity-0 -translate-x--full h-0 left-0 right-0 lg:top-3 top-[95px]'}`}>

                        <div className="flex flex-col -mx-6 lg:hidden lg:items-center lg:mx-8">
                            {navigation.map((item, i) => (
                                <div key={i} className="relative  ">
                                    <a
                                        href={item.href}
                                        target={'_blank'}
                                        className={`py-2 md:px-3 md:py-2 md:mx-3 md:mt-2 text-gray-700 duration-300 transform lg:mt-0 lg:hover:bg-gray-100 font-semibold text-center border-b lg:border-none flex items-center justify-center ${item.name === 'প্রোগ্রাম' && isProgramExpanded
                                            ? 'submenu_color'
                                            : ''
                                            }`}
                                        onClick={(e) => {
                                            if (item.name === 'প্রোগ্রাম') {
                                                handleProgramClick();
                                            }
                                        }}
                                    >
                                        <div className="flex items-center">
                                            <span>{item.name}</span>
                                            {item.item && item.item.length > 0 && (
                                                <AiFillCaretDown className="text-md ml-1 text-gray-600" />
                                            )}
                                        </div>
                                    </a>

                                    {item.name === 'প্রোগ্রাম' && (
                                        <div
                                            className={`bg-white transition-all duration-500 ease-in-out pl-5 ${isProgramExpanded ? 'h-[125px]' : 'h-0'} overflow-hidden`}
                                        >
                                            {item.item.map((subItem, j) => (
                                                <a
                                                    key={j}
                                                    href={subItem.href}
                                                    className={`py-2 pl-5 md:px-3 md:py-2 md:mx-3 md:mt-2 text-gray-700 duration-300 transform lg:mt-0 lg:hover:bg-gray-100 font-semibold text-center border-b lg:border-none flex items-center justify-start ${j === 2 ? 'submenu_color' : ''}`}
                                                >
                                                    {subItem.name}
                                                </a>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ))}
                            <a
                                href="https://ahmadullah.info/contact-us"
                                target="_blank"
                                className="py-2 md:px-3 md:py-2 md:mx-3 md:mt-2 text-gray-700 duration-300 transform lg:mt-0 lg:hover:bg-gray-100 font-semibold text-center  lg:border-none flex items-center justify-center"
                            >
                                <IoIosSend

                                    className='mr-2' />
                                যোগাযোগ
                            </a>
                        </div>



                        <div className='flex flex-col xl:mt-12'>
                            <div className='h-7 mb-1 mr-8 flex items-center justify-between hidden xl:flex'>
                                <div className='flex items-center gap-5 text-white '>
                                    <a href='https://www.facebook.com/sheikhahmadullahofficial/' target='_blank'>
                                        <FaFacebookF
                                            className='bg-white p-[2px] text-gray-700 text-lg hover:bg-lime-600 transition-all duration-500 ease-in-out'
                                        />
                                    </a>
                                    <a href='https://www.youtube.com/@sheikhahmadullahofficial' target='_blank'>
                                        <AiFillYoutube
                                            className='p-1 text- text-3xl hover:text-lime-600 transition-all duration-500 ease-in-out'
                                        />
                                    </a>
                                    <a href='#'>
                                        <BsTwitter
                                            className='text-lg text-white hover:text-lime-600 transition-all duration-500 ease-in-out'
                                        />
                                    </a>
                                </div>

                                <ImSearch
                                    className='text-lg text-white font-semibold'
                                />

                            </div>

                            <div className="flex-row hidden -mx-6 lg:flex nav_item lg:items-center mr-4 rounded-lg justify-between">
                                <div className='flex items-center gap-3 px-3 flex-1'>
                                    {navigation.map((item, i) => (
                                        <a className="relative main-item" href={item.href} key={i}>
                                            <a className='cursor-pointer iitem'>
                                                <a
                                                    to={item.href}
                                                    target={'_blank'}
                                                    className={` text-gray-700 duration-300 transform lg:mt-0  text-center text-[21px] font-medium text-white flex items-center ${i === 5 && 'py'}`}
                                                >
                                                    {item.name}
                                                    {item.item && item.item.length > 0 && (
                                                        <AiFillCaretDown className="text-xl text-white" />
                                                    )}
                                                </a>
                                                <div class="navbar-item-border "></div>
                                            </a>
                                            {item.item && item.item.length > 0 && (
                                                <div className=" submenu left-0 mt   bg-white w-56 shadow-3xl   transition-opacity duration-300">
                                                    {item.item.map((subItem, j) => (
                                                        <a
                                                            href={subItem.href}
                                                            key={j}
                                                            target={'_blank'}
                                                            className={`block pl-4 hover:pl-5 transition-all transform duration-500 ease-in-out py-3  text-gray-700 hover:text-white text-sm drop_item  ${j === 0 && 'border-b '}`}
                                                        >
                                                            {subItem.name}
                                                        </a>
                                                    ))}
                                                </div>
                                            )}

                                        </a>
                                    ))}
                                </div>

                                <div className="min-w-50px] lg:min-w-[80px] xl:min-w-[100px] 2xl:min-w-[251px]"></div>

                                <a href='https://ahmadullah.info/contact-us' target={'_blank'} className="flex items-center text-xl font-medium text-lime-500 bg-white py-4  px-3 m-[3px] rounded-md contact transition-all duration-500 hover:bg-green-900 ease-in-out cursor-pointer contact-btn hover:text-white">
                                    <IoIosSend />
                                    যোগাযোগ
                                </a>
                            </div>

                        </div>

                        <div className="flex items-center lg:mt-0">
                            <button className="hidden mx-4 text-gray-600 transition-colors duration-300 transform  hover:text-gray-700  focus:text-gray-700 focus:outline-none" aria-label="show notifications">
                                <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15 17H20L18.5951 15.5951C18.2141 15.2141 18 14.6973 18 14.1585V11C18 8.38757 16.3304 6.16509 14 5.34142V5C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5V5.34142C7.66962 6.16509 6 8.38757 6 11V14.1585C6 14.6973 5.78595 15.2141 5.40493 15.5951L4 17H9M15 17V18C15 19.6569 13.6569 21 12 21C10.3431 21 9 19.6569 9 18V17M15 17H9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default NewNavbar;