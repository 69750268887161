import { clearLocalStorage } from '../../utils/helpers';
import api from '../api';
import {
    SIGN_IN_REQIEST,
    SIGN_IN_SUCCESS,
    SIGN_IN_FAIL,
    SIGN_OUT_REQIEST,
    SIGN_OUT_SUCCESS,
    SIGN_OUT_FAIL,
    CURRENT_USER_REQIEST,
    CURRENT_USER_SUCCESS,
    CURRENT_USER_FAIL,
    CLEAR_ERROR,
} from '../constants';

export const singIn = (payload) => async dispatch => {
    try {
        dispatch({ type: SIGN_IN_REQIEST })
        const { data } = await api.post(`/user/login`, payload)
        dispatch({ type: SIGN_IN_SUCCESS, payload: data })
        clearLocalStorage();
    } catch (err) {
        console.log('err is: ', err);
        dispatch({ type: SIGN_IN_FAIL, payload: { message: err?.response?.data?.message } })
    }
}

export const singOut = (payload) => async dispatch => {
    try {
        dispatch({ type: SIGN_OUT_REQIEST })
        dispatch({ type: SIGN_OUT_SUCCESS })
    } catch (err) {
        console.log('err is: ', err);
        dispatch({ type: SIGN_OUT_FAIL })
    }
}

export const getCurrentUser = () => async dispatch => {
    try {
        dispatch({ type: CURRENT_USER_REQIEST })
        const { data } = await api.get('/user/current')
        dispatch({ type: CURRENT_USER_SUCCESS, payload: data })
    } catch (err) {
        console.log('err is: ', err);
        if (err?.response?.data?.status == 403) {
            localStorage.removeItem('accessToken')
        }
        dispatch({ type: CURRENT_USER_FAIL, payload: err?.response?.data })
    }
}

export const clearError = () => async dispatch => {
    try {
        dispatch({ type: CLEAR_ERROR })
    } catch (err) {
        console.log('err is: ', err);
    }
}