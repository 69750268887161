import axios from 'axios';
import toast from 'react-hot-toast';

const api = axios.create({
    baseURL: `${process.env.REACT_APP_SERVER_URL}/api/v1`,
    headers: {
        'Content-Type': 'application/json',
    },
});

api.interceptors.request.use(
    config => {
        // Get your authorization token from wherever you store it
        const accessToken = localStorage.getItem('accessToken');

        // Add the authorization header to the request configuration
        if (accessToken)
            config.headers['Authorization'] = accessToken;

        return config;
    },
    error => {
        // Do something with the request error
        console.log('apierror:', error);
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    response => {
        // Get your authorization token from wherever you store it
        // const accessToken = localStorage.getItem('accessToken');

        // // Add the authorization header to the request configuration
        // if (accessToken)
        //     config.headers['Authorization'] = accessToken;

        // return config;
        return response
    },
    error => {
        const errMessage = error?.response?.data?.message || error?.response?.data?.error || error?.message
        // Do something with the request error
        toast.error(errMessage);
        console.log('apierror:', error);
        return Promise.reject(error);
    }
);

export default api;