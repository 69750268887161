export const applicationType = {
    all: "all",
    accepted: "accepted",
    rejected: "rejected",
    next: "next",
    completed: "completed",
    trashed: "trashed",
    trashBack: "trashBack",
};

export const applicationStatus = {
    all: '0',
    accepted: '1',
    rejected: '2',
    next: '3',
    completed: '4',
    trashed: '5',
};

export const participantType = {
    male: "শুধুমাত্র পুরুষ",
    female: "শুধুমাত্র মহিলা",
    both: "পুরুষ মহিলা উভয়"
}

export const femaleCondition = {
    yes: "হ্যাঁ",
    no: "না"
}

export const banglaPageNo = {
    1: "১",
    2: "২",
    3: "৩",
    4: "৪",
    5: "৫",
    6: "৬",
    7: "৭",
    8: "৮",
    9: "৯",
    0: "০",
};

export const version = "0.0.4";

export const months = [
    "জানুয়ারী",
    "ফেব্রুয়ারি",
    "মার্চ",
    "এপ্রিল",
    "মে",
    "জুন",
    "জুলাই",
    "আগস্ট",
    "সেপ্টেম্বর",
    "অক্টোবর",
    "নভেম্বর",
    "ডিসেম্বর",
];
export const englishMonths = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];
