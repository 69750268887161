export const APPLICATION_SUMMARY_REQIEST = 'APPLICATION_SUMMARY_REQIEST'
export const APPLICATION_SUMMARY_SUCCESS = 'APPLICATION_SUMMARY_SUCCESS'
export const APPLICATION_SUMMARY_FAIL = 'APPLICATION_SUMMARY_FAIL'

export const ALL_APPLICATIONS_REQIEST = 'ALL_APPLICATIONS_REQIEST'
export const ALL_APPLICATIONS_SUCCESS = 'ALL_APPLICATIONS_SUCCESS'
export const ALL_APPLICATIONS_FAIL = 'ALL_APPLICATIONS_FAIL'

export const ACCEPTED_APPLICATIONS_REQIEST = 'ACCEPTED_APPLICATIONS_REQIEST'
export const ACCEPTED_APPLICATIONS_SUCCESS = 'ACCEPTED_APPLICATIONS_SUCCESS'
export const ACCEPTED_APPLICATIONS_FAIL = 'ACCEPTED_APPLICATIONS_FAIL'

export const REJECTED_APPLICATIONS_REQIEST = 'REJECTED_APPLICATIONS_REQIEST'
export const REJECTED_APPLICATIONS_SUCCESS = 'REJECTED_APPLICATIONS_SUCCESS'
export const REJECTED_APPLICATIONS_FAIL = 'REJECTED_APPLICATIONS_FAIL'

export const NEXT_APPLICATIONS_REQIEST = 'NEXT_APPLICATIONS_REQIEST'
export const NEXT_APPLICATIONS_SUCCESS = 'NEXT_APPLICATIONS_SUCCESS'
export const NEXT_APPLICATIONS_FAIL = 'NEXT_APPLICATIONS_FAIL'

export const COMPLETED_APPLICATIONS_REQIEST = 'COMPLETED_APPLICATIONS_REQIEST'
export const COMPLETED_APPLICATIONS_SUCCESS = 'COMPLETED_APPLICATIONS_SUCCESS'
export const COMPLETED_APPLICATIONS_FAIL = 'COMPLETED_APPLICATIONS_FAIL'

export const TRASHED_APPLICATIONS_REQIEST = 'TRASHED_APPLICATIONS_REQIEST'
export const TRASHED_APPLICATIONS_SUCCESS = 'TRASHED_APPLICATIONS_SUCCESS'
export const TRASHED_APPLICATIONS_FAIL = 'TRASHED_APPLICATIONS_FAIL'

export const SINGLE_APPLICATIONS_REQIEST = 'SINGLE_APPLICATIONS_REQIEST'
export const SINGLE_APPLICATIONS_SUCCESS = 'SINGLE_APPLICATIONS_SUCCESS'
export const SINGLE_APPLICATIONS_FAIL = 'SINGLE_APPLICATIONS_FAIL'

export const SIGN_IN_REQIEST = 'SIGN_IN_REQIEST'
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS'
export const SIGN_IN_FAIL = 'SIGN_IN_FAIL'

export const SIGN_UP_REQIEST = 'SIGN_UP_REQIEST'
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS'
export const SIGN_UP_FAIL = 'SIGN_UP_FAIL'

export const SIGN_OUT_REQIEST = 'SIGN_OUT_REQIEST'
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS'
export const SIGN_OUT_FAIL = 'SIGN_OUT_FAIL'

export const CURRENT_USER_REQIEST = 'CURRENT_USER_REQIEST'
export const CURRENT_USER_SUCCESS = 'CURRENT_USER_SUCCESS'
export const CURRENT_USER_FAIL = 'CURRENT_USER_FAIL'

export const ACCEPT_APPLICATION_REQIEST = 'ACCEPT_APPLICATION_REQIEST'
export const ACCEPT_APPLICATION_SUCCESS = 'ACCEPT_APPLICATION_SUCCESS'
export const ACCEPT_APPLICATION_FAIL = 'ACCEPT_APPLICATION_FAIL'

export const REJECT_APPLICATION_REQIEST = 'REJECT_APPLICATION_REQIEST'
export const REJECT_APPLICATION_SUCCESS = 'REJECT_APPLICATION_SUCCESS'
export const REJECT_APPLICATION_FAIL = 'REJECT_APPLICATION_FAIL'

export const UPDATE_APPLICATION_REQIEST = 'UPDATE_APPLICATION_REQIEST'
export const UPDATE_APPLICATION_SUCCESS = 'UPDATE_APPLICATION_SUCCESS'
export const UPDATE_APPLICATION_FAIL = 'UPDATE_APPLICATION_FAIL'

export const TRASH_APPLICATION_REQIEST = 'TRASH_APPLICATION_REQIEST'
export const TRASH_APPLICATION_SUCCESS = 'TRASH_APPLICATION_SUCCESS'
export const TRASH_APPLICATION_FAIL = 'TRASH_APPLICATION_FAIL'

export const TRASH_BACK_APPLICATION_REQIEST = 'TRASH_BACK_APPLICATION_REQIEST'
export const TRASH_BACK_APPLICATION_SUCCESS = 'TRASH_BACK_APPLICATION_SUCCESS'
export const TRASH_BACK_APPLICATION_FAIL = 'TRASH_BACK_APPLICATION_FAIL'

export const DELETE_APPLICATION_REQIEST = 'DELETE_APPLICATION_REQIEST'
export const DELETE_APPLICATION_SUCCESS = 'DELETE_APPLICATION_SUCCESS'
export const DELETE_APPLICATION_FAIL = 'DELETE_APPLICATION_FAIL'

export const SET_SELECTED_APPLICATION = 'SET_SELECTED_APPLICATION'
export const UNSET_SELECTED_APPLICATION = 'UNSET_SELECTED_APPLICATION'

export const SET_LOADER = 'SET_LOADER'
export const CLEAR_LOADER = 'CLEAR_LOADER'

export const SET_LOADER_FUNCTION = 'SET_LOADER_FUNCTION'
export const CLEAR_LOADER_FUNCTION = 'CLEAR_LOADER_FUNCTION'

export const CLEAR_ERROR = 'CLEAR_ERROR'