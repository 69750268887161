const subDistricts = {
    "1": {
        "id": "1",
        "district_id": "34",
        "name": "Amtali",
        "bn_name": "আমতলী"
    },
    "2": {
        "id": "2",
        "district_id": "34",
        "name": "Bamna",
        "bn_name": "বামনা"
    },
    "3": {
        "id": "3",
        "district_id": "34",
        "name": "Barguna Sadar",
        "bn_name": "বরগুনা সদর"
    },
    "4": {
        "id": "4",
        "district_id": "34",
        "name": "Betagi",
        "bn_name": "বেতাগি"
    },
    "5": {
        "id": "5",
        "district_id": "34",
        "name": "Patharghata",
        "bn_name": "পাথরঘাটা"
    },
    "6": {
        "id": "6",
        "district_id": "34",
        "name": "Taltali",
        "bn_name": "তালতলী"
    },
    "7": {
        "id": "7",
        "district_id": "35",
        "name": "Muladi",
        "bn_name": "মুলাদি"
    },
    "8": {
        "id": "8",
        "district_id": "35",
        "name": "Babuganj",
        "bn_name": "বাবুগঞ্জ"
    },
    "9": {
        "id": "9",
        "district_id": "35",
        "name": "Agailjhara",
        "bn_name": "আগাইলঝরা"
    },
    "10": {
        "id": "10",
        "district_id": "35",
        "name": "Barisal Sadar",
        "bn_name": "বরিশাল সদর"
    },
    "11": {
        "id": "11",
        "district_id": "35",
        "name": "Bakerganj",
        "bn_name": "বাকেরগঞ্জ"
    },
    "12": {
        "id": "12",
        "district_id": "35",
        "name": "Banaripara",
        "bn_name": "বানাড়িপারা"
    },
    "13": {
        "id": "13",
        "district_id": "35",
        "name": "Gaurnadi",
        "bn_name": "গৌরনদী"
    },
    "14": {
        "id": "14",
        "district_id": "35",
        "name": "Hizla",
        "bn_name": "হিজলা"
    },
    "15": {
        "id": "15",
        "district_id": "35",
        "name": "Mehendiganj",
        "bn_name": "মেহেদিগঞ্জ "
    },
    "16": {
        "id": "16",
        "district_id": "35",
        "name": "Wazirpur",
        "bn_name": "ওয়াজিরপুর"
    },
    "17": {
        "id": "17",
        "district_id": "36",
        "name": "Bhola Sadar",
        "bn_name": "ভোলা সদর"
    },
    "18": {
        "id": "18",
        "district_id": "36",
        "name": "Burhanuddin",
        "bn_name": "বুরহানউদ্দিন"
    },
    "19": {
        "id": "19",
        "district_id": "36",
        "name": "Char Fasson",
        "bn_name": "চর ফ্যাশন"
    },
    "20": {
        "id": "20",
        "district_id": "36",
        "name": "Daulatkhan",
        "bn_name": "দৌলতখান"
    },
    "21": {
        "id": "21",
        "district_id": "36",
        "name": "Lalmohan",
        "bn_name": "লালমোহন"
    },
    "22": {
        "id": "22",
        "district_id": "36",
        "name": "Manpura",
        "bn_name": "মনপুরা"
    },
    "23": {
        "id": "23",
        "district_id": "36",
        "name": "Tazumuddin",
        "bn_name": "তাজুমুদ্দিন"
    },
    "24": {
        "id": "24",
        "district_id": "37",
        "name": "Jhalokati Sadar",
        "bn_name": "ঝালকাঠি সদর"
    },
    "25": {
        "id": "25",
        "district_id": "37",
        "name": "Kathalia",
        "bn_name": "কাঁঠালিয়া"
    },
    "26": {
        "id": "26",
        "district_id": "37",
        "name": "Nalchity",
        "bn_name": "নালচিতি"
    },
    "27": {
        "id": "27",
        "district_id": "37",
        "name": "Rajapur",
        "bn_name": "রাজাপুর"
    },
    "28": {
        "id": "28",
        "district_id": "38",
        "name": "Bauphal",
        "bn_name": "বাউফল"
    },
    "29": {
        "id": "29",
        "district_id": "38",
        "name": "Dashmina",
        "bn_name": "দশমিনা"
    },
    "30": {
        "id": "30",
        "district_id": "38",
        "name": "Galachipa",
        "bn_name": "গলাচিপা"
    },
    "31": {
        "id": "31",
        "district_id": "38",
        "name": "Kalapara",
        "bn_name": "কালাপারা"
    },
    "32": {
        "id": "32",
        "district_id": "38",
        "name": "Mirzaganj",
        "bn_name": "মির্জাগঞ্জ "
    },
    "33": {
        "id": "33",
        "district_id": "38",
        "name": "Patuakhali Sadar",
        "bn_name": "পটুয়াখালী সদর"
    },
    "34": {
        "id": "34",
        "district_id": "38",
        "name": "Dumki",
        "bn_name": "ডুমকি"
    },
    "35": {
        "id": "35",
        "district_id": "38",
        "name": "Rangabali",
        "bn_name": "রাঙ্গাবালি"
    },
    "36": {
        "id": "36",
        "district_id": "39",
        "name": "Bhandaria",
        "bn_name": "ভ্যান্ডারিয়া"
    },
    "37": {
        "id": "37",
        "district_id": "39",
        "name": "Kaukhali",
        "bn_name": "কাউখালি"
    },
    "38": {
        "id": "38",
        "district_id": "39",
        "name": "Mathbaria",
        "bn_name": "মাঠবাড়িয়া"
    },
    "39": {
        "id": "39",
        "district_id": "39",
        "name": "Nazirpur",
        "bn_name": "নাজিরপুর"
    },
    "40": {
        "id": "40",
        "district_id": "39",
        "name": "Nesarabad",
        "bn_name": "নেসারাবাদ"
    },
    "41": {
        "id": "41",
        "district_id": "39",
        "name": "Pirojpur Sadar",
        "bn_name": "পিরোজপুর সদর"
    },
    "42": {
        "id": "42",
        "district_id": "39",
        "name": "Zianagar",
        "bn_name": "জিয়ানগর"
    },
    "43": {
        "id": "43",
        "district_id": "40",
        "name": "Bandarban Sadar",
        "bn_name": "বান্দরবন সদর"
    },
    "44": {
        "id": "44",
        "district_id": "40",
        "name": "Thanchi",
        "bn_name": "থানচি"
    },
    "45": {
        "id": "45",
        "district_id": "40",
        "name": "Lama",
        "bn_name": "লামা"
    },
    "46": {
        "id": "46",
        "district_id": "40",
        "name": "Naikhongchhari",
        "bn_name": "নাইখংছড়ি "
    },
    "47": {
        "id": "47",
        "district_id": "40",
        "name": "Ali kadam",
        "bn_name": "আলী কদম"
    },
    "48": {
        "id": "48",
        "district_id": "40",
        "name": "Rowangchhari",
        "bn_name": "রউয়াংছড়ি "
    },
    "49": {
        "id": "49",
        "district_id": "40",
        "name": "Ruma",
        "bn_name": "রুমা"
    },
    "50": {
        "id": "50",
        "district_id": "41",
        "name": "Brahmanbaria Sadar",
        "bn_name": "ব্রাহ্মণবাড়িয়া সদর"
    },
    "51": {
        "id": "51",
        "district_id": "41",
        "name": "Ashuganj",
        "bn_name": "আশুগঞ্জ"
    },
    "52": {
        "id": "52",
        "district_id": "41",
        "name": "Nasirnagar",
        "bn_name": "নাসির নগর"
    },
    "53": {
        "id": "53",
        "district_id": "41",
        "name": "Nabinagar",
        "bn_name": "নবীনগর"
    },
    "54": {
        "id": "54",
        "district_id": "41",
        "name": "Sarail",
        "bn_name": "সরাইল"
    },
    "55": {
        "id": "55",
        "district_id": "41",
        "name": "Shahbazpur Town",
        "bn_name": "শাহবাজপুর টাউন"
    },
    "56": {
        "id": "56",
        "district_id": "41",
        "name": "Kasba",
        "bn_name": "কসবা"
    },
    "57": {
        "id": "57",
        "district_id": "41",
        "name": "Akhaura",
        "bn_name": "আখাউরা"
    },
    "58": {
        "id": "58",
        "district_id": "41",
        "name": "Bancharampur",
        "bn_name": "বাঞ্ছারামপুর"
    },
    "59": {
        "id": "59",
        "district_id": "41",
        "name": "Bijoynagar",
        "bn_name": "বিজয় নগর"
    },
    "60": {
        "id": "60",
        "district_id": "42",
        "name": "Chandpur Sadar",
        "bn_name": "চাঁদপুর সদর"
    },
    "61": {
        "id": "61",
        "district_id": "42",
        "name": "Faridganj",
        "bn_name": "ফরিদগঞ্জ"
    },
    "62": {
        "id": "62",
        "district_id": "42",
        "name": "Haimchar",
        "bn_name": "হাইমচর"
    },
    "63": {
        "id": "63",
        "district_id": "42",
        "name": "Haziganj",
        "bn_name": "হাজীগঞ্জ"
    },
    "64": {
        "id": "64",
        "district_id": "42",
        "name": "Kachua",
        "bn_name": "কচুয়া"
    },
    "65": {
        "id": "65",
        "district_id": "42",
        "name": "Matlab Uttar",
        "bn_name": "মতলব উত্তর"
    },
    "66": {
        "id": "66",
        "district_id": "42",
        "name": "Matlab Dakkhin",
        "bn_name": "মতলব দক্ষিণ"
    },
    "67": {
        "id": "67",
        "district_id": "42",
        "name": "Shahrasti",
        "bn_name": "শাহরাস্তি"
    },
    "68": {
        "id": "68",
        "district_id": "43",
        "name": "Anwara",
        "bn_name": "আনোয়ারা"
    },
    "69": {
        "id": "69",
        "district_id": "43",
        "name": "Banshkhali",
        "bn_name": "বাশখালি"
    },
    "70": {
        "id": "70",
        "district_id": "43",
        "name": "Boalkhali",
        "bn_name": "বোয়ালখালি"
    },
    "71": {
        "id": "71",
        "district_id": "43",
        "name": "Chandanaish",
        "bn_name": "চন্দনাইশ"
    },
    "72": {
        "id": "72",
        "district_id": "43",
        "name": "Fatikchhari",
        "bn_name": "ফটিকছড়ি"
    },
    "73": {
        "id": "73",
        "district_id": "43",
        "name": "Hathazari",
        "bn_name": "হাঠহাজারী"
    },
    "74": {
        "id": "74",
        "district_id": "43",
        "name": "Lohagara",
        "bn_name": "লোহাগারা"
    },
    "75": {
        "id": "75",
        "district_id": "43",
        "name": "Mirsharai",
        "bn_name": "মিরসরাই"
    },
    "76": {
        "id": "76",
        "district_id": "43",
        "name": "Patiya",
        "bn_name": "পটিয়া"
    },
    "77": {
        "id": "77",
        "district_id": "43",
        "name": "Rangunia",
        "bn_name": "রাঙ্গুনিয়া"
    },
    "78": {
        "id": "78",
        "district_id": "43",
        "name": "Raozan",
        "bn_name": "রাউজান"
    },
    "79": {
        "id": "79",
        "district_id": "43",
        "name": "Sandwip",
        "bn_name": "সন্দ্বীপ"
    },
    "80": {
        "id": "80",
        "district_id": "43",
        "name": "Satkania",
        "bn_name": "সাতকানিয়া"
    },
    "81": {
        "id": "81",
        "district_id": "43",
        "name": "Sitakunda",
        "bn_name": "সীতাকুণ্ড"
    },
    "82": {
        "id": "82",
        "district_id": "44",
        "name": "Barura",
        "bn_name": "বড়ুরা"
    },
    "83": {
        "id": "83",
        "district_id": "44",
        "name": "Brahmanpara",
        "bn_name": "ব্রাহ্মণপাড়া"
    },
    "84": {
        "id": "84",
        "district_id": "44",
        "name": "Burichong",
        "bn_name": "বুড়িচং"
    },
    "85": {
        "id": "85",
        "district_id": "44",
        "name": "Chandina",
        "bn_name": "চান্দিনা"
    },
    "86": {
        "id": "86",
        "district_id": "44",
        "name": "Chauddagram",
        "bn_name": "চৌদ্দগ্রাম"
    },
    "87": {
        "id": "87",
        "district_id": "44",
        "name": "Daudkandi",
        "bn_name": "দাউদকান্দি"
    },
    "88": {
        "id": "88",
        "district_id": "44",
        "name": "Debidwar",
        "bn_name": "দেবীদ্বার"
    },
    "89": {
        "id": "89",
        "district_id": "44",
        "name": "Homna",
        "bn_name": "হোমনা"
    },
    "90": {
        "id": "90",
        "district_id": "44",
        "name": "Comilla Sadar",
        "bn_name": "কুমিল্লা সদর"
    },
    "91": {
        "id": "91",
        "district_id": "44",
        "name": "Laksam",
        "bn_name": "লাকসাম"
    },
    "92": {
        "id": "92",
        "district_id": "44",
        "name": "Monohorgonj",
        "bn_name": "মনোহরগঞ্জ"
    },
    "93": {
        "id": "93",
        "district_id": "44",
        "name": "Meghna",
        "bn_name": "মেঘনা"
    },
    "94": {
        "id": "94",
        "district_id": "44",
        "name": "Muradnagar",
        "bn_name": "মুরাদনগর"
    },
    "95": {
        "id": "95",
        "district_id": "44",
        "name": "Nangalkot",
        "bn_name": "নাঙ্গালকোট"
    },
    "96": {
        "id": "96",
        "district_id": "44",
        "name": "Sadar South",
        "bn_name": "সদর দক্ষিণ"
    },
    "97": {
        "id": "97",
        "district_id": "44",
        "name": "Titas",
        "bn_name": "তিতাস"
    },
    "98": {
        "id": "98",
        "district_id": "45",
        "name": "Chakaria",
        "bn_name": "চকরিয়া"
    },
    "100": {
        "id": "100",
        "district_id": "45",
        "name": "{{198}}''{{199}}",
        "bn_name": "কক্স বাজার সদর"
    },
    "101": {
        "id": "101",
        "district_id": "45",
        "name": "Kutubdia",
        "bn_name": "কুতুবদিয়া"
    },
    "102": {
        "id": "102",
        "district_id": "45",
        "name": "Maheshkhali",
        "bn_name": "মহেশখালী"
    },
    "103": {
        "id": "103",
        "district_id": "45",
        "name": "Ramu",
        "bn_name": "রামু"
    },
    "104": {
        "id": "104",
        "district_id": "45",
        "name": "Teknaf",
        "bn_name": "টেকনাফ"
    },
    "105": {
        "id": "105",
        "district_id": "45",
        "name": "Ukhia",
        "bn_name": "উখিয়া"
    },
    "106": {
        "id": "106",
        "district_id": "45",
        "name": "Pekua",
        "bn_name": "পেকুয়া"
    },
    "107": {
        "id": "107",
        "district_id": "46",
        "name": "Feni Sadar",
        "bn_name": "ফেনী সদর"
    },
    "108": {
        "id": "108",
        "district_id": "46",
        "name": "Chagalnaiya",
        "bn_name": "ছাগল নাইয়া"
    },
    "109": {
        "id": "109",
        "district_id": "46",
        "name": "Daganbhyan",
        "bn_name": "দাগানভিয়া"
    },
    "110": {
        "id": "110",
        "district_id": "46",
        "name": "Parshuram",
        "bn_name": "পরশুরাম"
    },
    "111": {
        "id": "111",
        "district_id": "46",
        "name": "Fhulgazi",
        "bn_name": "ফুলগাজি"
    },
    "112": {
        "id": "112",
        "district_id": "46",
        "name": "Sonagazi",
        "bn_name": "সোনাগাজি"
    },
    "113": {
        "id": "113",
        "district_id": "47",
        "name": "Dighinala",
        "bn_name": "দিঘিনালা "
    },
    "114": {
        "id": "114",
        "district_id": "47",
        "name": "Khagrachhari",
        "bn_name": "খাগড়াছড়ি"
    },
    "115": {
        "id": "115",
        "district_id": "47",
        "name": "Lakshmichhari",
        "bn_name": "লক্ষ্মীছড়ি"
    },
    "116": {
        "id": "116",
        "district_id": "47",
        "name": "Mahalchhari",
        "bn_name": "মহলছড়ি"
    },
    "117": {
        "id": "117",
        "district_id": "47",
        "name": "Manikchhari",
        "bn_name": "মানিকছড়ি"
    },
    "118": {
        "id": "118",
        "district_id": "47",
        "name": "Matiranga",
        "bn_name": "মাটিরাঙ্গা"
    },
    "119": {
        "id": "119",
        "district_id": "47",
        "name": "Panchhari",
        "bn_name": "পানছড়ি"
    },
    "120": {
        "id": "120",
        "district_id": "47",
        "name": "Ramgarh",
        "bn_name": "রামগড়"
    },
    "121": {
        "id": "121",
        "district_id": "48",
        "name": "Lakshmipur Sadar",
        "bn_name": "লক্ষ্মীপুর সদর"
    },
    "122": {
        "id": "122",
        "district_id": "48",
        "name": "Raipur",
        "bn_name": "রায়পুর"
    },
    "123": {
        "id": "123",
        "district_id": "48",
        "name": "Ramganj",
        "bn_name": "রামগঞ্জ"
    },
    "124": {
        "id": "124",
        "district_id": "48",
        "name": "Ramgati",
        "bn_name": "রামগতি"
    },
    "125": {
        "id": "125",
        "district_id": "48",
        "name": "Komol Nagar",
        "bn_name": "কমল নগর"
    },
    "126": {
        "id": "126",
        "district_id": "49",
        "name": "Noakhali Sadar",
        "bn_name": "নোয়াখালী সদর"
    },
    "127": {
        "id": "127",
        "district_id": "49",
        "name": "Begumganj",
        "bn_name": "বেগমগঞ্জ"
    },
    "128": {
        "id": "128",
        "district_id": "49",
        "name": "Chatkhil",
        "bn_name": "চাটখিল"
    },
    "129": {
        "id": "129",
        "district_id": "49",
        "name": "Companyganj",
        "bn_name": "কোম্পানীগঞ্জ"
    },
    "130": {
        "id": "130",
        "district_id": "49",
        "name": "Shenbag",
        "bn_name": "শেনবাগ"
    },
    "131": {
        "id": "131",
        "district_id": "49",
        "name": "Hatia",
        "bn_name": "হাতিয়া"
    },
    "132": {
        "id": "132",
        "district_id": "49",
        "name": "Kobirhat",
        "bn_name": "কবিরহাট "
    },
    "133": {
        "id": "133",
        "district_id": "49",
        "name": "Sonaimuri",
        "bn_name": "সোনাইমুরি"
    },
    "134": {
        "id": "134",
        "district_id": "49",
        "name": "Suborno Char",
        "bn_name": "সুবর্ণ চর "
    },
    "135": {
        "id": "135",
        "district_id": "50",
        "name": "Rangamati Sadar",
        "bn_name": "রাঙ্গামাটি সদর"
    },
    "136": {
        "id": "136",
        "district_id": "50",
        "name": "Belaichhari",
        "bn_name": "বেলাইছড়ি"
    },
    "137": {
        "id": "137",
        "district_id": "50",
        "name": "Bagaichhari",
        "bn_name": "বাঘাইছড়ি"
    },
    "138": {
        "id": "138",
        "district_id": "50",
        "name": "Barkal",
        "bn_name": "বরকল"
    },
    "139": {
        "id": "139",
        "district_id": "50",
        "name": "Juraichhari",
        "bn_name": "জুরাইছড়ি"
    },
    "140": {
        "id": "140",
        "district_id": "50",
        "name": "Rajasthali",
        "bn_name": "রাজাস্থলি"
    },
    "141": {
        "id": "141",
        "district_id": "50",
        "name": "Kaptai",
        "bn_name": "কাপ্তাই"
    },
    "142": {
        "id": "142",
        "district_id": "50",
        "name": "Langadu",
        "bn_name": "লাঙ্গাডু"
    },
    "143": {
        "id": "143",
        "district_id": "50",
        "name": "Nannerchar",
        "bn_name": "নান্নেরচর "
    },
    "144": {
        "id": "144",
        "district_id": "50",
        "name": "Kaukhali",
        "bn_name": "কাউখালি"
    },
    "145": {
        "id": "145",
        "district_id": "1",
        "name": "Dhamrai",
        "bn_name": "ধামরাই"
    },
    "146": {
        "id": "146",
        "district_id": "1",
        "name": "Dohar",
        "bn_name": "দোহার"
    },
    "147": {
        "id": "147",
        "district_id": "1",
        "name": "Keraniganj",
        "bn_name": "কেরানীগঞ্জ"
    },
    "148": {
        "id": "148",
        "district_id": "1",
        "name": "Nawabganj",
        "bn_name": "নবাবগঞ্জ"
    },
    "149": {
        "id": "149",
        "district_id": "1",
        "name": "Savar",
        "bn_name": "সাভার"
    },
    "150": {
        "id": "150",
        "district_id": "2",
        "name": "Faridpur Sadar",
        "bn_name": "ফরিদপুর সদর"
    },
    "151": {
        "id": "151",
        "district_id": "2",
        "name": "Boalmari",
        "bn_name": "বোয়ালমারী"
    },
    "152": {
        "id": "152",
        "district_id": "2",
        "name": "Alfadanga",
        "bn_name": "আলফাডাঙ্গা"
    },
    "153": {
        "id": "153",
        "district_id": "2",
        "name": "Madhukhali",
        "bn_name": "মধুখালি"
    },
    "154": {
        "id": "154",
        "district_id": "2",
        "name": "Bhanga",
        "bn_name": "ভাঙ্গা"
    },
    "155": {
        "id": "155",
        "district_id": "2",
        "name": "Nagarkanda",
        "bn_name": "নগরকান্ড"
    },
    "156": {
        "id": "156",
        "district_id": "2",
        "name": "Charbhadrasan",
        "bn_name": "চরভদ্রাসন "
    },
    "157": {
        "id": "157",
        "district_id": "2",
        "name": "Sadarpur",
        "bn_name": "সদরপুর"
    },
    "158": {
        "id": "158",
        "district_id": "2",
        "name": "Shaltha",
        "bn_name": "শালথা"
    },
    "159": {
        "id": "159",
        "district_id": "3",
        "name": "Gazipur Sadar-Joydebpur",
        "bn_name": "গাজীপুর সদর"
    },
    "160": {
        "id": "160",
        "district_id": "3",
        "name": "Kaliakior",
        "bn_name": "কালিয়াকৈর"
    },
    "161": {
        "id": "161",
        "district_id": "3",
        "name": "Kapasia",
        "bn_name": "কাপাসিয়া"
    },
    "162": {
        "id": "162",
        "district_id": "3",
        "name": "Sripur",
        "bn_name": "শ্রীপুর"
    },
    "163": {
        "id": "163",
        "district_id": "3",
        "name": "Kaliganj",
        "bn_name": "কালীগঞ্জ"
    },
    "164": {
        "id": "164",
        "district_id": "3",
        "name": "Tongi",
        "bn_name": "টঙ্গি"
    },
    "165": {
        "id": "165",
        "district_id": "4",
        "name": "Gopalganj Sadar",
        "bn_name": "গোপালগঞ্জ সদর"
    },
    "166": {
        "id": "166",
        "district_id": "4",
        "name": "Kashiani",
        "bn_name": "কাশিয়ানি"
    },
    "167": {
        "id": "167",
        "district_id": "4",
        "name": "Kotalipara",
        "bn_name": "কোটালিপাড়া"
    },
    "168": {
        "id": "168",
        "district_id": "4",
        "name": "Muksudpur",
        "bn_name": "মুকসুদপুর"
    },
    "169": {
        "id": "169",
        "district_id": "4",
        "name": "Tungipara",
        "bn_name": "টুঙ্গিপাড়া"
    },
    "170": {
        "id": "170",
        "district_id": "5",
        "name": "Dewanganj",
        "bn_name": "দেওয়ানগঞ্জ"
    },
    "171": {
        "id": "171",
        "district_id": "5",
        "name": "Baksiganj",
        "bn_name": "বকসিগঞ্জ"
    },
    "172": {
        "id": "172",
        "district_id": "5",
        "name": "Islampur",
        "bn_name": "ইসলামপুর"
    },
    "173": {
        "id": "173",
        "district_id": "5",
        "name": "Jamalpur Sadar",
        "bn_name": "জামালপুর সদর"
    },
    "174": {
        "id": "174",
        "district_id": "5",
        "name": "Madarganj",
        "bn_name": "মাদারগঞ্জ"
    },
    "175": {
        "id": "175",
        "district_id": "5",
        "name": "Melandaha",
        "bn_name": "মেলানদাহা"
    },
    "176": {
        "id": "176",
        "district_id": "5",
        "name": "Sarishabari",
        "bn_name": "সরিষাবাড়ি "
    },
    "177": {
        "id": "177",
        "district_id": "5",
        "name": "Narundi Police I.C",
        "bn_name": "নারুন্দি"
    },
    "178": {
        "id": "178",
        "district_id": "6",
        "name": "Astagram",
        "bn_name": "অষ্টগ্রাম"
    },
    "179": {
        "id": "179",
        "district_id": "6",
        "name": "Bajitpur",
        "bn_name": "বাজিতপুর"
    },
    "180": {
        "id": "180",
        "district_id": "6",
        "name": "Bhairab",
        "bn_name": "ভৈরব"
    },
    "181": {
        "id": "181",
        "district_id": "6",
        "name": "Hossainpur",
        "bn_name": "হোসেনপুর "
    },
    "182": {
        "id": "182",
        "district_id": "6",
        "name": "Itna",
        "bn_name": "ইটনা"
    },
    "183": {
        "id": "183",
        "district_id": "6",
        "name": "Karimganj",
        "bn_name": "করিমগঞ্জ"
    },
    "184": {
        "id": "184",
        "district_id": "6",
        "name": "Katiadi",
        "bn_name": "কতিয়াদি"
    },
    "185": {
        "id": "185",
        "district_id": "6",
        "name": "Kishoreganj Sadar",
        "bn_name": "কিশোরগঞ্জ সদর"
    },
    "186": {
        "id": "186",
        "district_id": "6",
        "name": "Kuliarchar",
        "bn_name": "কুলিয়ারচর"
    },
    "187": {
        "id": "187",
        "district_id": "6",
        "name": "Mithamain",
        "bn_name": "মিঠামাইন"
    },
    "188": {
        "id": "188",
        "district_id": "6",
        "name": "Nikli",
        "bn_name": "নিকলি"
    },
    "189": {
        "id": "189",
        "district_id": "6",
        "name": "Pakundia",
        "bn_name": "পাকুন্ডা"
    },
    "190": {
        "id": "190",
        "district_id": "6",
        "name": "Tarail",
        "bn_name": "তাড়াইল"
    },
    "191": {
        "id": "191",
        "district_id": "7",
        "name": "Madaripur Sadar",
        "bn_name": "মাদারীপুর সদর"
    },
    "192": {
        "id": "192",
        "district_id": "7",
        "name": "Kalkini",
        "bn_name": "কালকিনি"
    },
    "193": {
        "id": "193",
        "district_id": "7",
        "name": "Rajoir",
        "bn_name": "রাজইর"
    },
    "194": {
        "id": "194",
        "district_id": "7",
        "name": "Shibchar",
        "bn_name": "শিবচর"
    },
    "195": {
        "id": "195",
        "district_id": "8",
        "name": "Manikganj Sadar",
        "bn_name": "মানিকগঞ্জ সদর"
    },
    "196": {
        "id": "196",
        "district_id": "8",
        "name": "Singair",
        "bn_name": "সিঙ্গাইর"
    },
    "197": {
        "id": "197",
        "district_id": "8",
        "name": "Shibalaya",
        "bn_name": "শিবালয়"
    },
    "198": {
        "id": "198",
        "district_id": "8",
        "name": "Saturia",
        "bn_name": "সাটুরিয়া"
    },
    "199": {
        "id": "199",
        "district_id": "8",
        "name": "Harirampur",
        "bn_name": "হরিরামপুর"
    },
    "200": {
        "id": "200",
        "district_id": "8",
        "name": "Ghior",
        "bn_name": "ঘিওর"
    },
    "201": {
        "id": "201",
        "district_id": "8",
        "name": "Daulatpur",
        "bn_name": "দৌলতপুর"
    },
    "202": {
        "id": "202",
        "district_id": "9",
        "name": "Lohajang",
        "bn_name": "লোহাজং"
    },
    "203": {
        "id": "203",
        "district_id": "9",
        "name": "Sreenagar",
        "bn_name": "শ্রীনগর"
    },
    "204": {
        "id": "204",
        "district_id": "9",
        "name": "Munshiganj Sadar",
        "bn_name": "মুন্সিগঞ্জ সদর"
    },
    "205": {
        "id": "205",
        "district_id": "9",
        "name": "Sirajdikhan",
        "bn_name": "সিরাজদিখান"
    },
    "206": {
        "id": "206",
        "district_id": "9",
        "name": "Tongibari",
        "bn_name": "টঙ্গিবাড়ি"
    },
    "207": {
        "id": "207",
        "district_id": "9",
        "name": "Gazaria",
        "bn_name": "গজারিয়া"
    },
    "208": {
        "id": "208",
        "district_id": "10",
        "name": "Bhaluka",
        "bn_name": "ভালুকা"
    },
    "209": {
        "id": "209",
        "district_id": "10",
        "name": "Trishal",
        "bn_name": "ত্রিশাল"
    },
    "210": {
        "id": "210",
        "district_id": "10",
        "name": "Haluaghat",
        "bn_name": "হালুয়াঘাট"
    },
    "211": {
        "id": "211",
        "district_id": "10",
        "name": "Muktagachha",
        "bn_name": "মুক্তাগাছা"
    },
    "212": {
        "id": "212",
        "district_id": "10",
        "name": "Dhobaura",
        "bn_name": "ধবারুয়া"
    },
    "213": {
        "id": "213",
        "district_id": "10",
        "name": "Fulbaria",
        "bn_name": "ফুলবাড়িয়া"
    },
    "214": {
        "id": "214",
        "district_id": "10",
        "name": "Gaffargaon",
        "bn_name": "গফরগাঁও"
    },
    "215": {
        "id": "215",
        "district_id": "10",
        "name": "Gauripur",
        "bn_name": "গৌরিপুর"
    },
    "216": {
        "id": "216",
        "district_id": "10",
        "name": "Ishwarganj",
        "bn_name": "ঈশ্বরগঞ্জ"
    },
    "217": {
        "id": "217",
        "district_id": "10",
        "name": "Mymensingh Sadar",
        "bn_name": "ময়মনসিং সদর"
    },
    "218": {
        "id": "218",
        "district_id": "10",
        "name": "Nandail",
        "bn_name": "নন্দাইল"
    },
    "219": {
        "id": "219",
        "district_id": "10",
        "name": "Phulpur",
        "bn_name": "ফুলপুর"
    },
    "220": {
        "id": "220",
        "district_id": "11",
        "name": "Araihazar",
        "bn_name": "আড়াইহাজার"
    },
    "221": {
        "id": "221",
        "district_id": "11",
        "name": "Sonargaon",
        "bn_name": "সোনারগাঁও"
    },
    "222": {
        "id": "222",
        "district_id": "11",
        "name": "Bandar",
        "bn_name": "বান্দার"
    },
    "223": {
        "id": "223",
        "district_id": "11",
        "name": "Naryanganj Sadar",
        "bn_name": "নারায়ানগঞ্জ সদর"
    },
    "224": {
        "id": "224",
        "district_id": "11",
        "name": "Rupganj",
        "bn_name": "রূপগঞ্জ"
    },
    "225": {
        "id": "225",
        "district_id": "11",
        "name": "Siddirgonj",
        "bn_name": "সিদ্ধিরগঞ্জ"
    },
    "226": {
        "id": "226",
        "district_id": "12",
        "name": "Belabo",
        "bn_name": "বেলাবো"
    },
    "227": {
        "id": "227",
        "district_id": "12",
        "name": "Monohardi",
        "bn_name": "মনোহরদি"
    },
    "228": {
        "id": "228",
        "district_id": "12",
        "name": "Narsingdi Sadar",
        "bn_name": "নরসিংদী সদর"
    },
    "229": {
        "id": "229",
        "district_id": "12",
        "name": "Palash",
        "bn_name": "পলাশ"
    },
    "230": {
        "id": "230",
        "district_id": "12",
        "name": "Raipura, Narsingdi",
        "bn_name": "রায়পুর"
    },
    "231": {
        "id": "231",
        "district_id": "12",
        "name": "Shibpur",
        "bn_name": "শিবপুর"
    },
    "232": {
        "id": "232",
        "district_id": "13",
        "name": "Kendua Upazilla",
        "bn_name": "কেন্দুয়া"
    },
    "233": {
        "id": "233",
        "district_id": "13",
        "name": "Atpara Upazilla",
        "bn_name": "আটপাড়া"
    },
    "234": {
        "id": "234",
        "district_id": "13",
        "name": "Barhatta Upazilla",
        "bn_name": "বরহাট্টা"
    },
    "235": {
        "id": "235",
        "district_id": "13",
        "name": "Durgapur Upazilla",
        "bn_name": "দুর্গাপুর"
    },
    "236": {
        "id": "236",
        "district_id": "13",
        "name": "Kalmakanda Upazilla",
        "bn_name": "কলমাকান্দা"
    },
    "237": {
        "id": "237",
        "district_id": "13",
        "name": "Madan Upazilla",
        "bn_name": "মদন"
    },
    "238": {
        "id": "238",
        "district_id": "13",
        "name": "Mohanganj Upazilla",
        "bn_name": "মোহনগঞ্জ"
    },
    "239": {
        "id": "239",
        "district_id": "13",
        "name": "Netrakona-S Upazilla",
        "bn_name": "নেত্রকোনা সদর"
    },
    "240": {
        "id": "240",
        "district_id": "13",
        "name": "Purbadhala Upazilla",
        "bn_name": "পূর্বধলা"
    },
    "241": {
        "id": "241",
        "district_id": "13",
        "name": "Khaliajuri Upazilla",
        "bn_name": "খালিয়াজুরি"
    },
    "242": {
        "id": "242",
        "district_id": "14",
        "name": "Baliakandi",
        "bn_name": "বালিয়াকান্দি"
    },
    "243": {
        "id": "243",
        "district_id": "14",
        "name": "Goalandaghat",
        "bn_name": "গোয়ালন্দ ঘাট"
    },
    "244": {
        "id": "244",
        "district_id": "14",
        "name": "Pangsha",
        "bn_name": "পাংশা"
    },
    "245": {
        "id": "245",
        "district_id": "14",
        "name": "Kalukhali",
        "bn_name": "কালুখালি"
    },
    "246": {
        "id": "246",
        "district_id": "14",
        "name": "Rajbari Sadar",
        "bn_name": "রাজবাড়ি সদর"
    },
    "247": {
        "id": "247",
        "district_id": "15",
        "name": "Shariatpur Sadar -Palong",
        "bn_name": "শরীয়তপুর সদর "
    },
    "248": {
        "id": "248",
        "district_id": "15",
        "name": "Damudya",
        "bn_name": "দামুদিয়া"
    },
    "249": {
        "id": "249",
        "district_id": "15",
        "name": "Naria",
        "bn_name": "নড়িয়া"
    },
    "250": {
        "id": "250",
        "district_id": "15",
        "name": "Jajira",
        "bn_name": "জাজিরা"
    },
    "251": {
        "id": "251",
        "district_id": "15",
        "name": "Bhedarganj",
        "bn_name": "ভেদারগঞ্জ"
    },
    "252": {
        "id": "252",
        "district_id": "15",
        "name": "Gosairhat",
        "bn_name": "গোসাইর হাট "
    },
    "253": {
        "id": "253",
        "district_id": "16",
        "name": "Jhenaigati",
        "bn_name": "ঝিনাইগাতি"
    },
    "254": {
        "id": "254",
        "district_id": "16",
        "name": "Nakla",
        "bn_name": "নাকলা"
    },
    "255": {
        "id": "255",
        "district_id": "16",
        "name": "Nalitabari",
        "bn_name": "নালিতাবাড়ি"
    },
    "256": {
        "id": "256",
        "district_id": "16",
        "name": "Sherpur Sadar",
        "bn_name": "শেরপুর সদর"
    },
    "257": {
        "id": "257",
        "district_id": "16",
        "name": "Sreebardi",
        "bn_name": "শ্রীবরদি"
    },
    "258": {
        "id": "258",
        "district_id": "17",
        "name": "Tangail Sadar",
        "bn_name": "টাঙ্গাইল সদর"
    },
    "259": {
        "id": "259",
        "district_id": "17",
        "name": "Sakhipur",
        "bn_name": "সখিপুর"
    },
    "260": {
        "id": "260",
        "district_id": "17",
        "name": "Basail",
        "bn_name": "বসাইল"
    },
    "261": {
        "id": "261",
        "district_id": "17",
        "name": "Madhupur",
        "bn_name": "মধুপুর"
    },
    "262": {
        "id": "262",
        "district_id": "17",
        "name": "Ghatail",
        "bn_name": "ঘাটাইল"
    },
    "263": {
        "id": "263",
        "district_id": "17",
        "name": "Kalihati",
        "bn_name": "কালিহাতি"
    },
    "264": {
        "id": "264",
        "district_id": "17",
        "name": "Nagarpur",
        "bn_name": "নগরপুর"
    },
    "265": {
        "id": "265",
        "district_id": "17",
        "name": "Mirzapur",
        "bn_name": "মির্জাপুর"
    },
    "266": {
        "id": "266",
        "district_id": "17",
        "name": "Gopalpur",
        "bn_name": "গোপালপুর"
    },
    "267": {
        "id": "267",
        "district_id": "17",
        "name": "Delduar",
        "bn_name": "দেলদুয়ার"
    },
    "268": {
        "id": "268",
        "district_id": "17",
        "name": "Bhuapur",
        "bn_name": "ভুয়াপুর"
    },
    "269": {
        "id": "269",
        "district_id": "17",
        "name": "Dhanbari",
        "bn_name": "ধানবাড়ি"
    },
    "270": {
        "id": "270",
        "district_id": "55",
        "name": "Bagerhat Sadar",
        "bn_name": "বাগেরহাট সদর"
    },
    "271": {
        "id": "271",
        "district_id": "55",
        "name": "Chitalmari",
        "bn_name": "চিতলমাড়ি"
    },
    "272": {
        "id": "272",
        "district_id": "55",
        "name": "Fakirhat",
        "bn_name": "ফকিরহাট"
    },
    "273": {
        "id": "273",
        "district_id": "55",
        "name": "Kachua",
        "bn_name": "কচুয়া"
    },
    "274": {
        "id": "274",
        "district_id": "55",
        "name": "Mollahat",
        "bn_name": "মোল্লাহাট "
    },
    "275": {
        "id": "275",
        "district_id": "55",
        "name": "Mongla",
        "bn_name": "মংলা"
    },
    "276": {
        "id": "276",
        "district_id": "55",
        "name": "Morrelganj",
        "bn_name": "মরেলগঞ্জ"
    },
    "277": {
        "id": "277",
        "district_id": "55",
        "name": "Rampal",
        "bn_name": "রামপাল"
    },
    "278": {
        "id": "278",
        "district_id": "55",
        "name": "Sarankhola",
        "bn_name": "স্মরণখোলা"
    },
    "279": {
        "id": "279",
        "district_id": "56",
        "name": "Damurhuda",
        "bn_name": "দামুরহুদা"
    },
    "280": {
        "id": "280",
        "district_id": "56",
        "name": "Chuadanga-S",
        "bn_name": "চুয়াডাঙ্গা সদর"
    },
    "281": {
        "id": "281",
        "district_id": "56",
        "name": "Jibannagar",
        "bn_name": "জীবন নগর "
    },
    "282": {
        "id": "282",
        "district_id": "56",
        "name": "Alamdanga",
        "bn_name": "আলমডাঙ্গা"
    },
    "283": {
        "id": "283",
        "district_id": "57",
        "name": "Abhaynagar",
        "bn_name": "অভয়নগর"
    },
    "284": {
        "id": "284",
        "district_id": "57",
        "name": "Keshabpur",
        "bn_name": "কেশবপুর"
    },
    "285": {
        "id": "285",
        "district_id": "57",
        "name": "Bagherpara",
        "bn_name": "বাঘের পাড়া "
    },
    "286": {
        "id": "286",
        "district_id": "57",
        "name": "Jessore Sadar",
        "bn_name": "যশোর সদর"
    },
    "287": {
        "id": "287",
        "district_id": "57",
        "name": "Chaugachha",
        "bn_name": "চৌগাছা"
    },
    "288": {
        "id": "288",
        "district_id": "57",
        "name": "Manirampur",
        "bn_name": "মনিরামপুর "
    },
    "289": {
        "id": "289",
        "district_id": "57",
        "name": "Jhikargachha",
        "bn_name": "ঝিকরগাছা"
    },
    "290": {
        "id": "290",
        "district_id": "57",
        "name": "Sharsha",
        "bn_name": "সারশা"
    },
    "291": {
        "id": "291",
        "district_id": "58",
        "name": "Jhenaidah Sadar",
        "bn_name": "ঝিনাইদহ সদর"
    },
    "292": {
        "id": "292",
        "district_id": "58",
        "name": "Maheshpur",
        "bn_name": "মহেশপুর"
    },
    "293": {
        "id": "293",
        "district_id": "58",
        "name": "Kaliganj",
        "bn_name": "কালীগঞ্জ"
    },
    "294": {
        "id": "294",
        "district_id": "58",
        "name": "Kotchandpur",
        "bn_name": "কোট চাঁদপুর "
    },
    "295": {
        "id": "295",
        "district_id": "58",
        "name": "Shailkupa",
        "bn_name": "শৈলকুপা"
    },
    "296": {
        "id": "296",
        "district_id": "58",
        "name": "Harinakunda",
        "bn_name": "হাড়িনাকুন্দা"
    },
    "297": {
        "id": "297",
        "district_id": "59",
        "name": "Terokhada",
        "bn_name": "তেরোখাদা"
    },
    "298": {
        "id": "298",
        "district_id": "59",
        "name": "Batiaghata",
        "bn_name": "বাটিয়াঘাটা "
    },
    "299": {
        "id": "299",
        "district_id": "59",
        "name": "Dacope",
        "bn_name": "ডাকপে"
    },
    "300": {
        "id": "300",
        "district_id": "59",
        "name": "Dumuria",
        "bn_name": "ডুমুরিয়া"
    },
    "301": {
        "id": "301",
        "district_id": "59",
        "name": "Dighalia",
        "bn_name": "দিঘলিয়া"
    },
    "302": {
        "id": "302",
        "district_id": "59",
        "name": "Koyra",
        "bn_name": "কয়ড়া"
    },
    "303": {
        "id": "303",
        "district_id": "59",
        "name": "Paikgachha",
        "bn_name": "পাইকগাছা"
    },
    "304": {
        "id": "304",
        "district_id": "59",
        "name": "Phultala",
        "bn_name": "ফুলতলা"
    },
    "305": {
        "id": "305",
        "district_id": "59",
        "name": "Rupsa",
        "bn_name": "রূপসা"
    },
    "306": {
        "id": "306",
        "district_id": "60",
        "name": "Kushtia Sadar",
        "bn_name": "কুষ্টিয়া সদর"
    },
    "307": {
        "id": "307",
        "district_id": "60",
        "name": "Kumarkhali",
        "bn_name": "কুমারখালি"
    },
    "308": {
        "id": "308",
        "district_id": "60",
        "name": "Daulatpur",
        "bn_name": "দৌলতপুর"
    },
    "309": {
        "id": "309",
        "district_id": "60",
        "name": "Mirpur",
        "bn_name": "মিরপুর"
    },
    "310": {
        "id": "310",
        "district_id": "60",
        "name": "Bheramara",
        "bn_name": "ভেরামারা"
    },
    "311": {
        "id": "311",
        "district_id": "60",
        "name": "Khoksa",
        "bn_name": "খোকসা"
    },
    "312": {
        "id": "312",
        "district_id": "61",
        "name": "Magura Sadar",
        "bn_name": "মাগুরা সদর"
    },
    "313": {
        "id": "313",
        "district_id": "61",
        "name": "Mohammadpur",
        "bn_name": "মোহাম্মাদপুর"
    },
    "314": {
        "id": "314",
        "district_id": "61",
        "name": "Shalikha",
        "bn_name": "শালিখা"
    },
    "315": {
        "id": "315",
        "district_id": "61",
        "name": "Sreepur",
        "bn_name": "শ্রীপুর"
    },
    "316": {
        "id": "316",
        "district_id": "62",
        "name": "angni",
        "bn_name": "আংনি"
    },
    "317": {
        "id": "317",
        "district_id": "62",
        "name": "Mujib Nagar",
        "bn_name": "মুজিব নগর"
    },
    "318": {
        "id": "318",
        "district_id": "62",
        "name": "Meherpur-S",
        "bn_name": "মেহেরপুর সদর"
    },
    "319": {
        "id": "319",
        "district_id": "63",
        "name": "Narail-S Upazilla",
        "bn_name": "নড়াইল সদর"
    },
    "320": {
        "id": "320",
        "district_id": "63",
        "name": "Lohagara Upazilla",
        "bn_name": "লোহাগাড়া"
    },
    "321": {
        "id": "321",
        "district_id": "63",
        "name": "Kalia Upazilla",
        "bn_name": "কালিয়া"
    },
    "322": {
        "id": "322",
        "district_id": "64",
        "name": "Satkhira Sadar",
        "bn_name": "সাতক্ষীরা সদর"
    },
    "323": {
        "id": "323",
        "district_id": "64",
        "name": "Assasuni",
        "bn_name": "আসসাশুনি "
    },
    "324": {
        "id": "324",
        "district_id": "64",
        "name": "Debhata",
        "bn_name": "দেভাটা"
    },
    "325": {
        "id": "325",
        "district_id": "64",
        "name": "Tala",
        "bn_name": "তালা"
    },
    "326": {
        "id": "326",
        "district_id": "64",
        "name": "Kalaroa",
        "bn_name": "কলরোয়া"
    },
    "327": {
        "id": "327",
        "district_id": "64",
        "name": "Kaliganj",
        "bn_name": "কালীগঞ্জ"
    },
    "328": {
        "id": "328",
        "district_id": "64",
        "name": "Shyamnagar",
        "bn_name": "শ্যামনগর"
    },
    "329": {
        "id": "329",
        "district_id": "18",
        "name": "Adamdighi",
        "bn_name": "আদমদিঘী"
    },
    "330": {
        "id": "330",
        "district_id": "18",
        "name": "Bogra Sadar",
        "bn_name": "বগুড়া সদর"
    },
    "331": {
        "id": "331",
        "district_id": "18",
        "name": "Sherpur",
        "bn_name": "শেরপুর"
    },
    "332": {
        "id": "332",
        "district_id": "18",
        "name": "Dhunat",
        "bn_name": "ধুনট"
    },
    "333": {
        "id": "333",
        "district_id": "18",
        "name": "Dhupchanchia",
        "bn_name": "দুপচাচিয়া"
    },
    "334": {
        "id": "334",
        "district_id": "18",
        "name": "Gabtali",
        "bn_name": "গাবতলি"
    },
    "335": {
        "id": "335",
        "district_id": "18",
        "name": "Kahaloo",
        "bn_name": "কাহালু"
    },
    "336": {
        "id": "336",
        "district_id": "18",
        "name": "Nandigram",
        "bn_name": "নন্দিগ্রাম"
    },
    "337": {
        "id": "337",
        "district_id": "18",
        "name": "Sahajanpur",
        "bn_name": "শাহজাহানপুর"
    },
    "338": {
        "id": "338",
        "district_id": "18",
        "name": "Sariakandi",
        "bn_name": "সারিয়াকান্দি"
    },
    "339": {
        "id": "339",
        "district_id": "18",
        "name": "Shibganj",
        "bn_name": "শিবগঞ্জ"
    },
    "340": {
        "id": "340",
        "district_id": "18",
        "name": "Sonatala",
        "bn_name": "সোনাতলা"
    },
    "341": {
        "id": "341",
        "district_id": "19",
        "name": "Joypurhat S",
        "bn_name": "জয়পুরহাট সদর"
    },
    "342": {
        "id": "342",
        "district_id": "19",
        "name": "Akkelpur",
        "bn_name": "আক্কেলপুর"
    },
    "343": {
        "id": "343",
        "district_id": "19",
        "name": "Kalai",
        "bn_name": "কালাই"
    },
    "344": {
        "id": "344",
        "district_id": "19",
        "name": "Khetlal",
        "bn_name": "খেতলাল"
    },
    "345": {
        "id": "345",
        "district_id": "19",
        "name": "Panchbibi",
        "bn_name": "পাঁচবিবি"
    },
    "346": {
        "id": "346",
        "district_id": "20",
        "name": "Naogaon Sadar",
        "bn_name": "নওগাঁ সদর"
    },
    "347": {
        "id": "347",
        "district_id": "20",
        "name": "Mohadevpur",
        "bn_name": "মহাদেবপুর"
    },
    "348": {
        "id": "348",
        "district_id": "20",
        "name": "Manda",
        "bn_name": "মান্দা"
    },
    "349": {
        "id": "349",
        "district_id": "20",
        "name": "Niamatpur",
        "bn_name": "নিয়ামতপুর"
    },
    "350": {
        "id": "350",
        "district_id": "20",
        "name": "Atrai",
        "bn_name": "আত্রাই"
    },
    "351": {
        "id": "351",
        "district_id": "20",
        "name": "Raninagar",
        "bn_name": "রাণীনগর"
    },
    "352": {
        "id": "352",
        "district_id": "20",
        "name": "Patnitala",
        "bn_name": "পত্নীতলা"
    },
    "353": {
        "id": "353",
        "district_id": "20",
        "name": "Dhamoirhat",
        "bn_name": "ধামইরহাট "
    },
    "354": {
        "id": "354",
        "district_id": "20",
        "name": "Sapahar",
        "bn_name": "সাপাহার"
    },
    "355": {
        "id": "355",
        "district_id": "20",
        "name": "Porsha",
        "bn_name": "পোরশা"
    },
    "356": {
        "id": "356",
        "district_id": "20",
        "name": "Badalgachhi",
        "bn_name": "বদলগাছি"
    },
    "357": {
        "id": "357",
        "district_id": "21",
        "name": "Natore Sadar",
        "bn_name": "নাটোর সদর"
    },
    "358": {
        "id": "358",
        "district_id": "21",
        "name": "Baraigram",
        "bn_name": "বড়াইগ্রাম"
    },
    "359": {
        "id": "359",
        "district_id": "21",
        "name": "Bagatipara",
        "bn_name": "বাগাতিপাড়া"
    },
    "360": {
        "id": "360",
        "district_id": "21",
        "name": "Lalpur",
        "bn_name": "লালপুর"
    },
    "361": {
        "id": "361",
        "district_id": "21",
        "name": "Natore Sadar",
        "bn_name": "নাটোর সদর"
    },
    "362": {
        "id": "362",
        "district_id": "21",
        "name": "Baraigram",
        "bn_name": "বড়াই গ্রাম"
    },
    "363": {
        "id": "363",
        "district_id": "22",
        "name": "Bholahat",
        "bn_name": "ভোলাহাট"
    },
    "364": {
        "id": "364",
        "district_id": "22",
        "name": "Gomastapur",
        "bn_name": "গোমস্তাপুর"
    },
    "365": {
        "id": "365",
        "district_id": "22",
        "name": "Nachole",
        "bn_name": "নাচোল"
    },
    "366": {
        "id": "366",
        "district_id": "22",
        "name": "Nawabganj Sadar",
        "bn_name": "নবাবগঞ্জ সদর"
    },
    "367": {
        "id": "367",
        "district_id": "22",
        "name": "Shibganj",
        "bn_name": "শিবগঞ্জ"
    },
    "368": {
        "id": "368",
        "district_id": "23",
        "name": "Atgharia",
        "bn_name": "আটঘরিয়া"
    },
    "369": {
        "id": "369",
        "district_id": "23",
        "name": "Bera",
        "bn_name": "বেড়া"
    },
    "370": {
        "id": "370",
        "district_id": "23",
        "name": "Bhangura",
        "bn_name": "ভাঙ্গুরা"
    },
    "371": {
        "id": "371",
        "district_id": "23",
        "name": "Chatmohar",
        "bn_name": "চাটমোহর"
    },
    "372": {
        "id": "372",
        "district_id": "23",
        "name": "Faridpur",
        "bn_name": "ফরিদপুর"
    },
    "373": {
        "id": "373",
        "district_id": "23",
        "name": "Ishwardi",
        "bn_name": "ঈশ্বরদী"
    },
    "374": {
        "id": "374",
        "district_id": "23",
        "name": "Pabna Sadar",
        "bn_name": "পাবনা সদর"
    },
    "375": {
        "id": "375",
        "district_id": "23",
        "name": "Santhia",
        "bn_name": "সাথিয়া"
    },
    "376": {
        "id": "376",
        "district_id": "23",
        "name": "Sujanagar",
        "bn_name": "সুজানগর"
    },
    "377": {
        "id": "377",
        "district_id": "24",
        "name": "Bagha",
        "bn_name": "বাঘা"
    },
    "378": {
        "id": "378",
        "district_id": "24",
        "name": "Bagmara",
        "bn_name": "বাগমারা"
    },
    "379": {
        "id": "379",
        "district_id": "24",
        "name": "Charghat",
        "bn_name": "চারঘাট"
    },
    "380": {
        "id": "380",
        "district_id": "24",
        "name": "Durgapur",
        "bn_name": "দুর্গাপুর"
    },
    "381": {
        "id": "381",
        "district_id": "24",
        "name": "Godagari",
        "bn_name": "গোদাগারি"
    },
    "382": {
        "id": "382",
        "district_id": "24",
        "name": "Mohanpur",
        "bn_name": "মোহনপুর"
    },
    "383": {
        "id": "383",
        "district_id": "24",
        "name": "Paba",
        "bn_name": "পবা"
    },
    "384": {
        "id": "384",
        "district_id": "24",
        "name": "Puthia",
        "bn_name": "পুঠিয়া"
    },
    "385": {
        "id": "385",
        "district_id": "24",
        "name": "Tanore",
        "bn_name": "তানোর"
    },
    "386": {
        "id": "386",
        "district_id": "25",
        "name": "Sirajganj Sadar",
        "bn_name": "সিরাজগঞ্জ সদর"
    },
    "387": {
        "id": "387",
        "district_id": "25",
        "name": "Belkuchi",
        "bn_name": "বেলকুচি"
    },
    "388": {
        "id": "388",
        "district_id": "25",
        "name": "Chauhali",
        "bn_name": "চৌহালি"
    },
    "389": {
        "id": "389",
        "district_id": "25",
        "name": "Kamarkhanda",
        "bn_name": "কামারখান্দা"
    },
    "390": {
        "id": "390",
        "district_id": "25",
        "name": "Kazipur",
        "bn_name": "কাজীপুর"
    },
    "391": {
        "id": "391",
        "district_id": "25",
        "name": "Raiganj",
        "bn_name": "রায়গঞ্জ"
    },
    "392": {
        "id": "392",
        "district_id": "25",
        "name": "Shahjadpur",
        "bn_name": "শাহজাদপুর"
    },
    "393": {
        "id": "393",
        "district_id": "25",
        "name": "Tarash",
        "bn_name": "তারাশ"
    },
    "394": {
        "id": "394",
        "district_id": "25",
        "name": "Ullahpara",
        "bn_name": "উল্লাপাড়া"
    },
    "395": {
        "id": "395",
        "district_id": "26",
        "name": "Birampur",
        "bn_name": "বিরামপুর"
    },
    "396": {
        "id": "396",
        "district_id": "26",
        "name": "Birganj",
        "bn_name": "বীরগঞ্জ"
    },
    "397": {
        "id": "397",
        "district_id": "26",
        "name": "Biral",
        "bn_name": "বিড়াল"
    },
    "398": {
        "id": "398",
        "district_id": "26",
        "name": "Bochaganj",
        "bn_name": "বোচাগঞ্জ"
    },
    "399": {
        "id": "399",
        "district_id": "26",
        "name": "Chirirbandar",
        "bn_name": "চিরিরবন্দর"
    },
    "400": {
        "id": "400",
        "district_id": "26",
        "name": "Phulbari",
        "bn_name": "ফুলবাড়ি"
    },
    "401": {
        "id": "401",
        "district_id": "26",
        "name": "Ghoraghat",
        "bn_name": "ঘোড়াঘাট"
    },
    "402": {
        "id": "402",
        "district_id": "26",
        "name": "Hakimpur",
        "bn_name": "হাকিমপুর"
    },
    "403": {
        "id": "403",
        "district_id": "26",
        "name": "Kaharole",
        "bn_name": "কাহারোল"
    },
    "404": {
        "id": "404",
        "district_id": "26",
        "name": "Khansama",
        "bn_name": "খানসামা"
    },
    "405": {
        "id": "405",
        "district_id": "26",
        "name": "Dinajpur Sadar",
        "bn_name": "দিনাজপুর সদর"
    },
    "406": {
        "id": "406",
        "district_id": "26",
        "name": "Nawabganj",
        "bn_name": "নবাবগঞ্জ"
    },
    "407": {
        "id": "407",
        "district_id": "26",
        "name": "Parbatipur",
        "bn_name": "পার্বতীপুর"
    },
    "408": {
        "id": "408",
        "district_id": "27",
        "name": "Fulchhari",
        "bn_name": "ফুলছড়ি"
    },
    "409": {
        "id": "409",
        "district_id": "27",
        "name": "Gaibandha sadar",
        "bn_name": "গাইবান্ধা সদর"
    },
    "410": {
        "id": "410",
        "district_id": "27",
        "name": "Gobindaganj",
        "bn_name": "গোবিন্দগঞ্জ"
    },
    "411": {
        "id": "411",
        "district_id": "27",
        "name": "Palashbari",
        "bn_name": "পলাশবাড়ী"
    },
    "412": {
        "id": "412",
        "district_id": "27",
        "name": "Sadullapur",
        "bn_name": "সাদুল্যাপুর"
    },
    "413": {
        "id": "413",
        "district_id": "27",
        "name": "Saghata",
        "bn_name": "সাঘাটা"
    },
    "414": {
        "id": "414",
        "district_id": "27",
        "name": "Sundarganj",
        "bn_name": "সুন্দরগঞ্জ"
    },
    "415": {
        "id": "415",
        "district_id": "28",
        "name": "Kurigram Sadar",
        "bn_name": "কুড়িগ্রাম সদর"
    },
    "416": {
        "id": "416",
        "district_id": "28",
        "name": "Nageshwari",
        "bn_name": "নাগেশ্বরী"
    },
    "417": {
        "id": "417",
        "district_id": "28",
        "name": "Bhurungamari",
        "bn_name": "ভুরুঙ্গামারি"
    },
    "418": {
        "id": "418",
        "district_id": "28",
        "name": "Phulbari",
        "bn_name": "ফুলবাড়ি"
    },
    "419": {
        "id": "419",
        "district_id": "28",
        "name": "Rajarhat",
        "bn_name": "রাজারহাট"
    },
    "420": {
        "id": "420",
        "district_id": "28",
        "name": "Ulipur",
        "bn_name": "উলিপুর"
    },
    "421": {
        "id": "421",
        "district_id": "28",
        "name": "Chilmari",
        "bn_name": "চিলমারি"
    },
    "422": {
        "id": "422",
        "district_id": "28",
        "name": "Rowmari",
        "bn_name": "রউমারি"
    },
    "423": {
        "id": "423",
        "district_id": "28",
        "name": "Char Rajibpur",
        "bn_name": "চর রাজিবপুর"
    },
    "424": {
        "id": "424",
        "district_id": "29",
        "name": "Lalmanirhat Sadar",
        "bn_name": "লালমনিরহাট সদর"
    },
    "425": {
        "id": "425",
        "district_id": "29",
        "name": "Aditmari",
        "bn_name": "আদিতমারি"
    },
    "426": {
        "id": "426",
        "district_id": "29",
        "name": "Kaliganj",
        "bn_name": "কালীগঞ্জ"
    },
    "427": {
        "id": "427",
        "district_id": "29",
        "name": "Hatibandha",
        "bn_name": "হাতিবান্ধা"
    },
    "428": {
        "id": "428",
        "district_id": "29",
        "name": "Patgram",
        "bn_name": "পাটগ্রাম"
    },
    "429": {
        "id": "429",
        "district_id": "30",
        "name": "Nilphamari Sadar",
        "bn_name": "নীলফামারী সদর"
    },
    "430": {
        "id": "430",
        "district_id": "30",
        "name": "Saidpur",
        "bn_name": "সৈয়দপুর"
    },
    "431": {
        "id": "431",
        "district_id": "30",
        "name": "Jaldhaka",
        "bn_name": "জলঢাকা"
    },
    "432": {
        "id": "432",
        "district_id": "30",
        "name": "Kishoreganj",
        "bn_name": "কিশোরগঞ্জ"
    },
    "433": {
        "id": "433",
        "district_id": "30",
        "name": "Domar",
        "bn_name": "ডোমার"
    },
    "434": {
        "id": "434",
        "district_id": "30",
        "name": "Dimla",
        "bn_name": "ডিমলা"
    },
    "435": {
        "id": "435",
        "district_id": "31",
        "name": "Panchagarh Sadar",
        "bn_name": "পঞ্চগড় সদর"
    },
    "436": {
        "id": "436",
        "district_id": "31",
        "name": "Debiganj",
        "bn_name": "দেবীগঞ্জ"
    },
    "437": {
        "id": "437",
        "district_id": "31",
        "name": "Boda",
        "bn_name": "বোদা"
    },
    "438": {
        "id": "438",
        "district_id": "31",
        "name": "Atwari",
        "bn_name": "আটোয়ারি"
    },
    "439": {
        "id": "439",
        "district_id": "31",
        "name": "Tetulia",
        "bn_name": "তেঁতুলিয়া"
    },
    "440": {
        "id": "440",
        "district_id": "32",
        "name": "Badarganj",
        "bn_name": "বদরগঞ্জ"
    },
    "441": {
        "id": "441",
        "district_id": "32",
        "name": "Mithapukur",
        "bn_name": "মিঠাপুকুর"
    },
    "442": {
        "id": "442",
        "district_id": "32",
        "name": "Gangachara",
        "bn_name": "গঙ্গাচরা"
    },
    "443": {
        "id": "443",
        "district_id": "32",
        "name": "Kaunia",
        "bn_name": "কাউনিয়া"
    },
    "444": {
        "id": "444",
        "district_id": "32",
        "name": "Rangpur Sadar",
        "bn_name": "রংপুর সদর"
    },
    "445": {
        "id": "445",
        "district_id": "32",
        "name": "Pirgachha",
        "bn_name": "পীরগাছা"
    },
    "446": {
        "id": "446",
        "district_id": "32",
        "name": "Pirganj",
        "bn_name": "পীরগঞ্জ"
    },
    "447": {
        "id": "447",
        "district_id": "32",
        "name": "Taraganj",
        "bn_name": "তারাগঞ্জ"
    },
    "448": {
        "id": "448",
        "district_id": "33",
        "name": "Thakurgaon Sadar",
        "bn_name": "ঠাকুরগাঁও সদর"
    },
    "449": {
        "id": "449",
        "district_id": "33",
        "name": "Pirganj",
        "bn_name": "পীরগঞ্জ"
    },
    "450": {
        "id": "450",
        "district_id": "33",
        "name": "Baliadangi",
        "bn_name": "বালিয়াডাঙ্গি"
    },
    "451": {
        "id": "451",
        "district_id": "33",
        "name": "Haripur",
        "bn_name": "হরিপুর"
    },
    "452": {
        "id": "452",
        "district_id": "33",
        "name": "Ranisankail",
        "bn_name": "রাণীসংকইল"
    },
    "453": {
        "id": "453",
        "district_id": "51",
        "name": "Ajmiriganj",
        "bn_name": "আজমিরিগঞ্জ"
    },
    "454": {
        "id": "454",
        "district_id": "51",
        "name": "Baniachang",
        "bn_name": "বানিয়াচং"
    },
    "455": {
        "id": "455",
        "district_id": "51",
        "name": "Bahubal",
        "bn_name": "বাহুবল"
    },
    "456": {
        "id": "456",
        "district_id": "51",
        "name": "Chunarughat",
        "bn_name": "চুনারুঘাট"
    },
    "457": {
        "id": "457",
        "district_id": "51",
        "name": "Habiganj Sadar",
        "bn_name": "হবিগঞ্জ সদর"
    },
    "458": {
        "id": "458",
        "district_id": "51",
        "name": "Lakhai",
        "bn_name": "লাক্ষাই"
    },
    "459": {
        "id": "459",
        "district_id": "51",
        "name": "Madhabpur",
        "bn_name": "মাধবপুর"
    },
    "460": {
        "id": "460",
        "district_id": "51",
        "name": "Nabiganj",
        "bn_name": "নবীগঞ্জ"
    },
    "461": {
        "id": "461",
        "district_id": "51",
        "name": "Shaistagonj",
        "bn_name": "শায়েস্তাগঞ্জ"
    },
    "462": {
        "id": "462",
        "district_id": "52",
        "name": "Moulvibazar Sadar",
        "bn_name": "মৌলভীবাজার"
    },
    "463": {
        "id": "463",
        "district_id": "52",
        "name": "Barlekha",
        "bn_name": "বড়লেখা"
    },
    "464": {
        "id": "464",
        "district_id": "52",
        "name": "Juri",
        "bn_name": "জুড়ি"
    },
    "465": {
        "id": "465",
        "district_id": "52",
        "name": "Kamalganj",
        "bn_name": "কামালগঞ্জ"
    },
    "466": {
        "id": "466",
        "district_id": "52",
        "name": "Kulaura",
        "bn_name": "কুলাউরা"
    },
    "467": {
        "id": "467",
        "district_id": "52",
        "name": "Rajnagar",
        "bn_name": "রাজনগর"
    },
    "468": {
        "id": "468",
        "district_id": "52",
        "name": "Sreemangal",
        "bn_name": "শ্রীমঙ্গল"
    },
    "469": {
        "id": "469",
        "district_id": "53",
        "name": "Bishwamvarpur",
        "bn_name": "বিসশম্ভারপুর"
    },
    "470": {
        "id": "470",
        "district_id": "53",
        "name": "Chhatak",
        "bn_name": "ছাতক"
    },
    "471": {
        "id": "471",
        "district_id": "53",
        "name": "Derai",
        "bn_name": "দেড়াই"
    },
    "472": {
        "id": "472",
        "district_id": "53",
        "name": "Dharampasha",
        "bn_name": "ধরমপাশা"
    },
    "473": {
        "id": "473",
        "district_id": "53",
        "name": "Dowarabazar",
        "bn_name": "দোয়ারাবাজার"
    },
    "474": {
        "id": "474",
        "district_id": "53",
        "name": "Jagannathpur",
        "bn_name": "জগন্নাথপুর"
    },
    "475": {
        "id": "475",
        "district_id": "53",
        "name": "Jamalganj",
        "bn_name": "জামালগঞ্জ"
    },
    "476": {
        "id": "476",
        "district_id": "53",
        "name": "Sulla",
        "bn_name": "সুল্লা"
    },
    "477": {
        "id": "477",
        "district_id": "53",
        "name": "Sunamganj Sadar",
        "bn_name": "সুনামগঞ্জ সদর"
    },
    "478": {
        "id": "478",
        "district_id": "53",
        "name": "Shanthiganj",
        "bn_name": "শান্তিগঞ্জ"
    },
    "479": {
        "id": "479",
        "district_id": "53",
        "name": "Tahirpur",
        "bn_name": "তাহিরপুর"
    },
    "480": {
        "id": "480",
        "district_id": "54",
        "name": "Sylhet Sadar",
        "bn_name": "সিলেট সদর"
    },
    "481": {
        "id": "481",
        "district_id": "54",
        "name": "Beanibazar",
        "bn_name": "বেয়ানিবাজার"
    },
    "482": {
        "id": "482",
        "district_id": "54",
        "name": "Bishwanath",
        "bn_name": "বিশ্বনাথ"
    },
    "483": {
        "id": "483",
        "district_id": "54",
        "name": "Dakshin Surma",
        "bn_name": "দক্ষিণ সুরমা"
    },
    "484": {
        "id": "484",
        "district_id": "54",
        "name": "Balaganj",
        "bn_name": "বালাগঞ্জ"
    },
    "485": {
        "id": "485",
        "district_id": "54",
        "name": "Companiganj",
        "bn_name": "কোম্পানিগঞ্জ"
    },
    "486": {
        "id": "486",
        "district_id": "54",
        "name": "Fenchuganj",
        "bn_name": "ফেঞ্চুগঞ্জ"
    },
    "487": {
        "id": "487",
        "district_id": "54",
        "name": "Golapganj",
        "bn_name": "গোলাপগঞ্জ"
    },
    "488": {
        "id": "488",
        "district_id": "54",
        "name": "Gowainghat",
        "bn_name": "গোয়াইনঘাট"
    },
    "489": {
        "id": "489",
        "district_id": "54",
        "name": "Jointapur",
        "bn_name": "জৈন্তাপুর"
    },
    "490": {
        "id": "490",
        "district_id": "54",
        "name": "Kanaighat",
        "bn_name": "কানাইঘাট"
    },
    "491": {
        "id": "491",
        "district_id": "54",
        "name": "Zakiganj",
        "bn_name": "জাকিগঞ্জ"
    },
    "492": {
        "id": "492",
        "district_id": "54",
        "name": "Nobigonj",
        "bn_name": "নবীগঞ্জ"
    },
    "493": {
        "id": "493",
        "district_id": "45",
        "name": "Eidgaon",
        "bn_name": "ঈদগাঁও"
    },
    "494": {
        "id": "494",
        "district_id": "53",
        "name": "Modhyanagar",
        "bn_name": "মধ্যনগর"
    },
    "495": {
        "id": "495",
        "district_id": "7",
        "name": "Dasar",
        "bn_name": "দশর"
    }
}
export default subDistricts;