import { Dialog, Transition } from '@headlessui/react';
import React, { useEffect, useRef } from 'react';
import { Fragment, useState } from 'react'
import logo from '../../src/assets/shaikh_ahmadullah_logo.png';
import assunnah from '../../src/assets/asf_logo.png';
import { formatDateForViewers } from '../utils/helpers';
import divisions from '../json/divisions';
import districts from '../json/districts';
import subDistricts from '../json/subDistricts';

const PreviewModal = ({ isOpen, closeModal, formData, applicationNumber }) => {
    const [division, setSelectedDivision] = useState(null);
    const [district, setSelectedDistrict] = useState(null);
    const [subDistrict, setSelectedSubDistrict] = useState(null);
    const ref = useRef()

    useEffect(() => {
        const fetchSelectedData = async () => {
            try {
                const divisionId = formData?.division;
                const districtId = formData?.district;
                const subDistrictId = formData?.subDistrict;

                setSelectedDivision(divisions[divisionId]?.bn_name);

                setSelectedDistrict(districts[districtId].bn_name);

                setSelectedSubDistrict(subDistricts[subDistrictId].bn_name);

            } catch (error) {
                console.log(error);
            }
        };

        fetchSelectedData();

        return () => setSelectedSubDistrict(null)
    }, [formData?.district, formData?.subDistrict, formData?.division]);


    return (
        <div>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full shadow-3xl max-w-3xl transform overflow-hidden  text-left bg-white align-middle transition-all rounded-2xl flex items-center justify-center flex-col">

                                    <div ref={ref} className='w-full transform overflow-hidden rounded-2xl bg-white text-left align-middle transition-all px-5 py-5 lg:px-10 lg:py-7'>
                                        <div className="-mt-7 relative">
                                            <img
                                                src={assunnah}
                                                className='opacity-10 absolute'
                                                style={{ width: '60%', height: 'auto', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', }}
                                            />
                                            <div className=''>
                                                <div className='flex flex-col items-center'>
                                                    <img
                                                        src={logo}
                                                        className='lg:h-24 lg:w-24 h-20 w-20'
                                                    />
                                                    <h3 className='text-lg font-[500]
                                                     mt-1'>দীনি হালাকাহর আবেদন</h3>
                                                </div>

                                                <h3 className='text-right text-md mt-3'>আবেদন নং: {applicationNumber}</h3>

                                                <div>
                                                    <h3 className='text-md font-semibold'>প্রতিষ্ঠানের তথ্যঃ</h3>
                                                    <div className='border p-2'>
                                                        <h3 className='text-md'><span className='font-semibold pr-2 '>প্রতিষ্ঠানের ধরন:</span>
                                                            {
                                                                formData?.institutionType === '1' &&
                                                                'কেন্দ্রীয়/বড় মসজিদ'
                                                            }
                                                            {
                                                                formData?.institutionType === '2' &&
                                                                'বিশ্ববিদ্যালয়/কলেজ'
                                                            }
                                                            {
                                                                formData?.institutionType === '3' &&
                                                                'মিলনায়তন'
                                                            }
                                                            {
                                                                formData?.institutionType === '4' &&
                                                                'মাদরাসা'
                                                            }
                                                        </h3>
                                                    </div>
                                                    <div>
                                                        {
                                                            formData?.institutionType === '4' &&
                                                            <div className='border p-2'>
                                                                <h3 className='text-md'><span className='font-semibold pr-2 '>প্রতিষ্ঠানের নাম:</span>
                                                                    {formData?.madrashaw_name && formData?.madrashaw_name}
                                                                </h3>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div>
                                                        {
                                                            formData?.institutionType === '1' &&
                                                            <div className='border p-2'>
                                                                <h3 className='text-md'><span className='font-semibold pr-2 '>প্রতিষ্ঠানের নাম:</span>
                                                                    {formData?.central_masjid_name && formData?.central_masjid_name}
                                                                </h3>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div>
                                                        {
                                                            formData?.institutionType === '2' &&
                                                            <div className='border p-2'>
                                                                <h3 className='text-md'><span className='font-semibold pr-2 '>প্রতিষ্ঠানের নাম:</span>
                                                                    {formData?.educational_institute_name && formData?.educational_institute_name}
                                                                </h3>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div>
                                                        {
                                                            formData?.institutionType === '3' &&
                                                            <div className='border p-2'>
                                                                <h3 className='text-md'><span className='font-semibold pr-2 '>প্রতিষ্ঠানের নাম:</span>
                                                                    {formData?.auditorium_name && formData?.auditorium_name}
                                                                </h3>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div>
                                                        <div className='border p-2'>
                                                            <h3 className='text-md'><span className='font-semibold pr-2 '>হালাকাহ আয়োজনের উদ্দেশ্য:</span>
                                                                {formData?.goals_of_halaka}
                                                            </h3>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {
                                                            formData?.institutionType === '2' &&
                                                            <div className='border p-2'>
                                                                <h3 className='text-md'><span className='font-semibold pr-2 '>প্রোগ্রাম স্থল:</span>
                                                                    {formData?.programePlace}
                                                                </h3>
                                                            </div>
                                                        }
                                                    </div>

                                                    <div>
                                                        {
                                                            formData?.programePlace === 'অন্যান্য' &&
                                                            <div className='border p-2'>
                                                                <h3 className='text-md'><span className='font-semibold pr-2 '>প্রোগ্রাম স্থান সম্পর্কে বিস্তারিত:</span>
                                                                    {formData?.programme_place_description}
                                                                </h3>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div>
                                                        <div className='border p-2'>
                                                            <h3 className='text-md'><span className='font-semibold pr-2 '>প্রোগ্রাম স্থলের ধারণ-ক্ষমতা:</span>
                                                                {formData?.people_container_ability} জন
                                                            </h3>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {
                                                            formData?.institutionType === '3' &&
                                                            <div className='border p-2'>
                                                                <h3 className='text-md'><span className='font-semibold pr-2 '>মিলনায়তন ভাড়ার আয়ের উৎস: </span>
                                                                    {formData?.place_renting_source}
                                                                </h3>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div>
                                                        <div className='border p-2'>
                                                            <h3 className='text-md'><span className='font-semibold pr-2 '>অংশগ্রহণকারী: </span>
                                                                {formData?.participantType}
                                                            </h3>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {
                                                            formData?.participantType === 'পুরুষ মহিলা উভয়'
                                                            &&
                                                            <div className='border p-2'>
                                                                <h3 className='text-md'><span className='font-semibold pr-2 '>নারীদের অংশগ্রহণের জন্য পর্দার সাথে স্বতন্ত্র ব্যবস্থা থাকবে কি?</span>
                                                                    {formData?.fimaleCondition}।
                                                                </h3>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div>
                                                        {
                                                            formData?.institutionType === '1' &&
                                                            <div className='border p-2'>
                                                                <h3 className='text-md'><span className='font-semibold pr-2 '>মসজিদের বৈশিষ্ট্য:</span>
                                                                    {formData?.masjid_quality && formData?.masjid_quality}
                                                                </h3>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>

                                                {/* second section of Preview */}

                                                <div className='mt-11'>
                                                    <h3 className='text-md font-semibold'>আবেদনকারীর তথ্যঃ</h3>
                                                    <div>
                                                        {
                                                            formData?.institutionType === '3' &&
                                                            <div className='border p-2'>
                                                                <h3 className='text-md'><span className='font-semibold pr-2 '>রেফারকারীর নাম: </span>
                                                                    {formData?.referance_name}
                                                                </h3>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div>
                                                        <div className='border p-2'>
                                                            <h3 className='text-md'><span className='font-semibold pr-2 '>আবেদনকারীর নাম: </span>
                                                                {formData?.applicante_name}
                                                            </h3>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='border p-2'>
                                                            <h3 className='text-md'><span className='font-semibold pr-2 '>আবেদনকারীর নম্বর: </span>
                                                                {formData?.applicante_mobile}
                                                            </h3>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {
                                                            formData?.designation && formData?.institutionType !== '3' &&
                                                            <div className='border p-2'>
                                                                <h3 className='text-md'><span className='font-semibold pr-2 '>আবেদনকারীর পদবী: </span>
                                                                    {formData?.designation}
                                                                </h3>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div>
                                                        {
                                                            formData?.designation && formData?.institutionType === '3' &&
                                                            <div className='border p-2'>
                                                                <h3 className='text-md'><span className='font-semibold pr-2 '>আবেদনকারীর পেশা: </span>
                                                                    {formData?.designation}
                                                                </h3>
                                                            </div>
                                                        }
                                                    </div>

                                                </div>

                                                {/* third section */}

                                                <div className='mt-11 pdf-section'>
                                                    <h3 className='text-md font-semibold'>হালাকাহর তথ্যঃ</h3>

                                                    <div className='border-r'>
                                                        <div className='flex items-center gap-11 md:flex-row flex-row border'>
                                                            <div className='p-2 border-l'>
                                                                <h3 className='text-md'><span className='font-semibold pr-2 '>বিভাগ: </span>
                                                                    {division}
                                                                </h3>
                                                            </div>
                                                            <div className='p-2 border-l'>
                                                                <h3 className='text-md'><span className='font-semibold pr-2 '>জেলা: </span>
                                                                    {district}
                                                                </h3>
                                                            </div>
                                                            {
                                                                formData?.cityCorporation
                                                                    ?
                                                                    <div className='border-l p-2'>
                                                                        <h3 className='text-md'><span className='font-semibold pr-2 '>সিটি কর্পোরেশন এলাকা: </span>
                                                                            {formData?.cityCorporation}
                                                                        </h3>
                                                                    </div>
                                                                    :
                                                                    <div className='border-l p-2'>
                                                                        <h3 className='text-md'><span className='font-semibold pr-2 '>উপজেলা: </span>
                                                                            {subDistrict}
                                                                        </h3>
                                                                    </div>
                                                            }
                                                        </div>
                                                        <div className='border p-2'>
                                                            <h3 className='text-md'><span className='font-semibold pr-2 '>ঠিকানা: </span>
                                                                {formData?.details_address}
                                                            </h3>
                                                        </div>
                                                        <div className='border p-2'>
                                                            <h3 className='text-md'><span className='font-semibold pr-2 '>হালাকাহর প্রস্তাবিত তারিখ: </span>
                                                                {formatDateForViewers(formData?.dateFrom)} <span className='mx-2 font-bold'>হতে</span> {formatDateForViewers(formData?.dateTo)} <span className='mx-2 font-bold'>এর মধ্যে</span>
                                                            </h3>
                                                        </div>
                                                        <div className='border p-2'>
                                                            <h3 className='text-md'><span className='font-semibold pr-2 '>তারিখ নির্বাচনের ক্ষেত্রে বিশেষ কারণ: </span>
                                                                {formData?.date_selection_reason}
                                                            </h3>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="mt-4 flex items-center justify-end gap-3 mx-16 my-7">
                                        <button
                                            type="button"
                                            className={`mt-4 bg-red-500 hover:bg-red-700 text-white font-bold flex items-center justify-center w-28 h-10 rounded
                                            }`}
                                            onClick={closeModal}
                                        >
                                            বন্ধ
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};

export default PreviewModal;
