import React, { useState } from 'react'
import { BiSortAlt2 } from 'react-icons/bi';
import ApplicationRow from './ApplicationRow';
import { applicationType } from '../utils/constants';
import AdminViewModal from './AdminViewModal';
import { useLocation } from 'react-router-dom';
import ApplicationDeleteModal from './Modals/ApplicationDeleteModal';
import TrashToBackModal from './Modals/TrashToBackModal';
import PermanentDeleteModal from './Modals/PermanentDeleteModal';

const ApplicationTable = ({ sort, setSort, applications, page, type, limit, applicationCount, setSortBy }) => {
    let serialNo;
    if (sort === 1) { serialNo = page * limit; }
    else { serialNo = applicationCount - (page * limit); }

    let [isOpen, setIsOpen] = useState(false)
    let [isTrashModalOpen, setTrashModalOpen] = useState(false)
    let [deleteOpen, setDeleteOpen] = useState(false)
    const [isRestoreBtn, setRestoreBtn] = useState(false)
    const [applicationId, setApplicationId] = useState(null);
    const location = useLocation();
    const [applicationNum, setApplicationNum] = useState(null);
    const [open, setOpen] = useState(false)


    function offDeleteModal() {
        setDeleteOpen(false)
        setRestoreBtn(false)
    }

    function onDeleteModal() {
        setDeleteOpen(true)
    }


    function closeModal() {
        setIsOpen(false)
        setRestoreBtn(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    function onRestoreModal() {
        setRestoreBtn(true)
        setDeleteOpen(true)
    }


    return (
        <>
            <div className="flex flex-col mt-6">
                <div className="-mx-4 -my-2 overflow-x-auto m:-mx-6 g:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-hidden border border-gray-200  md:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200 ">
                                <thead className="bg-gray-50 ">
                                    <tr>
                                        <th scope="col" className="py-3.5 px-4 text-sm font-medium text-left rtl:text-right text-gray-700">
                                            <button
                                                onClick={() => { setSort(sort === 1 ? -1 : 1); setSortBy('updatedAt') }}
                                                className="flex items-center gap-x-3 focus:outline-none">
                                                <span>ক্রমিক</span>
                                                <BiSortAlt2 />
                                            </button>
                                        </th>

                                        <th scope="col" className="py-3.5  text-sm font-medium text-left rtl:text-right text-gray-500">
                                            <h3 className="flex items-center gap-x-3 focus:outline-none">
                                                <span>আবেদনকারীর নাম</span>
                                            </h3>
                                        </th>

                                        <th scope="col" className="px-4 py-3.5 text-sm font-medium text-left rtl:text-right text-gray-500 ">
                                            <h3 className="flex items-center gap-x-3 focus:outline-none">
                                                {
                                                    (type == applicationType.next || type == applicationType.completed) ?

                                                        <button
                                                            onClick={() => { setSort(sort === 1 ? -1 : 1); setSortBy('selected_date') }}
                                                            className="flex items-center gap-x-3 focus:outline-none">
                                                            <span>চূড়ান্ত তারিখ</span>
                                                            <BiSortAlt2 />
                                                        </button>
                                                        :
                                                        <button
                                                            onClick={() => { setSort(sort === 1 ? -1 : 1); setSortBy('createdAt') }}
                                                            className="flex items-center gap-x-3 focus:outline-none">
                                                            <span>আবেদনের তারিখ</span>
                                                            <BiSortAlt2 />
                                                        </button>
                                                }
                                            </h3>
                                        </th>

                                        <th scope="col" className="px-4 py-3.5 text-sm font-medium text-left rtl:text-right text-gray-500 ">
                                            <h3 className="flex items-center gap-x-3 focus:outline-none">
                                                <span> মোবাইল</span>
                                            </h3>
                                        </th>

                                        <th scope="col" className="px-4 py-3.5 text-sm font-medium text-left rtl:text-right text-gray-500 ">
                                            <h3 className="flex items-center gap-x-3 focus:outline-none">
                                                <span> জেলা</span>
                                            </h3>
                                        </th>


                                        <th scope="col" className="px-4 py-3.5 text-sm font-medium text-left rtl:text-right text-gray-500 ">
                                            <h3 className="flex items-center gap-x-3 focus:outline-none">
                                                <span> উপজেলা / সিটি কর্পোরেশন</span>
                                            </h3>
                                        </th>

                                        <th scope="col" className="px-4 py-3.5 text-sm font-medium text-left rtl:text-right text-gray-500 ">
                                            <h3 className="flex items-center gap-x-3 focus:outline-none">
                                                <span> প্রতিষ্ঠানের ধরণ</span>
                                            </h3>
                                        </th>

                                        <th scope="col" className="px-4 py-3.5 text-sm font-medium text-left  text-gray-500 ">---</th>


                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {
                                        applications?.map((item, i) =>
                                            <ApplicationRow
                                                formData={item}
                                                key={item._id}
                                                applicationId={applicationId}
                                                setApplicationId={setApplicationId}
                                                i={i}
                                                serialNo={sort === 1 ? ++serialNo : serialNo--}
                                                type={type}
                                                isOpen={isOpen}
                                                open={open}
                                                setOpen={setOpen}
                                                setIsOpen={setIsOpen}
                                                isTrashModalOpen={isTrashModalOpen}
                                                setApplicationNum={setApplicationNum}
                                                setTrashModalOpen={setTrashModalOpen}
                                                deleteOpen={deleteOpen}
                                                setDeleteOpen={setDeleteOpen}
                                                isRestoreBtn={isRestoreBtn}
                                                setRestoreBtn={setRestoreBtn}
                                                offDeleteModal={offDeleteModal}
                                                onDeleteModal={onDeleteModal}
                                                closeModal={closeModal}
                                                openModal={openModal}
                                                onRestoreModal={onRestoreModal}
                                            />
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {<AdminViewModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                deleteOpen={deleteOpen}
                setDeleteOpen={setDeleteOpen}
                isRestoreBtn={isRestoreBtn}
                setRestoreBtn={setRestoreBtn}
                offDeleteModal={offDeleteModal}
                onDeleteModal={onDeleteModal}
                closeModal={closeModal}
                openModal={openModal}
                onRestoreModal={onRestoreModal}
                type={type}
            />}

            {

                location.pathname === '/asf-admin/trash' ?

                    <>
                        <TrashToBackModal
                            open={isTrashModalOpen}
                            setOpen={setTrashModalOpen}
                            type={type}
                            id={applicationId}
                            applicationNum={applicationNum}
                        />
                        <PermanentDeleteModal
                            open={open}
                            setOpen={setOpen}
                            type={type}
                            id={applicationId}
                            applicationNum={applicationNum}
                        />
                    </>
                    :

                    <ApplicationDeleteModal
                        open={open}
                        setOpen={setOpen}
                        type={type}
                        id={applicationId}
                        applicationNum={applicationNum}
                    />

            }
        </>
    )
}

export default ApplicationTable