import React, { useEffect, useState } from 'react';
import up_arrow from '../assets/up_arrow.png'
import './Scroll.css';

const ClickToScrollTop = () => {

    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        setIsVisible(scrollTop > 0);
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    // Attach the scroll event listener when the component mounts
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <a className={`scrollToTop relati style cursor-pointer  ${isVisible ? 'visible' : 'hidden'}`} onClick={scrollToTop}>
            <div className='flex items-center justify-center h-full'>
                <img
                    className='h-5 w-5 z-10'
                    src={up_arrow} />
            </div>
            <span className="pluse_1"></span>
        </a>
    );
};

export default ClickToScrollTop;