import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/shaikh_ahmadullah_logo.png'
import './Signin.css'
import Lottie from 'react-lottie';
import animationData from '../../components/loader-3dots.json';
import { clearError, singIn } from '../../redux/actions/authActions'
import { useDispatch, useSelector } from 'react-redux';
import { checkValidEmail } from '../../utils/helpers';



const LoadingAnimation = ({ width, height, color }) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
            colorFilters: [
                {
                    keypath: 'Ellipse 1.Fill 1',
                    color: color,
                },
                {
                    keypath: 'Shape Layer 2.Stroke 1',
                    color: color,
                },
                // Add more color filters for other elements if needed
            ],
        },
    };

    return <div style={{ width: width, height: height, color: color }}>
        <Lottie options={defaultOptions} />
    </div>;
};

const Signin = () => {

    const dispatch = useDispatch();
    const { isAuthenticated, loading, error } = useSelector(state => state.auth);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate()
    const location = useLocation()
    const from = location.state?.from?.pathname || '/asf-admin'
    if (isAuthenticated) {
        navigate(from)
    }

    useEffect(() => {
        if (error) {
            dispatch(clearError())
        }
    }, [error])

    const onSubmit = (data) => {
        const email = data.email
        const password = data.password

        const payload = {
            email,
            password
        }

        if (checkValidEmail(email) === false) {
            toast.error('Please enter a valid email')
            return;
        }

        if (errors.email) {
            toast.error(errors.email.message)
            return;
        }
        dispatch(singIn(payload))
    }
    return (
        <div className='flex items-center justify-center h-screen siginin_bg  dashboard'>
            <div className="w-full max-w-sm p-6 m-auto mx-auto bg-white rounded-lg shadow-md ">
                <div className="flex justify-center mx-auto">
                    <img className="w-auto h-7 sm:h-8   md:h-20" src={logo} alt="" />
                </div>

                <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
                    <div className='h-[81px]'>
                        <label for="username" className="block text-sm text-gray-800 ">Email</label>
                        <input type="email" className={`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border rounded-lg focus:border-blue-400  focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40 ${errors.email && ' border-red-500 ring ring-red-200 focus:ring-red-300  focus:border-red-500'}`}
                            {...register("email", { required: "Email is Required" })}
                        />
                        {errors?.email && <span className="text-red-500 text-sm">{errors?.email?.message}</span>}
                    </div>
                    <div className='mt-4 h-[81px]'>
                        <div className="flex items-center justify-between">
                            <label for="password" className="block text-sm text-gray-800 ">Password</label>
                        </div>

                        <div className="relative flex items-center mt-2">
                            <button
                                type='button'
                                className="absolute right-0 focus:outline-none rtl:left-0 rtl:right-auto"
                                onClick={() => setShowPassword(!showPassword)}
                            >{
                                    showPassword ?
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" fill="currentColor"
                                            className="w-6 h-6 mx-4 text-gray-400 transition-colors duration-300  hover:text-gray-500 "
                                        >
                                            <path d="M0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0z" fill="none" /><path d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z" />
                                        </svg>

                                        :
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 mx-4 text-gray-400 transition-colors duration-300  hover:text-gray-500 ">
                                            <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                                            <path fill-rule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z" clip-rule="evenodd" />
                                        </svg>
                                }

                            </button>

                            <input
                                type={showPassword ? 'text' : 'password'}
                                className={`block w-full py-2.5 text-gray-700 placeholder-gray-400/70 bg-white border border-gray-200 rounded-lg pl-5 pr-11 rtl:pr-5 rtl:pl-11 focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40 ${errors.password && 'border-red-500 ring ring-red-200 focus:ring-red-300 focus:border-red-500'
                                    }`}
                                {...register('password', { required: 'Password is required' })}
                            />
                        </div>
                        {errors?.password && (
                            <span className="text-red-500 text-sm">{errors?.password?.message}</span>
                        )}
                    </div>

                    <div className="mt-6">
                        <button disabled={loading ? true : false} className="w-full h-11 px-6 flex items-center justify-center text-sm font-medium tracking-wide text-white  transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-50">
                            {
                                loading ?
                                    <LoadingAnimation
                                        width={70} height={70}
                                        color="#ffffff"
                                        className='h-full'
                                    />
                                    :
                                    "Sign in"
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Signin;