import api from '../api';
import {
    SIGN_IN_REQIEST,
    SIGN_IN_SUCCESS,
    SIGN_IN_FAIL,
    SIGN_OUT_REQIEST,
    SIGN_OUT_SUCCESS,
    SIGN_OUT_FAIL,
    CURRENT_USER_REQIEST,
    CURRENT_USER_SUCCESS,
    CURRENT_USER_FAIL,
    CLEAR_ERROR,
} from '../constants';

const initialState = { isAuthenticated: undefined, user: {}, loading: false };

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SIGN_IN_REQIEST:
            return {
                ...state,
                loading: true,
            }
        case SIGN_IN_SUCCESS:
            localStorage.setItem('accessToken', action.payload.token);
            return {
                ...state,
                loading: false,
                user: action.payload.user,
                isAuthenticated: true
            }
        case SIGN_IN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload?.message
            }
        case SIGN_OUT_REQIEST:
            return {
                loading: true,
                ...state,
            }
        case SIGN_OUT_SUCCESS:
            return initialState
        case SIGN_OUT_FAIL:
            return {
                ...state,
                loading: false
            }
        case CURRENT_USER_REQIEST:
            return {
                ...state,
                loading: true,
            }
        case CURRENT_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload.user,
                isAuthenticated: true
            }
        case CURRENT_USER_FAIL:
            return {
                ...state,
                loading: false,
                user: {},
                isAuthenticated: false
            }
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
}
