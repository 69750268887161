import { applicationStatus, applicationType } from '../../utils/constants';
import {
    APPLICATION_SUMMARY_REQIEST,
    APPLICATION_SUMMARY_SUCCESS,
    APPLICATION_SUMMARY_FAIL,
    ALL_APPLICATIONS_REQIEST,
    ALL_APPLICATIONS_SUCCESS,
    ALL_APPLICATIONS_FAIL,
    ACCEPTED_APPLICATIONS_REQIEST,
    ACCEPTED_APPLICATIONS_SUCCESS,
    ACCEPTED_APPLICATIONS_FAIL,
    NEXT_APPLICATIONS_REQIEST,
    NEXT_APPLICATIONS_SUCCESS,
    NEXT_APPLICATIONS_FAIL,
    COMPLETED_APPLICATIONS_REQIEST,
    COMPLETED_APPLICATIONS_SUCCESS,
    COMPLETED_APPLICATIONS_FAIL,
    REJECTED_APPLICATIONS_REQIEST,
    REJECTED_APPLICATIONS_SUCCESS,
    REJECTED_APPLICATIONS_FAIL,
    TRASHED_APPLICATIONS_REQIEST,
    TRASHED_APPLICATIONS_SUCCESS,
    TRASHED_APPLICATIONS_FAIL,
    SET_SELECTED_APPLICATION,
    SINGLE_APPLICATIONS_REQIEST,
    SINGLE_APPLICATIONS_SUCCESS,
    SINGLE_APPLICATIONS_FAIL,
    ACCEPT_APPLICATION_REQIEST,
    ACCEPT_APPLICATION_SUCCESS,
    ACCEPT_APPLICATION_FAIL,
    REJECT_APPLICATION_REQIEST,
    REJECT_APPLICATION_SUCCESS,
    REJECT_APPLICATION_FAIL,
    TRASH_APPLICATION_REQIEST,
    TRASH_APPLICATION_SUCCESS,
    TRASH_APPLICATION_FAIL,
    TRASH_BACK_APPLICATION_REQIEST,
    TRASH_BACK_APPLICATION_SUCCESS,
    TRASH_BACK_APPLICATION_FAIL,
    DELETE_APPLICATION_REQIEST,
    DELETE_APPLICATION_SUCCESS,
    DELETE_APPLICATION_FAIL,
    UNSET_SELECTED_APPLICATION,
    SET_LOADER,
    CLEAR_LOADER,
    SET_LOADER_FUNCTION,
    CLEAR_LOADER_FUNCTION,
    SIGN_OUT_REQIEST,
    SIGN_OUT_SUCCESS,
    SIGN_OUT_FAIL,
    UPDATE_APPLICATION_REQIEST,
    UPDATE_APPLICATION_SUCCESS,
    UPDATE_APPLICATION_FAIL,
} from '../constants';

const initialState = {
    loading: false,
    allApplicationCount: 0,
    acceptedApplicationCount: 0,
    rejectedApplicationCount: 0,
    completedApplicationCount: 0,
    nextApplicationCount: 0,
    trashedApplicationCount: 0,
    applications: [],
    acceptedApplications: [],
    rejectedApplications: [],
    nextApplications: [],
    completedApplications: [],
    trashedApplications: [],
    selectedApplication: {}
}

export const applicationReducer = (state = initialState, action) => {
    const { application, type } = action.payload || {}
    switch (action.type) {
        case APPLICATION_SUMMARY_REQIEST:
            return {
                ...state,
                loading: true,
            }
        case APPLICATION_SUMMARY_SUCCESS:
            const {
                allApplicationCount,
                acceptedApplicationCount,
                rejectedApplicationCount,
                completedApplicationCount,
                nextApplicationCount,
                trashedApplicationCount,
            } = action.payload;
            return {
                ...state,
                loading: false,
                allApplicationCount,
                acceptedApplicationCount,
                rejectedApplicationCount,
                completedApplicationCount,
                nextApplicationCount,
                trashedApplicationCount
            }

        case APPLICATION_SUMMARY_FAIL:
            return {
                ...state,
                loading: false,
            }
        case ALL_APPLICATIONS_REQIEST:
            return {
                ...state,
                loading: false
            }
        case ALL_APPLICATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                applications: action.payload.applications,
                allCount: action.payload.applicationCount ?? null
            }
        case ALL_APPLICATIONS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                errorStatus: action.payload?.response?.status
            }

        case ACCEPTED_APPLICATIONS_REQIEST:
            return {
                ...state,
                loading: false
            }
        case ACCEPTED_APPLICATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                acceptedApplications: action.payload.applications,
                acceptedCount: action.payload.applicationCount ?? null
            }
        case ACCEPTED_APPLICATIONS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                errorStatus: action.payload?.response?.status
            }

        case NEXT_APPLICATIONS_REQIEST:
            return {
                ...state,
                loading: false
            }
        case NEXT_APPLICATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                nextApplications: action.payload.applications,
                nextCount: action.payload.applicationCount ?? null
            }
        case NEXT_APPLICATIONS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                errorStatus: action.payload?.response?.status
            }

        case COMPLETED_APPLICATIONS_REQIEST:
            return {
                ...state,
                loading: false
            }
        case COMPLETED_APPLICATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                completedApplications: action.payload.applications,
                completedCount: action.payload.applicationCount ?? null
            }
        case COMPLETED_APPLICATIONS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                errorStatus: action.payload?.response?.status
            }

        case REJECTED_APPLICATIONS_REQIEST:
            return {
                ...state,
                loading: false
            }
        case REJECTED_APPLICATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                rejectedApplications: action.payload.applications,
                rejectedCount: action.payload.applicationCount ?? null
            }
        case REJECTED_APPLICATIONS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                errorStatus: action.payload?.response?.status
            }

        case TRASHED_APPLICATIONS_REQIEST:
            return {
                ...state,
                loading: false
            }
        case TRASHED_APPLICATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                trashedApplications: action.payload.applications,
                trashedCount: action.payload.applicationCount ?? null
            }
        case TRASHED_APPLICATIONS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                errorStatus: action.payload?.response?.status
            }

        case SET_SELECTED_APPLICATION:
            return {
                ...state,
                selectedApplication: action.payload
            }

        case UNSET_SELECTED_APPLICATION:
            return {
                ...state,
                selectedApplication: {}
            }

        case SINGLE_APPLICATIONS_REQIEST:
            return {
                ...state,
                loading: false
            }
        case SINGLE_APPLICATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                selectedApplication: action.payload,
            }

        case SINGLE_APPLICATIONS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                errorStatus: action.payload?.response?.status
            }

        case ACCEPT_APPLICATION_REQIEST:
            return {
                ...state,
                loading: false,
                actionLoading: true
            }
        case ACCEPT_APPLICATION_SUCCESS:
            switch (type) {
                case applicationType.all:
                    return {
                        ...state,
                        loading: false,
                        actionLoading: false,
                        applications: state.applications.filter(item => item._id !== application._id),
                        // acceptedApplications: [...state.acceptedApplications, application],
                        allApplicationCount: state.allApplicationCount - 1,
                        acceptedApplicationCount: state.acceptedApplicationCount + 1
                    }
                case applicationType.rejected:
                    return {
                        ...state,
                        loading: false,
                        actionLoading: false,
                        rejectedApplications: state.rejectedApplications.filter(item => item._id !== application._id),
                        // acceptedApplications: [...state.acceptedApplications, application],
                        rejectedApplicationCount: state.rejectedApplicationCount - 1,
                        acceptedApplicationCount: state.acceptedApplicationCount + 1
                    }
                case applicationType.completed:
                    return {
                        ...state,
                        loading: false,
                        actionLoading: false,
                        completedApplications: state.completedApplications.filter(item => item._id !== application._id),
                        // acceptedApplications: [...state.acceptedApplications, application],
                        completedApplicationCount: state.completedApplicationCount - 1,
                        acceptedApplicationCount: state.acceptedApplicationCount + 1
                    }
                case applicationType.next:
                    return {
                        ...state,
                        loading: false,
                        actionLoading: false,
                        nextApplications: state.nextApplications.filter(item => item._id !== application._id),
                        // acceptedApplications: [...state.acceptedApplications, application],
                        nextApplicationCount: state.nextApplicationCount - 1,
                        acceptedApplicationCount: state.acceptedApplicationCount + 1
                    }
                case applicationType.trashed:
                    return {
                        ...state,
                        loading: false,
                        actionLoading: false,
                        trashedApplications: state.trashedApplications.filter(item => item._id !== application._id),
                        // acceptedApplications: [...state.acceptedApplications, application],
                        trashedApplicationCount: state.trashedApplicationCount - 1,
                        acceptedApplicationCount: state.acceptedApplicationCount + 1
                    }
                default:
                    return {
                        ...state,
                        loading: false,
                        actionLoading: false,
                    }
            }

        case ACCEPT_APPLICATION_FAIL:
            return {
                ...state,
                loading: false,
                actionLoading: false,
                error: action.payload,
                errorStatus: action.payload
            }

        case REJECT_APPLICATION_REQIEST:
            return {
                ...state,
                loading: false,
                actionLoading: true,
            }
        case REJECT_APPLICATION_SUCCESS:
            switch (type) {
                case applicationType.all:
                    return {
                        ...state,
                        loading: false,
                        actionLoading: false,
                        applications: state.applications.filter(item => item._id !== application._id),
                        // rejectedApplications: [...state.rejectedApplications, application],
                        allApplicationCount: state.allApplicationCount - 1,
                        rejectedApplicationCount: state.rejectedApplicationCount + 1
                    }
                case applicationType.accepted:
                    return {
                        ...state,
                        loading: false,
                        actionLoading: false,
                        acceptedApplications: state.acceptedApplications.filter(item => item._id !== application._id),
                        // rejectedApplications: [...state.rejectedApplications, application],
                        acceptedApplicationCount: state.acceptedApplicationCount - 1,
                        rejectedApplicationCount: state.rejectedApplicationCount + 1
                    }
                case applicationType.next:
                    return {
                        ...state,
                        loading: false,
                        actionLoading: false,
                        nextApplications: state.nextApplications.filter(item => item._id !== application._id),
                        // rejectedApplications: [...state.rejectedApplications, application],
                        nextApplicationCount: state.nextApplicationCount - 1,
                        rejectedApplicationCount: state.rejectedApplicationCount + 1
                    }
                default: return {
                    ...state,
                    loading: false,
                    actionLoading: false,
                }
            }

        case REJECT_APPLICATION_FAIL:
            return {
                ...state,
                loading: false,
                actionLoading: false,
                error: action.payload,
                errorStatus: action.payload
            }

        case TRASH_APPLICATION_REQIEST:
            return {
                ...state,
                loading: false,
                actionLoading: true
            }
        case TRASH_APPLICATION_SUCCESS:
            switch (type) {
                case applicationType.all:
                    return {
                        ...state,
                        loading: false,
                        actionLoading: false,
                        applications: state.applications.filter(item => item._id !== application._id),
                        // trashedApplications: [...state.trashedApplications, application],
                        allApplicationCount: state.allApplicationCount - 1,
                        trashedApplicationCount: state.trashedApplicationCount + 1
                    }
                case applicationType.accepted:
                    return {
                        ...state,
                        loading: false,
                        actionLoading: false,
                        acceptedApplications: state.acceptedApplications.filter(item => item._id !== application._id),
                        // trashedApplications: [...state.trashedApplications, application],
                        acceptedApplicationCount: state.acceptedApplicationCount - 1,
                        trashedApplicationCount: state.trashedApplicationCount + 1
                    }
                case applicationType.rejected:
                    return {
                        ...state,
                        loading: false,
                        actionLoading: false,
                        rejectedApplications: state.rejectedApplications.filter(item => item._id !== application._id),
                        // trashedApplications: [...state.trashedApplications, application],
                        rejectedApplicationCount: state.rejectedApplicationCount - 1,
                        trashedApplicationCount: state.trashedApplicationCount + 1
                    }
                case applicationType.completed:
                    return {
                        ...state,
                        loading: false,
                        actionLoading: false,
                        completedApplications: state.completedApplications.filter(item => item._id !== application._id),
                        // trashedApplications: [...state.trashedApplications, application],
                        completedApplicationCount: state.completedApplicationCount - 1,
                        trashedApplicationCount: state.trashedApplicationCount + 1
                    }
                case applicationType.next:
                    return {
                        ...state,
                        loading: false,
                        actionLoading: false,
                        nextApplications: state.nextApplications.filter(item => item._id !== application._id),
                        // trashedApplications: [...state.trashedApplications, application],
                        nextApplicationCount: state.nextApplicationCount - 1,
                        trashedApplicationCount: state.trashedApplicationCount + 1
                    }
                default:
                    return {
                        ...state,
                        loading: false,
                        actionLoading: false,
                    }
            }

        case TRASH_APPLICATION_FAIL:
            return {
                ...state,
                loading: false,
                actionLoading: false,
                error: action.payload,
                errorStatus: action.payload
            }

        case TRASH_BACK_APPLICATION_REQIEST:
            return {
                ...state,
                loading: false,
                actionLoading: true,
            }
        case TRASH_BACK_APPLICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                actionLoading: false,
                trashedApplications: state.trashedApplications.filter(item => item._id !== application._id),
                // applications: [...state.applications, application],
                trashedApplicationCount: state.trashedApplicationCount - 1,
                allApplicationCount: state.allApplicationCount + 1
            }
        case TRASH_BACK_APPLICATION_FAIL:
            return {
                ...state,
                loading: false,
                actionLoading: false,
                error: action.payload
            }

        case UPDATE_APPLICATION_REQIEST:
            return {
                ...state,
                loading: false,
                actionLoading: true,
            }
        case UPDATE_APPLICATION_SUCCESS:
            let stateObj = { selectedApplication: application }
            switch (type) {
                case applicationType.all:
                    if (action.payload.selectedDate) {
                        stateObj = {
                            ...stateObj,
                            applications: state.applications.filter(item => item._id !== application._id),
                            // nextApplications: [...state.nextApplications, application],
                            allApplicationCount: state.allApplicationCount - 1,
                            nextApplicationCount: state.nextApplicationCount + 1
                        }
                    } else {
                        state.applications = state.applications.map(item => {
                            if (item._id == application._id) {
                                item = application
                            }
                            return item;
                        })
                    }
                    return {
                        ...state,
                        ...stateObj,
                        loading: false,
                        actionLoading: false,
                    }
                case applicationType.accepted:
                    if (action.payload.selectedDate) {
                        stateObj = {
                            ...stateObj,
                            acceptedApplications: state.acceptedApplications.filter(item => item._id !== application._id),
                            // nextApplications: [...state.nextApplications, application],
                            acceptedApplicationCount: state.acceptedApplicationCount - 1,
                            nextApplicationCount: state.nextApplicationCount + 1
                        }
                    } else {
                        state.acceptedApplications = state.acceptedApplications.map(item => {
                            if (item._id == application._id) {
                                item = application
                            }
                            return item;
                        })
                    }
                    return {
                        ...state,
                        ...stateObj,
                        loading: false,
                        actionLoading: false,
                    }
                case applicationType.next:
                    if (application.status === applicationStatus.next) {
                        state.nextApplications = state.nextApplications.map(item => {
                            if (item._id == application._id) {
                                item = application
                            }
                            return item;
                        })
                    } else {
                        state.nextApplications = state.nextApplications.filter(item => item._id !== application._id)
                        state.nextApplicationCount = state.nextApplicationCount - 1;
                        state.completedApplicationCount = state.completedApplicationCount + 1;
                    }
                    return {
                        ...state,
                        ...stateObj,
                        loading: false,
                        actionLoading: false,
                    }
                case applicationType.rejected:
                    if (action.payload.selectedDate) {
                        stateObj = {
                            ...stateObj,
                            rejectedApplications: state.rejectedApplications.filter(item => item._id !== application._id),
                            // nextApplications: [...state.nextApplications, application],
                            rejectedApplicationCount: state.rejectedApplicationCount - 1,
                            nextApplicationCount: state.nextApplicationCount + 1
                        }
                    } else {
                        state.rejectedApplications = state.rejectedApplications.map(item => {
                            if (item._id == application._id) {
                                item = application
                            }
                            return item;
                        })
                    }
                    return {
                        ...state,
                        ...stateObj,
                        loading: false,
                        actionLoading: false,
                    }
                default: return {
                    ...state,
                    loading: false,
                    actionLoading: false,
                }
            }
        case UPDATE_APPLICATION_FAIL:
            return {
                ...state,
                loading: false,
                actionLoading: false,
                error: action.payload
            }

        case DELETE_APPLICATION_REQIEST:
            return {
                ...state,
                loading: false,
                actionLoading: true,
            }
        case DELETE_APPLICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                actionLoading: false,
                trashedApplications: state.trashedApplications.filter(item => item._id !== application._id),
                trashedApplicationCount: state.trashedApplicationCount - 1
            }
        case DELETE_APPLICATION_FAIL:
            return {
                ...state,
                loading: false,
                actionLoading: false,
                error: action.payload
            }

        case SET_LOADER:
            return {
                ...state,
                loading: true,
            }

        case SET_LOADER:
            return {
                ...state,
                loading: true,
            }

        case CLEAR_LOADER:
            return {
                ...state,
                loading: false,
            }

        case SET_LOADER_FUNCTION:
            return {
                ...state,
                loaderFunction: action.payload
            }

        case SIGN_OUT_REQIEST:
            return {
                loading: true,
                ...state,
            }

        case SIGN_OUT_SUCCESS:
            return initialState

        case SIGN_OUT_FAIL:
            return {
                ...state,
                loading: false
            }

        case CLEAR_LOADER_FUNCTION:
            clearTimeout(state.loaderFunction)
            return {
                ...state
            }

        default: return state
    }
}
