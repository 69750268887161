import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from '../../../components/Spinner';
import { getApplicationSummary } from '../../../redux/actions/applicationsActions'
import { engToBanPage } from '../../../utils/helpers';

const DashboardHome = () => {
    const dispatch = useDispatch();
    const {
        loading,
        allApplicationCount,
        acceptedApplicationCount,
        rejectedApplicationCount,
        nextApplicationCount,
        completedApplicationCount
    } = useSelector(state => state.applications)

    useEffect(() => {
        if (
            allApplicationCount === 0 &&
            acceptedApplicationCount === 0 &&
            rejectedApplicationCount === 0 &&
            completedApplicationCount === 0 &&
            nextApplicationCount === 0
        ) {
            dispatch(getApplicationSummary());
        }
    }, [])

    const totatInfo = [
        {
            title: "অনির্ধারিত আবেদন",
            dataapplications: engToBanPage(allApplicationCount)
        },
        {
            title: "গৃহীত আবেদন",
            dataapplications: engToBanPage(acceptedApplicationCount)
        },
        {
            title: "বাতিলকৃত আবেদন",
            dataapplications: engToBanPage(rejectedApplicationCount)
        },
        {
            title: "সম্পন্ন হালাকাহ",
            dataapplications: engToBanPage(completedApplicationCount)
        },
        {
            title: "পরবর্তী হালাকাহ",
            dataapplications: engToBanPage(nextApplicationCount)
        }
    ]

    return (
        <div className='w-full'>
            {
                loading ?
                    <Spinner />
                    :
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-7  pt-7'>
                        {
                            totatInfo.map((card, i) =>
                                <div key={i} className="w-full px-7 py-7 bg-white rounded-md shadow-md border">
                                    <div className="flex items-center justify-between">
                                        <h1 className={`mt-2 text-lg font-semibold text-gray-800 ${i === 1 && 'text-green-500'} ${i === 2 && 'text-red-500'}`}>{card?.title}</h1>
                                    </div>
                                    <div>
                                        <p className="mt-5 text-5xl">{card?.dataapplications}</p>
                                    </div>
                                </div>
                            )
                        }
                    </div>
            }
        </div>
    );
};

export default DashboardHome;
