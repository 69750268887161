import { Dialog, Transition } from '@headlessui/react';
import React from 'react';
import { Fragment } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import LoadingAnimation from './LoadingAnimation';

const ConformationSubmitModal = ({ isOpen, onClose, onConfirmation, actionLoading }) => {
    return (
        <div>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={onClose}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden bg-white text-left align-middle transition-all rounded-lg shadow-xl flex items-center justify-center flex-col py-5 relative dashboard">

                                    <IoCloseOutline
                                        className='absolute top-3 right-3 hover:text-red-600 text-2xl transition-all ease-in duration-300 cursor-pointer'
                                        onClick={onClose}
                                    />
                                    <div className='mt-7 w-full px-10 text-center text-gray-600 flex flex-col items-center justify-center '>
                                        <h3 className={`text-xl`}>
                                            আপনি কি নিশ্চিত এই আবেদনটির  <span className='text-green-500 font-semibold'>পরিবর্তনগুলি সংরক্ষণ</span> করতে চান?</h3>
                                    </div>


                                    <div className="mt-7 flex items-center justify-center gap-3 mx-16 my-7 dashboard w-full">
                                        <button
                                            type="button"
                                            id={actionLoading ? 'not-allowed' : ''}
                                            disabled={actionLoading}
                                            className={`flex items-center w-full px-10 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-300 transform bg-red-500 rounded-md sm:w-auto sm:mt-0 hover:bg-lime-700  `}
                                            onClick={onClose}
                                        >
                                            না
                                        </button>
                                        <button
                                            type="button"
                                            id={actionLoading ? 'not-allowed' : ''}
                                            disabled={actionLoading}
                                            className={`flex items-center justify-center w-24 h-9 text-sm font-medium tracking-wide text-white transition-colors duration-300 transform bg-lime-600 rounded-md  sm:mt-0 hover:bg-lime-700`}
                                            onClick={onConfirmation}
                                        >

                                            {
                                                actionLoading ?
                                                    <LoadingAnimation
                                                        width={70} height={70}
                                                        color="#ffffff"
                                                        className='h-full'
                                                    />
                                                    :
                                                    <span className="mx-2">Yes</span>
                                            }
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};

export default ConformationSubmitModal;