import React from 'react'
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react'
import { IoCloseOutline } from "react-icons/io5";
import { useDispatch, useSelector } from 'react-redux';
import { rejectApplicationFromModal, trashApplication } from '../../redux/actions/applicationsActions';
import LoadingAnimation from '../LoadingAnimation';

const RejectModal = ({ rejectModalOpen, closeRejectModal, applicationNum, type, id, closeModal }) => {

    const dispatch = useDispatch();
    const { actionLoading } = useSelector(state => state.applications)

    const handleClickReject = async () => {
        dispatch(rejectApplicationFromModal({ id, type, closeRejectModal, closeModal }))
    };

    const close = () => {
        console.log('close')
    }
    return (
        <>
            <div>
                <div>
                    <Transition appear show={rejectModalOpen} as={Fragment}>
                        <Dialog as="div" className="relative z-10" onClose={close}>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-black bg-opacity-25" />
                            </Transition.Child>

                            <div className="fixed inset-0 overflow-y-auto">
                                <div className="flex min-h-full items-center justify-center text-center">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 scale-95"
                                        enterTo="opacity-100 scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 scale-100"
                                        leaveTo="opacity-0 scale-95"
                                    >
                                        <Dialog.Panel className="w-full max-w-md transform overflow-hidden bg-white text-left align-middle transition-all rounded-md shadow-xl flex items-center justify-center flex-col relative dashboard">

                                            <div className='bg-white px-16 py-11 relative flex items-center justify-center flex-col rounded-md max-w-md'>
                                                <button onClick={closeRejectModal}
                                                    id={actionLoading ? 'not-allowed' : ''}
                                                    disabled={actionLoading}
                                                >
                                                    <IoCloseOutline
                                                        className='absolute top-3 right-3 hover:text-red-600 text-2xl transition-all ease-in duration-300 cursor-pointer'

                                                    />
                                                </button>
                                                <h3 className='text-xl text-center'>আপনি কি নিশ্চিত এই আবেদনটি বাতিল করতে চান ?</h3>
                                                <h3 className='text-lg mt-4 font-semibold'>আবেদন নং: <span className='text-blue-500'>{applicationNum}</span></h3>
                                                <div className="mt-7 flex items-center justify-center gap-3 mx-16 my-7">
                                                    <button
                                                        id={actionLoading ? 'not-allowed' : ''}
                                                        disabled={actionLoading}
                                                        type="button"
                                                        className={`flex items-center w-20 h-9 text-sm font-medium tracking-wide text-white transition-colors duration-300 transform bg-lime-600 rounded-md  sm:mt-0 hover:bg-lime-700 flex items-center justify-center`}
                                                        onClick={closeRejectModal}
                                                    >
                                                        না
                                                    </button>

                                                    <button
                                                        id={actionLoading ? 'not-allowed' : ''}
                                                        disabled={actionLoading}
                                                        onClick={handleClickReject}
                                                        className={`flex items-center w-20  h-9 text-sm font-medium tracking-wide text-white transition-colors duration-300 transform bg-red-600 rounded-md  sm:mt-0 hover:bg-red-700 flex items-center justify-center`}
                                                    >
                                                        {actionLoading ? <LoadingAnimation /> : 'হ্যাঁ'}
                                                    </button>


                                                </div>
                                            </div>
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </Dialog>
                    </Transition>
                </div>
            </div>
        </>

    )
}

export default RejectModal