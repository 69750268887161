import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react'
import { IoCloseOutline } from "react-icons/io5";
import { useLocation, useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';
import animationData from '../components/loader-3dots.json';
import { applicationType } from '../utils/constants';
import { acceptApplication, rejectApplication } from '../redux/actions/applicationsActions';


const LoadingAnimation = ({ width, height, color }) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
            colorFilters: [
                {
                    keypath: 'Ellipse 1.Fill 1',
                    color: color,
                },
                {
                    keypath: 'Shape Layer 2.Stroke 1',
                    color: color,
                },
                // Add more color filters for other elements if needed
            ],
        },
    };

    return <div style={{ width: width, height: height, color: color }}>
        <Lottie options={defaultOptions} />
    </div>;
};


const ConfromationModal = ({ btnTrue, id, confirmCloseModal, isConfirmOpen, applicationNumber, isRestoreBtn }) => {

    const dispatch = useDispatch();
    const { actionLoading } = useSelector(state => state.applications)
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const previousRoute = localStorage.getItem("previousRoute");

    const handleClickAccept = async () => {
        setIsLoading(true)

        dispatch(acceptApplication({ id, type: applicationType.all, confirmCloseModal, setIsLoading, navigate, previousRoute }))
    };

    const handleClickReject = async () => {
        setIsLoading(true)

        dispatch(rejectApplication({ id, type: applicationType.all, confirmCloseModal, setIsLoading, navigate, previousRoute }))
    };


    return (
        <div>
            <div>
                <Transition appear show={isConfirmOpen} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={confirmCloseModal}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-black bg-opacity-25" />
                        </Transition.Child>

                        <div className="fixed inset-0 overflow-y-auto">
                            <div className="flex min-h-full items-center justify-center text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden bg-white text-left align-middle transition-all rounded-lg shadow-xl flex items-center justify-center flex-col py-5 relative dashboard">

                                        <IoCloseOutline
                                            className='absolute top-3 right-3 hover:text-red-600 text-2xl transition-all ease-in duration-300 cursor-pointer'
                                            onClick={confirmCloseModal}
                                        />

                                        <div className='mt-7 w-full px-10 text-center text-gray-600 flex flex-col items-center justify-center '>
                                            {
                                                previousRoute === '/asf-admin/rejected' &&
                                                <h3 className={`text-xl ${isRestoreBtn === false ? 'hidden' : 'block'}`}>
                                                    আপনি কি নিশ্চিত এই আবেদনটি   <span className='text-green-500 font-semibold'>গ্রহণ</span> করতে চান?</h3>
                                            }
                                            {
                                                previousRoute === '/asf-admin/accepted' &&

                                                <h3 className={`text-xl ${isRestoreBtn === false ? 'hidden' : 'block'}`}>
                                                    আপনি কি নিশ্চিত এই আবেদনটি   <span className='text-red-500 font-semibold'>বাতিল </span> করতে চান?</h3>
                                            }
                                            {
                                                previousRoute === '/asf-admin/applications' &&
                                                <>
                                                    {
                                                        btnTrue === true ?
                                                            <h3 className={`text-xl ${isRestoreBtn === false ? 'hidden' : 'block'}`}>
                                                                আপনি কি নিশ্চিত এই আবেদনটি   <span className='text-green-500 font-semibold'>গ্রহণ</span> করতে চান?</h3>
                                                            :
                                                            <h3 className={`text-xl ${isRestoreBtn === false ? 'hidden' : 'block'}`}>
                                                                আপনি কি নিশ্চিত এই আবেদনটি   <span className='text-red-500 font-semibold'>বাতিল </span> করতে চান?</h3>
                                                    }
                                                </>
                                            }

                                            <h3 className='text-lg mt-4 font-semibold'>আবেদন নং: <span className='text-blue-500'>{applicationNumber}</span></h3>
                                        </div>

                                        <div className="mt-7 flex items-center justify-center gap-3 mx-16 my-7 dashboard w-full">
                                            <button
                                                disabled={actionLoading ? true : false}
                                                type="button"
                                                className={`flex items-center w-full px-10 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-300 transform  rounded-md sm:w-auto sm:mt-0  ${(btnTrue === true || previousRoute === '/asf-admin/rejected') ? 'bg-red-600 hover:bg-red-700' : 'bg-lime-600 hover:bg-lime-700'} `}
                                                onClick={confirmCloseModal}
                                            >
                                                না
                                            </button>

                                            {
                                                previousRoute === '/asf-admin/rejected' &&
                                                <button
                                                    disabled={actionLoading ? true : false}
                                                    type="button"
                                                    onClick={handleClickAccept}
                                                    className={`flex items-center justify-center w-24 h-9 text-sm font-medium tracking-wide text-white transition-colors duration-300 transform bg-lime-600 rounded-md  hover:bg-lime-700`}
                                                >
                                                    {
                                                        isLoading ?
                                                            <LoadingAnimation
                                                                width={70} height={70}
                                                                color="#ffffff"
                                                                className='h-full'
                                                            />
                                                            :
                                                            <span className="mx-2">গ্রহণ</span>
                                                    }

                                                </button>
                                            }
                                            {
                                                previousRoute === '/asf-admin/accepted' &&
                                                <button
                                                    disabled={actionLoading ? true : false}
                                                    onClick={handleClickReject}
                                                    type="button"
                                                    className={`flex items-center justify-center w-24 h-9 text-sm font-medium tracking-wide text-white transition-colors duration-300 transform bg-red-600 rounded-md  hover:bg-red-700 `}
                                                >
                                                    {
                                                        isLoading ?
                                                            <LoadingAnimation
                                                                width={70} height={70}
                                                                color="#ffffff"
                                                                className='h-full'
                                                            />
                                                            :
                                                            <span className="mx-2">বাতিল</span>
                                                    }
                                                </button>
                                            }
                                            {
                                                previousRoute === '/asf-admin/applications' &&
                                                <>
                                                    {
                                                        btnTrue === true ?
                                                            <button
                                                                disabled={actionLoading ? true : false}
                                                                type="button"
                                                                onClick={handleClickAccept}
                                                                className={`flex items-center justify-center w-24 h-9 text-sm font-medium tracking-wide text-white transition-colors duration-300 transform bg-lime-600 rounded-md hover:bg-lime-700`}
                                                            >
                                                                {
                                                                    isLoading ?
                                                                        <LoadingAnimation
                                                                            width={70} height={70}
                                                                            color="#ffffff"
                                                                            className='h-full'
                                                                        />
                                                                        :
                                                                        <span className="mx-2">গ্রহণ</span>
                                                                }
                                                            </button>
                                                            :
                                                            <button
                                                                disabled={actionLoading ? true : false}
                                                                onClick={handleClickReject}
                                                                type="button"
                                                                className={`flex items-center justify-center w-24 h-9 text-sm font-medium tracking-wide text-white transition-colors duration-300 transform bg-red-600 rounded-md  hover:bg-red-700 `}
                                                            >
                                                                {
                                                                    isLoading ?
                                                                        <LoadingAnimation
                                                                            width={70} height={70}
                                                                            color="#ffffff"
                                                                            className='h-full'
                                                                        />
                                                                        :
                                                                        <span className="mx-2">বাতিল</span>
                                                                }
                                                            </button>
                                                    }
                                                </>

                                            }
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition>
            </div>
        </div>
    );
};

export default ConfromationModal;