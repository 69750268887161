import React from 'react';
import dot from '../../src/assets/black-circle.png';

const ApplyRules = () => {

    const applyRules = [
        "হালাকাহ আস-সুন্নাহ ফাউন্ডেশনের দা'ওয়াহ কার্যক্রমের অংশ। স্থানীয় আয়োজকগণ সহযোগীর ভূমিকায় থাকবেন। সুতরাং হালাকাহ বিষয়ক যেকোনো সিদ্ধান্ত গ্রহণের ক্ষেত্রে আস-সুন্নাহ ফাউন্ডেশনের মতামতই চূড়ান্ত বলে বিবেচিত হবে।",
        "হালাকাহ বাস্তবায়নের পূর্বে পূর্ণাঙ্গ কর্মসূচি ফাউন্ডেশনকে পাঠাতে হবে।",
        "হালাকাহর ব্যয় নির্বাহ করা ব্যক্তি/ব্যক্তিবর্গের উপার্জন হালাল হতে হবে।",
        "বিরক্তির কারণ হতে পারে এরকম কোনো শিশু নিয়ে আসা থেকে বিরত থাকতে হবে; বিশেষত মহিলাদের স্থানে।",
        "মহিলাদের আয়োজনে পরিপূর্ণ শরয়ী পর্দা এবং পুরুষ-মহিলা পৃথক প্রবেশ-পথ নিশ্চিত করতে হবে।",
        "কোনো শর্ত ভঙ্গ করলে হালাকাহ বাতিলের এখতিয়ার ফাউন্ডেশনের থাকবে।",
        "হালাকাহর উদ্দেশ্য হতে হবে শুধু দীন প্রচার, সমাজ সংস্কার ও আল্লাহর সন্তুষ্টি অর্জন।",
        "অর্থ সংগ্রহ করা হালাকাহ আয়োজনের উদ্দেশ্য হতে পারবে না।",
        "আলোচককে কোনো হাদিয়া বা বিনিময় দিতে হবে না।",
        "হালাকাহ দিনে করতে হবে, রাতে করলে এশার আগে সমাপ্ত করতে হবে।",
        "মসজিদ/মিলনায়তনের মধ্যে শুধু সাউন্ড বক্স ব্যবহার করা যাবে, মাইক ব্যবহার করা যাবে না।",
        "হালাকাহর জন্য সাধারণ মানুষ থেকে কোনোরূপ চাঁদা সংগ্রহ করা যাবে না।",
        "শায়খ আহমাদুল্লাহর পরিচয় হিসেবে ‘শায়খ আহমাদুল্লাহ, চেয়ারম্যান, আস-সুন্নাহ ফাউন্ডেশন’ লিখতে হবে। এছাড়া অন্য কোনো পদবী বা বিশেষণ লেখা যাবে না।",
        "হালাকাহর উপস্থাপক শুধু নাম ও উল্লিখিত পদবী বা বিশেষণ উল্লেখ করবেন, নামের আগে বা পরে অন্য কোনো বিশেষণ ব্যবহার করবেন না।",
        "কোনো ব্যক্তি-গোষ্ঠীর বিরোধিতা কিংবা কোনো প্রকার দলীয় এজেন্ডা বাস্তবায়নের উদ্দেশে হালাকাহর আয়োজন করা যাবে না।",
        "রাষ্ট্রীয় ও সামাজিক শান্তি-শৃঙ্খলা বিঘ্ন হতে পারে, এরকম কোনো অনুষঙ্গ রাখা যাবে না।",
        "বলিষ্ঠ ও চৌকস ভলান্টিয়ার সার্ভিস রাখতে হবে, যারা আলোচকের নিরাপত্তা ও রিসিভ করা থেকে গাড়িতে ওঠার পর কিছুদূর পর্যন্ত এগিয়ে দেওয়ার কাজটি সুচারুরূপে আঞ্জাম দেবেন।",
        "কোনো কারণে হালাকাহ বাতিল হলে তিনদিন আগে জানাতে হবে।",
        "আহমাদুল্লাহর শারীরিক অসুস্থতা কিংবা আকস্মিক কোনো সমস্যা দেখা দিলে হালাকাহ আয়োজকদের অবহিত করা হবে। এক্ষেত্রে কোনো প্রকার ক্ষতিপূরণ দাবি করা যাবে না।",
        "আলোচনা চলাকালে মসজিদ/মিলনায়তনের মধ্যে কোনো প্রকার হৈচৈ বা শব্দ যেন না হয়।",
        "আলোচনার সময় শ্রেতাদেরকে মোবাইল বন্ধ বা সাইলেন্ট রাখতে হবে।",
        "প্রিন্টেড পোস্টার, লিফলেট, ব্যানার ইত্যাদিতে ছবি ব্যবহার করা যাবে না। তবে অনলাইনে প্রচারযোগ্য পোস্টার, ব্যানার ও লিফলেটে ছবি ব্যবহার করা যেতে পারে।",
        "পোস্টার ডিজাইন করার পর প্রিন্ট করার আগে দেখাতে হবে।"
    ]
    return (
        <div className='max-w-[1320px]  mx-auto md:px-3 py-2 mx-auto  mt-16'>
            <h3 className='text-xl font-semibold'>নিচের শর্তাবলি মেনে আবেদন করতে হবে:</h3>
            <div className='mt-3 flex flex-col items-left justify-start'>
                {
                    applyRules.map((rule, i) =>
                        <div key={i} className='flex items-start justify-left  w-full'>
                            <img
                                src={dot}
                                className=' h-2 w-2 rounded-full mt-2 mr-3'
                            />
                            <p
                                key={i}
                                className='text-lg text-left '
                            >{rule}</p>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default ApplyRules;