import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { singOut } from '../redux/actions/authActions';

const PrivateRoutes = ({ children }) => {
    const dispatch = useDispatch()
    const { isAuthenticated, loading, user } = useSelector(state => state.auth);
    const { errorStatus, loading: appLoading } = useSelector(state => state.applications)
    const location = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    useEffect(() => {
        if (errorStatus == 401 || errorStatus == 403) {
            dispatch(singOut())
        }
    }, [errorStatus])

    if (loading) {
        return <></>
    }

    if (isAuthenticated) {
        return children
    }

    if (loading === false && appLoading === false && Object.keys(user).length === 0) {
        if (localStorage.getItem('accessToken')) {
            if (isAuthenticated === false)
                return <Navigate to='/signin' state={{ from: location }} replace />
        } else {
            return <Navigate to='/signin' state={{ from: location }} replace />
        }

    }
};

export default PrivateRoutes;