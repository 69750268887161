import { banglaPageNo, months } from './constants'

export function engToBanPage(num) {
    const strNum = num.toString();
    const length = strNum.length;
    let str = '';
    for (let i = 0; i < length; i++) {
        str += banglaPageNo[strNum[i]]
    }
    return str;
}

export function formatDate(date) {
    const dateObj = new Date(date);
    let year = dateObj.getFullYear().toString();
    let month = (dateObj.getMonth() + 1).toString();
    if (month.length === 1) {
        month = '0' + month;
    }
    let day = dateObj.getDate().toString();
    if (day.length === 1) {
        day = '0' + day;
    }
    return `${year}-${month}-${day}`;
}

export const formatDateForViewers = arg => {
    const d = new Date(arg);
    const date = d.getDate();
    const month = months[d.getMonth()];
    const year = d.getFullYear();
    return `${month} ${engToBanPage(date)}, ${engToBanPage(year)}`
}

export const clearLocalStorage = () => {
    localStorage.removeItem('previousRoute');
    localStorage.removeItem('curAppPage');
    localStorage.removeItem('accAppPage');
    localStorage.removeItem('rejectedAppPage');
    localStorage.removeItem('nextAppPage');
    localStorage.removeItem('completedAppPage');
    localStorage.removeItem('trashedAppPage');
    localStorage.removeItem('curAppItem');
    localStorage.removeItem('accAppItem');
    localStorage.removeItem('rejectedAppItem');
    localStorage.removeItem('nextAppItem');
    localStorage.removeItem('completedAppItem');
    localStorage.removeItem('trashedAppItem');
}

export const checkValidEmail = email => {
    // eslint-disable-next-line no-useless-escape
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w]{2,4}$/i
    return regex.test(email)
} 