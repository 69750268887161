import React from 'react'
import { IoCloseOutline } from "react-icons/io5";
import { useDispatch, useSelector } from 'react-redux';
import { trashApplication } from '../../redux/actions/applicationsActions';
import LoadingAnimation from '../LoadingAnimation';

const ApplicationDeleteModal = ({ open, setOpen, applicationNum, type, id }) => {

    const dispatch = useDispatch();
    const { actionLoading } = useSelector(state => state.applications)

    const handleClickToTrash = async () => {
        dispatch(trashApplication({ id, setOpen, type }))
    };

    return (
        <>
            <div className={`overlay ${open ? 'overlay-show ' : ''}`}></div>
            <div className={` delete-modal ${open ? 'delete-modal-show ' : ''}`}>
                <div className='bg-white px-16 py-11 relative flex items-center justify-center flex-col rounded-md max-w-md'>
                    <button onClick={() => setOpen(prev => !prev)}
                        id={actionLoading ? 'not-allowed' : ''}
                        disabled={actionLoading}
                    >
                        <IoCloseOutline
                            className='absolute top-3 right-3 hover:text-red-600 text-2xl transition-all ease-in duration-300 cursor-pointer'

                        />
                    </button>
                    <h3 className='text-xl text-center'>আপনি কি নিশ্চিত এই আবেদনটি ট্র্যাশে নিতে চান ?</h3>
                    <h3 className='text-lg mt-4 font-semibold'>আবেদন নং: <span className='text-blue-500'>{applicationNum}</span></h3>
                    <div className="mt-7 flex items-center justify-center gap-3 mx-16 my-7">
                        <button
                            id={actionLoading ? 'not-allowed' : ''}
                            disabled={actionLoading}
                            type="button"
                            className={`flex items-center w-20 h-9 text-sm font-medium tracking-wide text-white transition-colors duration-300 transform bg-lime-600 rounded-md  sm:mt-0 hover:bg-lime-700 flex items-center justify-center`}
                            onClick={() => setOpen(prev => !prev)}
                        >
                            না
                        </button>

                        <button
                            id={actionLoading ? 'not-allowed' : ''}
                            disabled={actionLoading}
                            onClick={handleClickToTrash}
                            className={`flex items-center w-20  h-9 text-sm font-medium tracking-wide text-white transition-colors duration-300 transform bg-red-600 rounded-md  sm:mt-0 hover:bg-red-700 flex items-center justify-center`}
                        >
                            {actionLoading ? <LoadingAnimation /> : 'হ্যাঁ'}
                        </button>


                    </div>
                </div>
            </div>
        </>

    )
}

export default ApplicationDeleteModal