import React from 'react'
import districts from '../json/districts';

const districtsArray = [];
for (const key in districts) {
    districtsArray.push({ districtId: districts[key].id, districtName: districts[key].bn_name })
}

const Filter = ({ subDistrictArray, setSubDistrictArray, cityCorporationArray, setCityCorporationArray, setInstitutionId, setDistrictId, setPage, setSubDistrictId, districtId, subDistrictId, institutionId, cityCorporation, setCityCorporation }) => {
    return (
        <form
            className="flex overflow-hidden bg-white rtl:flex-row-reverse gap-5">
            <div className='flex items-center divide-x rounded-lg border'>
                <div className='relative'>
                    <select
                        value={institutionId}
                        onChange={e => { setInstitutionId(e.target.value); setDistrictId(''); setPage(0); setSubDistrictId(''); setCityCorporation(''); }}
                        placeholder='প্রতিষ্ঠানের নাম লিখুন'
                        type="text" className={`w-full appearance-none placeholder-gray-400/70 px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm outline-none mr-5`}
                    >
                        <option value="">প্রতিষ্ঠানের ধরন</option>
                        <option value="1">কেন্দ্রীয়/বড় মসজিদ</option>
                        <option value="2">বিশ্ববিদ্যালয়/কলেজ/মেডিকেল কলেজ</option>
                        <option value="3">মিলনায়তন</option>
                        <option value="4">মাদরাসা</option>
                    </select>

                    <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                        <svg className="fill-current h-4 w-4" viewBox="0 0 20 20">
                            <path d="M10 12l-6-6 1.41-1.41L10 9.17l4.59-4.58L16 6l-6 6z" />
                        </svg>
                    </div>

                </div>


                <div className='relative '>
                    <select id="district"
                        value={districtId}
                        className={`w-full appearance-none placeholder-gray-400/70 px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm mr-2 outline-none`}
                        onChange={(e) => { setDistrictId(e.target.value); setPage(0); setSubDistrictId() }}
                    >
                        < option value="">জেলা</option>
                        {districtsArray.map((district) => (
                            <option key={district.districtId} value={district.districtId}

                            >
                                {district.districtName}
                            </option>
                        ))}
                    </select>

                    <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                        <svg className="fill-current h-4 w-4" viewBox="0 0 20 20">
                            <path d="M10 12l-6-6 1.41-1.41L10 9.17l4.59-4.58L16 6l-6 6z" />
                        </svg>
                    </div>
                </div>

                <div className='relative '>
                    <select id="subDistrictId"
                        value={subDistrictId}
                        onChange={(e) => { setSubDistrictId(e.target.value); setCityCorporation(''); setPage(0) }}
                        className={`w-full appearance-none placeholder-gray-400/70 px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm mr-2 outline-none`}
                    >
                        <option value="">উপজেলা</option>
                        {cityCorporationArray?.length > 0 && (
                            <option value="city-corporation">সিটি কর্পোরেশন</option>
                        )}
                        {subDistrictArray.map((subDistrict) => (
                            <option key={subDistrict.subDistrictId} value={subDistrict.subDistrictId}>
                                {subDistrict.subDistrictName}
                            </option>
                        ))}
                    </select>

                    <div className="absolute inset-y-0  right-0 flex items-center px-2 pointer-events-none">
                        <svg className="fill-current h-4 w-4" viewBox="0 0 20 20">
                            <path d="M10 12l-6-6 1.41-1.41L10 9.17l4.59-4.58L16 6l-6 6z" />
                        </svg>
                    </div>
                </div>

                {subDistrictId === 'city-corporation' &&
                    <div className='relative '>
                        <select id="cityCorporation"
                            value={cityCorporation}
                            onChange={(e) => { setCityCorporation(e.target.value); setPage(0) }}
                            className={`w-full appearance-none placeholder-gray-400/70 px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm mr-2 outline-none`}
                        >
                            <option value="">সিটি কর্পোরেশন</option>
                            {cityCorporationArray.map((cityCorporation) => (
                                <option key={cityCorporation} value={cityCorporation}>
                                    {cityCorporation}
                                </option>
                            ))}
                        </select>

                        <div className="absolute inset-y-0  right-0 flex items-center px-2 pointer-events-none">
                            <svg className="fill-current h-4 w-4" viewBox="0 0 20 20">
                                <path d="M10 12l-6-6 1.41-1.41L10 9.17l4.59-4.58L16 6l-6 6z" />
                            </svg>
                        </div>
                    </div>
                }
            </div>
            <div className='relative rounded-lg border '>
                <button type='button' onClick={() => { setInstitutionId(''); setDistrictId(''); setSubDistrictId(''); setSubDistrictArray([]); setPage(0); setCityCorporationArray([]); setCityCorporation('') }} className={`w-full appearance-none placeholder-gray-400/70 rounded-lg px-5 py-2 text-xs font-medium  transition-colors duration-200 sm:text-sm mr-2 outline-none ${districtId || subDistrictId || institutionId ? 'bg-primary text-white' : 'bg-gray-100 cursor-not-allowed text-gray-300'}`}>ফিল্টার মুছুন</button>
            </div>
        </form>
    )
}

export default Filter;