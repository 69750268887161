import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RiFileEditLine } from "react-icons/ri";
import { BsArrowCounterclockwise, BsTrash } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";
import { Link } from 'react-router-dom';
import DeleteModal from './DeleteModal';
import districts from '../json/districts'
import divisions from '../json/divisions'
import subDistricts from '../json/subDistricts'
import { setSelectedApplication, } from '../redux/actions/applicationsActions';
import { applicationType } from '../utils/constants';
import { engToBanPage } from '../utils/helpers';
import { MdOutlineDeleteForever } from 'react-icons/md';

const ApplicationRow = ({ formData, serialNo, i, type, deleteOpen, isRestoreBtn, offDeleteModal, setOpen, setApplicationId, setTrashModalOpen, openModal, open, setApplicationNum }) => {
    const dispatch = useDispatch();
    const { applicante_mobile, applicante_name, institutionType } = formData;

    const [division, setSelectedDivision] = useState(null);
    const [district, setSelectedDistrict] = useState(null);
    const [subDistrict, setSelectedSubDistrict] = useState(null);

    const applicationIdSetting = () => {
        setApplicationId(formData?._id)
        setOpen(prev => !prev);
        setApplicationNum(formData?.applicationNumber ?? i + 1)
    }

    const createdAtDate = (type == applicationType.next || type == applicationType.completed) ? new Date(formData?.selected_date) : new Date(formData?.createdAt);

    const day = createdAtDate.getDate();
    const month = createdAtDate.getMonth() + 1;
    const year = createdAtDate.getFullYear();

    const formattedCreatedAt = `${day}/${month}/${year}`;

    useEffect(() => {
        const fetchSelectedData = async () => {
            try {
                const divisionId = formData?.division;
                const districtId = formData?.district;
                const subDistrictId = formData?.subDistrict;

                const selectedDivision = divisions[divisionId];
                setSelectedDivision(selectedDivision?.bn_name);

                const selectedDistrict = districts[districtId]
                setSelectedDistrict(selectedDistrict?.bn_name);

                const selectedSubDistrict = subDistricts[subDistrictId]
                setSelectedSubDistrict(selectedSubDistrict?.bn_name);

            } catch (error) {
                console.log(error);
            }
        };

        fetchSelectedData();
    }, [formData?.district, formData?.subDistrict, formData?.division]);



    const mobile = applicante_mobile?.toString();
    return (
        <tr>
            <td className="px-4 py-4 text-sm  whitespace-nowrap">
                <div>
                    <h2 className=" text-gray-800 ">{engToBanPage(serialNo)}</h2>
                </div>
            </td>
            <td className=" py-4 text-sm  whitespace-nowrap">
                <div>
                    <h2 className=" text-gray-800 ">{applicante_name?.slice(0, 30)}{applicante_name?.length > 30 && '...'}</h2>
                </div>
            </td>
            <td className="px-4 py-4 text-sm  whitespace-nowrap ">
                <div>
                    <h2 className=" text-gray-800 ">{formattedCreatedAt}</h2>
                </div>
            </td>
            <td className="px-4 py-4 text-sm whitespace-nowrap  ">
                <div>
                    <h4 className="text-gray-700  ">{mobile?.slice(2, 13)}</h4>
                </div>
            </td>

            <td className="px-4 py-4 text-sm whitespace-nowrap w-44">
                <div>
                    <h4 className="text-gray-700 ">{district}</h4>
                </div>
            </td>

            <td className="px-4 py-4 text-sm whitespace-nowrap w-48">
                <div>
                    {
                        formData?.cityCorporation
                            ?
                            <h4 className="text-gray-700 ">{formData?.cityCorporation}</h4>
                            :
                            <h4 className="text-gray-700 ">{subDistrict}</h4>
                    }
                </div>
            </td>
            <td className="px-4 py-4 text-sm  whitespace-nowrap"
            >
                <div className="inline px-3 py-1 text-[14px] font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 " >

                    {
                        institutionType === '1' &&
                        'কেন্দ্রীয়/বড় মসজিদ'
                    }
                    {
                        institutionType === '2' &&
                        'বিশ্ববিদ্যালয়/কলেজ'
                    }
                    {
                        institutionType === '3' &&
                        'মিলনায়তন'
                    }
                    {
                        institutionType === '4' &&
                        'মাদরাসা'
                    }
                </div>
            </td>

            <td className="px-4 py-4 text-sm whitespace-nowrap">
                {type === applicationType.trashed ?
                    <div className="flex items-center gap-x-6">
                        <button
                            onClick={() => { setApplicationId(formData?._id); setOpen(prev => !prev); setApplicationNum(formData?.applicationNumber ?? i + 1) }}
                            className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg text-xl hover:text-red-500">
                            <MdOutlineDeleteForever />
                        </button>

                        <button onClick={() => { setApplicationId(formData?._id); setTrashModalOpen(prev => !prev); setApplicationNum(formData?.applicationNumber ?? i + 1) }} className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg text-xl hover:text-blue-500 ">
                            <BsArrowCounterclockwise />
                        </button>
                        <button onClick={() => { dispatch(setSelectedApplication(formData)); openModal() }} className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg text-xl  hover:text-green-600">
                            <AiOutlineEye />
                        </button>
                    </div>
                    :
                    <div className="flex items-center gap-x-6">
                        <button
                            onClick={applicationIdSetting}
                            className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg text-xl  hover:text-red-300">
                            <BsTrash />
                        </button>

                        <button onClick={() => { dispatch(setSelectedApplication(formData)); openModal() }} className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg text-xl  hover:text-green-600">
                            <AiOutlineEye />
                        </button>

                        <Link to={`/asf-admin/edit/${formData?._id}?type=${type}`} onClick={() => dispatch(setSelectedApplication(formData))} className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg text-xl  hover:text-yellow-500">
                            <RiFileEditLine />
                        </Link>
                    </div>}
            </td>

            {
                <DeleteModal
                    deleteOpen={deleteOpen}
                    offDeleteModal={offDeleteModal}
                    id={formData?._id}
                    applicationNumber={formData?.applicationNumber}
                    type={type}
                    isRestoreBtn={isRestoreBtn}
                />
            }

        </tr>
    );
};

export default ApplicationRow;