import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react'
import { IoCloseOutline } from "react-icons/io5";
import { useLocation } from 'react-router-dom';
import { deleteApplication, trashApplication, trashBackApplication } from '../redux/actions/applicationsActions';
import { useDispatch, useSelector } from 'react-redux';



const DeleteModal = ({ deleteOpen, offDeleteModal, applicationNumber, id, isRestoreBtn, type }) => {
    const dispatch = useDispatch();
    const { actionLoading } = useSelector(state => state.applications)
    const location = useLocation();
    const isTrashRoute = location.pathname === '/asf-admin/trash';

    const handleClickToTrash = async () => {
        dispatch(trashApplication({ id, offDeleteModal, type }))
    };

    const handleClickTrashToBack = async () => {
        dispatch(trashBackApplication({ id }))
    };

    const handleClickToDelete = async () => {
        dispatch(deleteApplication({ id, offDeleteModal }))
    };


    return (
        <div>
            <div>
                <Transition appear show={deleteOpen} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={offDeleteModal}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-black bg-opacity-[0.1]" />
                        </Transition.Child>

                        <div className="fixed inset-0 overflow-y-auto">
                            <div className="flex min-h-full items-center justify-center text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden bg-white text-left align-middle transition-all rounded-lg shadow-3xl flex items-center justify-center flex-col py-5 relative dashboard">

                                        <IoCloseOutline
                                            className='absolute top-3 right-3 hover:text-red-600 text-2xl transition-all ease-in duration-300 cursor-pointer'
                                            onClick={offDeleteModal}
                                        />

                                        <div className='mt-7 w-full px-10 text-center text-gray-600 flex flex-col items-center justify-center '>

                                            {
                                                isTrashRoute ?
                                                    <>
                                                        <h3 className={`text-xl ${isRestoreBtn === true ? 'hidden' : 'block'}`}>
                                                            আপনি কি নিশ্চিত এই আবেদনটি স্থায়ীভাবে <span className='text-red-500 font-semibold'>মুছে</span> দিতে চান?</h3>
                                                        <h3 className={`text-xl ${isRestoreBtn === false ? 'hidden' : 'block'}`}>
                                                            আপনি কি নিশ্চিত এই আবেদনটি আবার পূর্বাবস্থায় <span className='text-red-500 font-semibold'>ফিরিয়ে</span> নিতে চান?</h3>
                                                    </>
                                                    :
                                                    <>
                                                        <h3 className='text-xl'>আপনি কি নিশ্চিত এই আবেদনটি ট্র্যাশে নিতে চান?</h3>
                                                    </>
                                            }
                                            <h3 className='text-lg mt-4 font-semibold'>আবেদন নং: <span className='text-blue-500'>{applicationNumber}</span></h3>
                                        </div>

                                        <div className="mt-7 flex items-center justify-end gap-3 mx-16 my-7 dashboard">
                                            <button
                                                id={actionLoading ? 'not-allowed' : ''}
                                                disabled={actionLoading}
                                                type="button"
                                                className={`flex items-center w-full px-10 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-300 transform bg-lime-600 rounded-md sm:w-auto sm:mt-0 hover:bg-lime-700 `}
                                                onClick={offDeleteModal}
                                            >
                                                না
                                            </button>
                                            {
                                                isTrashRoute ?
                                                    <>
                                                        <button
                                                            id={actionLoading ? 'not-allowed' : ''}
                                                            disabled={actionLoading}
                                                            onClick={handleClickToDelete}
                                                            className={`flex items-center w-full px-10 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-300 transform bg-red-600 rounded-md sm:w-auto sm:mt-0 hover:bg-red-700  ${isRestoreBtn === true && 'hidden'}`}
                                                        >হ্যাঁ</button>
                                                        <button
                                                            id={actionLoading ? 'not-allowed' : ''}
                                                            disabled={actionLoading}
                                                            onClick={handleClickTrashToBack}
                                                            className={`flex items-center w-full px-10 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-300 transform bg-red-600 rounded-md sm:w-auto sm:mt-0 hover:bg-red-700  ${isRestoreBtn === true ? 'block' : 'hidden'}`}
                                                        >হ্যাঁ</button>

                                                    </>
                                                    :
                                                    <button
                                                        id={actionLoading ? 'not-allowed' : ''}
                                                        disabled={actionLoading}
                                                        onClick={handleClickToTrash}
                                                        className='flex items-center w-full px-10 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-300 transform bg-red-600 rounded-md sm:w-auto sm:mt-0 hover:bg-red-700 '
                                                    >হ্যাঁ</button>
                                            }
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition>
            </div>
        </div>
    );
};

export default DeleteModal;