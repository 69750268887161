import { Dialog, Transition } from '@headlessui/react';
import React, { useEffect, useRef } from 'react';
import ReactToPdf from 'react-to-pdf';
import { Fragment, useState } from 'react'
import { IoCloseOutline } from "react-icons/io5";
import logo from '../../src/assets/shaikh_ahmadullah_logo.png';
import assunnah from '../../src/assets/asf_logo.png';
import AcceptIcon from '../../src/assets/icon/accept.png';
import RejectIcon from '../../src/assets/icon/reject.png';
import DownloadIcon from '../../src/assets/icon/download.png';
import { useLocation } from 'react-router-dom';

import districts from '../json/districts'
import divitions from '../json/divisions'
import subDistricts from '../json/subDistricts'
import { useSelector } from 'react-redux';
import { applicationType } from '../utils/constants';
import { formatDateForViewers } from '../utils/helpers';
import AcceptModal from './Modals/AcceptModal';
import RejectModal from './Modals/RejectModal';
import { participantType } from '../utils/constants'

const AdminViewModal = ({ isOpen, closeModal, type }) => {
    const { actionLoading, selectedApplication: formData } = useSelector(state => state.applications)
    const ref = useRef()

    const [division, setSelectedDivision] = useState();
    const [district, setSelectedDistrict] = useState(null);
    const [subDistrict, setSelectedSubDistrict] = useState(null);

    const options = {
        orientation: 'portrait',
        unit: 'in',
        format: [15, 9]
    };

    const [acceptModalOpen, setAcceptModalOpen] = useState(false)
    const [rejectModalOpen, setRejectModalOpen] = useState(false)

    function closeAcceptModal() {
        setAcceptModalOpen(false)
    }

    function openAcceptModal() {
        setAcceptModalOpen(true)
    }

    function closeRejectModal() {
        setRejectModalOpen(false)
    }

    function openRejectModal() {
        setRejectModalOpen(true)
    }


    useEffect(() => {
        const fetchSelectedData = async () => {
            try {
                const divisionId = formData?.division;
                const districtId = formData?.district;
                const subDistrictId = formData?.subDistrict;

                const selectedDivision = divitions[divisionId];
                setSelectedDivision(selectedDivision?.bn_name);

                const selectedDistrict = districts[districtId]
                setSelectedDistrict(selectedDistrict?.bn_name);

                const selectedSubDistrict = subDistricts[subDistrictId]
                setSelectedSubDistrict(selectedSubDistrict?.bn_name);

            } catch (error) {
                console.log(error);
            }
        };

        fetchSelectedData();
    }, [formData?.district, formData?.subDistrict, formData?.division]);


    const createdAtDate = new Date(formData?.createdAt);

    // Extract day, month, and year from the createdAtDate
    const day = createdAtDate.getDate();
    const month = createdAtDate.getMonth() + 1; // Months are zero-based, so adding 1
    const year = createdAtDate.getFullYear();

    // Format the date as "day/month/year"
    const formattedCreatedAt = `${day}/${month}/${year}`;

    const createdAtTime = new Date(formData?.createdAt);

    // Format the date and time in Bangladesh time zone
    const formattedCreatedAtTime = createdAtTime.toLocaleTimeString("en-BD", {
        timeZone: "Asia/Dhaka"
    });

    const location = useLocation();
    const isTrashRoute = location.pathname === '/asf-admin/trash';
    const isRejectedRoute = location.pathname === '/asf-admin/rejected';
    const isAcceptedRoute = location.pathname === '/asf-admin/accepted';
    const isNextRoute = location.pathname === '/asf-admin/next';

    return (
        <div>
            <Transition appear show={isOpen} as={Fragment}>

                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full shadow-3xl max-w-3xl transform overflow-hidden  text-left bg-white align-middle transition-all rounded-md flex items-center justify-center flex-col relative  dashboard" >

                                    <div className='flex items-center justify-end w-full'>
                                        <IoCloseOutline
                                            className='hover:text-red-600 text-2xl 
                                        text-right transition-all ease-in duration-300 cursor-pointer text-black mt-5 mr-5'
                                            onClick={closeModal}
                                        />
                                    </div>

                                    <div ref={ref} className='dashboard w-full transform overflow-hidden rounded-md bg-white text-left align-middle transition-all px-5 py-5 lg:px-10 lg:py-7 relative'>
                                        <div className='flex items-center absolute left-11 top-0 gap-1'>
                                            <h3 className='text-xs'>{formattedCreatedAt},</h3>
                                            <h3 className='text-xs '>{formattedCreatedAtTime}</h3>
                                        </div>
                                        <div className="-mt-7 relative">
                                            <img
                                                src={assunnah}
                                                className='opacity-10 absolute'
                                                style={{ width: '60%', height: 'auto', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', }}
                                                alt=""
                                            />
                                            <div className=''>
                                                <div className='flex flex-col items-center'>
                                                    <img
                                                        src={logo}
                                                        className='lg:h-24 lg:w-24 h-20 w-20'
                                                        alt=""
                                                    />
                                                    <h3 className='text-lg font-[500]
                                                     mt-1'>দীনি হালাকাহর আবেদন</h3>
                                                </div>

                                                <div className='flex items-center gap-5 mt-3 justify-end'>
                                                    <h3 className='text-right text-md font-semibold'>আবেদন নং:  {formData?.applicationNumber}</h3>
                                                </div>

                                                <div>
                                                    <h3 className='text-md font-semibold'>প্রতিষ্ঠানের তথ্যঃ</h3>
                                                    <div className='border p-2 flex items-center justify-between'>
                                                        <h3 className='text-md'><span className='font-semibold pr-2 '>প্রতিষ্ঠানের ধরন:</span>
                                                            {
                                                                formData?.institutionType === '1' &&
                                                                'কেন্দ্রীয়/বড় মসজিদ'
                                                            }
                                                            {
                                                                formData?.institutionType === '2' &&
                                                                'বিশ্ববিদ্যালয়/কলেজ'
                                                            }
                                                            {
                                                                formData?.institutionType === '3' &&
                                                                'মিলনায়তন'
                                                            }
                                                            {
                                                                formData?.institutionType === '4' &&
                                                                'মাদরাসা'
                                                            }
                                                        </h3>
                                                    </div>
                                                    <div>
                                                        {
                                                            formData?.central_masjid_name &&
                                                            <div className='border p-2'>
                                                                <h3 className='text-md'><span className='font-semibold pr-2 '>প্রতিষ্ঠানের নাম:</span>
                                                                    {formData?.central_masjid_name}
                                                                </h3>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div>
                                                        {
                                                            formData?.institutionType === '2' &&
                                                            <div className='border p-2'>
                                                                <h3 className='text-md'><span className='font-semibold pr-2 '>প্রতিষ্ঠানের নাম:</span>
                                                                    {formData?.educational_institute_name}
                                                                </h3>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div>
                                                        {
                                                            formData?.institutionType === '3' &&
                                                            <div className='border p-2'>
                                                                <h3 className='text-md'><span className='font-semibold pr-2 '>প্রতিষ্ঠানের নাম:</span>
                                                                    {formData?.auditorium_name}
                                                                </h3>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div>
                                                        {
                                                            formData?.institutionType === '4' &&
                                                            <div className='border p-2'>
                                                                <h3 className='text-md'><span className='font-semibold pr-2 '>প্রতিষ্ঠানের নাম:</span>
                                                                    {formData?.madrashaw_name && formData?.madrashaw_name}
                                                                </h3>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div>
                                                        <div className='border p-2'>
                                                            <h3 className='text-md'><span className='font-semibold pr-2 '>হালাকাহ আয়োজনের উদ্দেশ্য:</span>
                                                                {formData?.goals_of_halaka}
                                                            </h3>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {
                                                            formData?.institutionType === '2' &&
                                                            <div className='border p-2'>
                                                                <h3 className='text-md'><span className='font-semibold pr-2 '>প্রোগ্রাম স্থল:</span>
                                                                    {formData?.programePlace}
                                                                </h3>
                                                            </div>
                                                        }
                                                    </div>

                                                    <div>
                                                        {
                                                            formData?.programePlace === 'অন্যান্য' &&
                                                            <div className='border p-2'>
                                                                <h3 className='text-md'><span className='font-semibold pr-2 '>প্রোগ্রাম স্থান সম্পর্কে বিস্তারিত:</span>
                                                                    {formData?.programme_place_description}
                                                                </h3>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div>
                                                        <div className='border p-2'>
                                                            <h3 className='text-md'><span className='font-semibold pr-2 '>প্রোগ্রাম স্থলের ধারণ-ক্ষমতা:</span>
                                                                {formData?.people_container_ability} জন
                                                            </h3>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {
                                                            formData?.institutionType === '3' &&
                                                            <div className='border p-2'>
                                                                <h3 className='text-md'><span className='font-semibold pr-2 '>মিলনায়তন ভাড়ার আয়ের উৎস: </span>
                                                                    {formData?.place_renting_source}
                                                                </h3>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div>
                                                        <div className='border p-2'>
                                                            <h3 className='text-md'><span className='font-semibold pr-2 '>অংশগ্রহণকারী: </span>
                                                                {formData?.participantType}
                                                            </h3>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {
                                                            formData?.participantType === participantType.both
                                                            &&
                                                            <div className='border p-2'>
                                                                <h3 className='text-md'><span className='font-semibold pr-2 '>নারীদের অংশগ্রহণের জন্য পর্দার সাথে স্বতন্ত্র ব্যবস্থা থাকবে কি?</span>
                                                                    {formData?.fimaleCondition}।
                                                                </h3>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div>
                                                        {
                                                            formData?.institutionType === '1' &&
                                                            <div className='border p-2'>
                                                                <h3 className='text-md'><span className='font-semibold pr-2 '>মসজিদের বৈশিষ্ট্য:</span>
                                                                    {formData?.masjid_quality && formData?.masjid_quality}
                                                                </h3>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>

                                                {/* second section of Preview */}

                                                <div className='mt-11'>
                                                    <h3 className='text-md font-semibold'>আবেদনকারীর তথ্যঃ</h3>
                                                    <div>
                                                        {
                                                            formData?.institutionType === '3' &&
                                                            <div className='border p-2'>
                                                                <h3 className='text-md'><span className='font-semibold pr-2 '>রেফারকারীর নাম: </span>
                                                                    {formData?.referance_name}
                                                                </h3>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div>
                                                        <div className='border p-2'>
                                                            <h3 className='text-md'><span className='font-semibold pr-2 '>আবেদনকারীর নাম: </span>
                                                                {formData?.applicante_name}
                                                            </h3>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='border p-2'>
                                                            <h3 className='text-md'><span className='font-semibold pr-2 '>আবেদনকারীর নম্বর: </span>
                                                                {formData?.applicante_mobile && formData?.applicante_mobile.slice(2)}
                                                            </h3>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {
                                                            formData?.designation && formData?.institutionType !== '3' &&
                                                            <div className='border p-2'>
                                                                <h3 className='text-md'><span className='font-semibold pr-2 '>আবেদনকারীর পদবী: </span>
                                                                    {formData?.designation}
                                                                </h3>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div>
                                                        {
                                                            formData?.designation && formData?.institutionType === '3' &&
                                                            <div className='border p-2'>
                                                                <h3 className='text-md'><span className='font-semibold pr-2 '>আবেদনকারীর পেশা: </span>
                                                                    {formData?.designation}
                                                                </h3>
                                                            </div>
                                                        }
                                                    </div>

                                                </div>

                                                {/* third section */}

                                                <div className='mt-11 pdf-section'>
                                                    <h3 className='text-md font-semibold'>হালাকাহর তথ্যঃ</h3>

                                                    <div className='flex items-center gap-11 md:flex-row flex-row border'>
                                                        <div className='p-2 border-l'>
                                                            <h3 className='text-md'><span className='font-semibold pr-2 '>বিভাগ: </span>
                                                                {division}
                                                            </h3>
                                                        </div>
                                                        <div className='p-2 border-l'>
                                                            <h3 className='text-md'><span className='font-semibold pr-2 '>জেলা: </span>
                                                                {district}
                                                            </h3>
                                                        </div>
                                                        {
                                                            formData?.cityCorporation
                                                                ?
                                                                <div className='border-l p-2'>
                                                                    <h3 className='text-md'><span className='font-semibold pr-2 '>সিটি কর্পোরেশন এলাকা : </span>
                                                                        {formData?.cityCorporation}
                                                                    </h3>
                                                                </div>
                                                                :
                                                                <div className='border-l p-2'>
                                                                    <h3 className='text-md'><span className='font-semibold pr-2 '>উপজেলা: </span>
                                                                        {subDistrict}
                                                                    </h3>
                                                                </div>
                                                        }
                                                    </div>

                                                    <div>
                                                        <div className='border p-2'>
                                                            <h3 className='text-md'><span className='font-semibold pr-2 '>ঠিকানা: </span>
                                                                {formData?.details_address}
                                                            </h3>
                                                        </div>
                                                        {
                                                            (type == applicationType.next || type == applicationType.completed) ||
                                                            <div className='border p-2'>
                                                                <h3 className='text-md'><span className='font-semibold pr-2 '>হালাকাহর প্রস্তাবিত তারিখ: </span>
                                                                    {
                                                                        formData?.dateFrom && formData?.dateTo ?
                                                                            <>
                                                                                {formatDateForViewers(formData?.dateFrom)} <span className='mx-2 font-bold'>হতে</span> {formatDateForViewers(formData?.dateTo)} <span className='mx-2 font-bold'>এর মধ্যে</span>
                                                                            </>
                                                                            :
                                                                            'নাই'
                                                                    }
                                                                </h3>
                                                            </div>
                                                        }
                                                        {
                                                            type == applicationType.next ? <div className='border p-2'>
                                                                <h3 className='text-md'><span className='font-semibold pr-2 '>চূড়ান্ত তারিখ: </span>
                                                                    {formatDateForViewers(formData?.selected_date)}
                                                                </h3>
                                                            </div>
                                                                : type == applicationType.completed && <div className='border p-2'>
                                                                    <h3 className='text-md'><span className='font-semibold pr-2 '>হালাকাহ সম্পন্নের তারিখ: </span>
                                                                        {formatDateForViewers(formData?.selected_date)}
                                                                    </h3>
                                                                </div>
                                                        }

                                                        {
                                                            type == applicationType.completed ||
                                                            <div className='border p-2'>
                                                                <h3 className='text-md'><span className='font-semibold pr-2 '>তারিখ নির্বাচনের ক্ষেত্রে বিশেষ কারণ: </span>
                                                                    {formData?.date_selection_reason}
                                                                </h3>
                                                            </div>
                                                        }
                                                        {
                                                            formData?.note &&
                                                            <div className='border p-2'>
                                                                <h3 className='text-md'><span className='font-semibold pr-2 '>নোট: </span>
                                                                    {formData?.note}
                                                                </h3>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="mt-4 flex items-center justify-end gap-3 mx-16 my-7 dashboard">
                                        {
                                            isTrashRoute ?

                                                <ReactToPdf targetRef={ref} options={options} x={.5} y={.5} filename="asf-applycation.pdf">
                                                    {({ toPdf }) => (
                                                        <button onClick={toPdf}
                                                            className='flex items-center w-full px-4 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-300 transform bg-lime-600 rounded-md sm:w-auto sm:mt-0 hover:bg-lime-700 '
                                                        >
                                                            <img
                                                                src={DownloadIcon}
                                                                className='h-4 w-4'
                                                                alt=''
                                                            />
                                                            <span className="mx-2 text-sm ">ডাউনলোড</span>
                                                        </button>
                                                    )}
                                                </ReactToPdf>
                                                :
                                                <>

                                                    <div className='flex items-center gap-3'>
                                                        {location.pathname !== '/asf-admin/completed' && <>
                                                            <button
                                                                disabled={actionLoading ? true : false}
                                                                onClick={openRejectModal}
                                                                className={`flex items-center w-full px-4 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-300 transform bg-red-600 rounded-md sm:w-auto sm:mt-0 hover:bg-red-700  ${isRejectedRoute && 'hidden'}`}>
                                                                <img
                                                                    src={RejectIcon}
                                                                    className='h-4 w-4'
                                                                    alt=''
                                                                />
                                                                <span className="mx-2 text-sm ">বাতিল</span>
                                                            </button>
                                                            {
                                                                (isNextRoute || isAcceptedRoute) ||
                                                                <button
                                                                    disabled={actionLoading ? true : false}
                                                                    onClick={openAcceptModal}
                                                                    className={`flex items-center w-full px-4 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-300 transform bg-green-600 rounded-md sm:w-auto sm:mt-0 hover:bg-green-700`}>
                                                                    <img
                                                                        src={AcceptIcon}
                                                                        className='h-4 w-4'
                                                                        alt=''
                                                                    />
                                                                    <span className="mx-2 text-sm ">গ্রহণ</span>
                                                                </button>
                                                            }
                                                        </>}
                                                        <ReactToPdf targetRef={ref} options={options} x={.5} y={.5} filename={`halaqah_${formData?.applicationNumber ? formData?.applicationNumber : ''}.pdf`}>
                                                            {({ toPdf }) => (
                                                                <button onClick={toPdf}
                                                                    className='flex items-center w-full px-4 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-300 transform bg-lime-600 rounded-md sm:w-auto sm:mt-0 hover:bg-lime-700 '
                                                                >
                                                                    <img
                                                                        src={DownloadIcon}
                                                                        className='h-4 w-4'
                                                                        alt=''
                                                                    />
                                                                    <span className="mx-2 text-sm ">ডাউনলোড</span>
                                                                </button>
                                                            )}
                                                        </ReactToPdf>
                                                    </div></>
                                        }
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>

            {
                <AcceptModal
                    acceptModalOpen={acceptModalOpen}
                    type={type}
                    id={formData?._id}
                    closeAcceptModal={closeAcceptModal}
                    applicationNum={formData?.applicationNumber}
                    closeModal={closeModal}
                />
            }

            {
                <RejectModal
                    rejectModalOpen={rejectModalOpen}
                    type={type}
                    id={formData?._id}
                    applicationNum={formData?.applicationNumber}
                    closeRejectModal={closeRejectModal}
                    closeModal={closeModal}
                />
            }

        </div>
    );
};

export default AdminViewModal;