import Lottie from 'react-lottie';
import animationData from './loader-3dots.json';

const LoadingAnimation = ({ width, height, color }) => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
            colorFilters: [
                {
                    keypath: 'Ellipse 1.Fill 1',
                    color: color,
                },
                {
                    keypath: 'Shape Layer 2.Stroke 1',
                    color: color,
                },
            ],
        },
    };

    return <div style={{ width: width, height: height, color: color }}>
        <Lottie options={defaultOptions} />
    </div>;
};

export default LoadingAnimation