import React, { useEffect, useState } from 'react';
import './Appoinment.css';
import { useForm } from 'react-hook-form';
import PreviewModal from '../../../components/PreviewModal';
import { toast } from 'react-hot-toast';
import ApplyRules from '../../../components/ApplyRules';
import axios from 'axios';
import SubmitModal from '../../../components/SubmitModal'
import ClickToScrollTop from '../../../components/ClickToScrollTop';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { bn } from 'date-fns/locale';
import divisions from '../../../json/divisions';
import districts from '../../../json/districts';
import subDistricts from '../../../json/subDistricts';
import cityCorporations from '../../../json/cityCorporations';
import 'react-day-picker/dist/style.css';
import { formatDate } from '../../../utils/helpers';
import moment from 'moment';
import { femaleCondition, participantType as participant } from '../../../utils/constants';
import { formatISO, addDays } from 'date-fns';

registerLocale('bn', bn);

const TwoAppoinment = () => {

    const [agreeTerms, setAgreeTerms] = useState(false);
    const [districtData, setDistrictData] = useState([]);
    const [subDistrictData, setSubDistrictData] = useState([]);
    const [cityCorporationData, setCityCorporationData] = useState([]);
    const [applicationNumber, setApplicationNumber] = useState(null)

    const { register, handleSubmit, watch, getValues, clearErrors, formState: { errors, isValid }, setValue, reset } = useForm();

    console.log('watchData:', watch())

    const institutionType = watch("institutionType")
    const participantType = watch("participantType")
    const programePlace = watch("programePlace")
    const applicante_mobile = watch("applicante_mobile")
    const goals_of_halaka_Value = watch('goals_of_halaka') || '';
    const madrasa_speciality_Value = watch('madrasa_speciality') || '';
    const date_selection_reason_value = watch('date_selection_reason') || '';
    const masjid_quality_value = watch('masjid_quality') || '';
    let [isOpen, setIsOpen] = useState(false)
    let [open, setOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [loader, setLoader] = useState(false);
    const [formData, setFormData] = useState(null);
    const [editBtn, setEditBtn] = useState(false)

    const startDate = formatISO(addDays(new Date(), 1), { representation: 'date' })

    // modal
    function closeModal() {
        setEditBtn(false)
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    function offModal() {
        setOpen(false)
        reset()
    }

    function onModal() {
        setOpen(true)
    }

    // district and subdistrict fetching
    useEffect(() => {
        if (watch('division')) {
            fetchDistrictData(watch('division'));
        } else {
            setDistrictData([]);
            setSubDistrictData([]);
            setCityCorporationData([]);
            setValue('district', '');
            setValue('subDistrict', '');
        }
    }, [watch('division')]);

    useEffect(() => {
        if (watch('district')) {
            fetchSubDistrictData(watch('district'));
            checkCityCorporationData(watch('district'));
        } else {
            setSubDistrictData([]);
            setCityCorporationData([]);
            setValue('subDistrict', '');
        }
    }, [watch('district')]);

    useEffect(() => {
        if (watch('subDistrict') !== 'city-corporation') {
            setValue('cityCorporation', undefined);
        }
    }, [watch('subDistrict')])

    const fetchDistrictData = async (selectedDivisionId) => {
        try {
            const filteredDistricts = Object.values(districts).filter(
                (district) => district.division_id === selectedDivisionId
            );
            setDistrictData(filteredDistricts);
            setValue('district', '');
            setValue('subDistrict', '');
        } catch (error) {
            console.log(error);
        }
    };

    const fetchSubDistrictData = async (selectedDistrictId) => {
        try {
            const filteredSubDistricts = Object.values(subDistricts).filter(
                (subDistrict) => subDistrict.district_id === selectedDistrictId
            );
            setSubDistrictData(filteredSubDistricts);
            setValue('subDistrict', '');
        } catch (error) {
            console.log(error);
        }
    };

    const checkCityCorporationData = async (selectedDistrictId) => {
        try {
            setCityCorporationData(cityCorporations[selectedDistrictId] || []);
        } catch (error) {
            console.log(error);
        }
    };

    // aggree terms and conditions
    const handleTermsCondition = () => {
        setAgreeTerms(prev => !prev);
    };

    // for date selection
    const handleDateFromChange = (e) => {
        setValue('dateTo', '', { shouldValidate: true });
        clearErrors('dateFrom');
    };

    const onSubmit = data => {
        if (!isValid) {
            toast.error('আবশ্যক তথ্যগুলি পূরণ করুন');
            return;
        }

        setLoader(true)
        const applicante_mobile = data.applicante_mobile;
        const applicante_name = data.applicante_name;
        const details_address = data.details_address;
        const goals_of_halaka = data.goals_of_halaka;
        const manage_cost_source = data.manage_cost_source;
        const masjid_quality = data.masjid_quality;
        const people_container_ability = data.people_container_ability;
        const volunteer_number = data.volunteer_number;
        const refarence = data.refarence;
        const referance_name = data.referance_name;
        const madrashaw_name = data.madrashaw_name;
        const present_students_number = data.present_students_number;
        const institutionType = data.institutionType;
        const participantType = data.participantType
        const district = data.district
        const subDistrict = data.subDistrict
        const programePlace = data.programePlace;
        const designation = data.designation;
        const fimaleCondition = data.fimaleCondition
        const programme_place_description = data.programme_place_description;
        const cityCorporation = data.cityCorporation;
        const division = data.division;
        const central_masjid_name = data.central_masjid_name;
        const educational_institute_name = data.educational_institute_name;
        const auditorium_name = data.auditorium_name;
        const place_renting_source = data.place_renting_source;
        // new for database
        const date_selection_reason = data.date_selection_reason;
        const madrasa_speciality = data.madrasa_speciality;
        const dateFrom = data.dateFrom;
        const dateTo = data.dateTo;

        const allData = {
            institutionType,
            programePlace,
            participantType,
            fimaleCondition,
            designation,
            district,
            subDistrict,
            applicante_mobile: "88" + applicante_mobile,
            applicante_name,
            details_address,
            goals_of_halaka,
            manage_cost_source,
            masjid_quality,
            people_container_ability,
            programme_place_description,
            volunteer_number,
            refarence,
            madrashaw_name,
            present_students_number,
            cityCorporation,
            division,
            auditorium_name,
            educational_institute_name,
            central_masjid_name,
            referance_name,
            place_renting_source,
            date_selection_reason,
            madrasa_speciality,
            dateFrom,
            dateTo,
        }

        if (data.subDistrict !== 'city-corporation') delete data.cityCorporation

        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/program/apply`, allData)
            .then(res => {
                toast.success("আবেদনটি সফল ভাবে সম্পন্ন হয়েছে")
                setLoader(false)
                if (res?.data?.status === 'success') {
                    setApplicationNumber(res?.data?.applicationNumber)
                    setFormData(allData)
                    onModal()
                    reset()
                }
            })
            .catch(err => {
                console.log(err)
                if (err?.response?.data?.error?.keyPattern) {
                    console.log('erris: ', err?.response?.data?.error?.keyPattern['applicante_mobile'])
                    toast.error("এই মোবাইল নম্বরটি পূর্বে ব্যবহার করা হয়েছে।")
                } else {
                    toast.error('Something went wrong')
                }
                setLoader(false)
            })


    }


    const handlePreview = () => {
        if (!isValid) {
            toast.error('আবশ্যক তথ্যগুলি সঠিক ভাবে পূরণ করুন');
            return;
        }

        setIsLoading(true);
        const allData = getValues();
        setFormData(allData)

        setTimeout(() => {
            openModal()
            setIsLoading(false);
        }, 500);

    };


    const handleDownload = () => {
        setTimeout(() => {
            reset();
            offModal()
        }, 500);
    };

    const handleInstitutionType = e => {
        const selectedValue = e.target.value;

        // Get the current form values
        const currentValues = { institutionType: selectedValue, division: '', participantType: '', dateFrom: '', dateTo: '', people_container_ability: '' };

        // Reset the form with the new values
        clearErrors("people_container_ability")
        reset(currentValues);
    }

    return (
        <div className='back_bg w-full'>
            <ClickToScrollTop />
            <div className=' h-20 md:h-40 lg:h-56 overflow-hidden relative w-full page-header '>
                <div className='absolute top-0 bottom-0 left-0 right-0 heading_image_bg flex items-center justify-center'>
                    <h3 className='text-2xl md:text-[36px]  text-white'>দীনি হালাকাহর আবেদন ফরম </h3>
                </div>
            </div>

            {/* For form submit using here React hook form library */}
            <div className=' max-w-4xl mx-auto px-3 py-2 mx-auto px-5 md:px-32 2xl:px-5 py-7 md:py-16'>
                <div className=''>
                    <div className=''>
                        <div className='flex flex-col items-left md:items-center justify-left md:justify-just 2xl:px-11'>
                            <h3 className='text-center text-xl md:text-xl  lg:text-[23px] '>যে ব্যক্তি মানুষকে আল্লাহর পথে দাওয়াত দেয়, সৎকর্ম করে আর বলে, ‘আমি তো মুসলমানদের একজন’, তার চেয়ে উত্তম আর কে হতে পারে?</h3>
                            <p className='tex text-center  text-xl md:text-xl md:leading-loose lg:text-xl pb-2'>(সূরা হা-মিম-সাজদা: ৩৩)।</p>
                            <h3 className='text-center  text-xl md:text-xl md:leading-loose lg:text-xl font-semibold'>মানুষকে আল্লাহর পথে আহ্বান এবং একমাত্র তাঁর সন্তুষ্টি অর্জনের উদ্দেশ্যে যদি কেউ দীনি হালাকাহর আয়োজন করতে চান, তাহলে ফরমটি পূরণ করুন।</h3>
                        </div>
                        <form className='py-11'
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <div>

                                {/* select first prothistaner dhoron */}
                                <div>
                                    <label htmlFor="username" className="block text-lg  text-color">যে ধরনের প্রতিষ্ঠানে হালাকাহ আয়োজন করতে চান <span className='text-red-500'>*</span></label>

                                    <div className='relative w-full'>
                                        <select
                                            placeholder='প্রতিষ্ঠানের নাম লিখুন'
                                            type="text" className={`block bg-white mt-2 w-full appearance-none placeholder-gray-400/70 rounded-md border border-lime-500 px-5 py-3.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ${errors.institutionType && ' border-red-500 ring ring-red-200 focus:ring-red-300  focus:border-red-500'}`}
                                            {...register('institutionType', { required: 'এই তথ্যটি পূরণ করা আবশ্যক' })}
                                            onChange={handleInstitutionType}
                                        >
                                            <option value="">প্রতিষ্ঠানের ধরন</option>
                                            <option value="1">কেন্দ্রীয়/বড় মসজিদ</option>
                                            <option value="2">বিশ্ববিদ্যালয়/কলেজ/মেডিকেল কলেজ</option>
                                            <option value="3">মিলনায়তন</option>
                                            <option value="4">মাদরাসা</option>
                                        </select>
                                        <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                                            <svg className="fill-current h-4 w-4" viewBox="0 0 20 20">
                                                <path d="M10 12l-6-6 1.41-1.41L10 9.17l4.59-4.58L16 6l-6 6z" />
                                            </svg>
                                        </div>

                                    </div>
                                    {
                                        errors.institutionType &&
                                        <span
                                            className='text-sm text-red-500 pt-2'
                                        >{errors.institutionType.message}</span>
                                    }

                                    {
                                        <>
                                            {
                                                institutionType === '1' &&
                                                <span className='flex items-center gap-2'>
                                                    <span className='text-sky-500 text-sm pt-1'>মসজিদের সর্বনিম্ন ধারণ-ক্ষমতা হতে হবে ১৫০০ জন</span>

                                                    <span className="relative flex h-2 w-2 items-center justify-center">
                                                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-200 opacity-75"></span>
                                                        <span className="relative inline-flex rounded-full h-1.5 w-1.5 bg-sky-500"></span>
                                                    </span>
                                                </span>
                                            }
                                            {
                                                institutionType === '3' &&
                                                <span className='flex items-center gap-2'>
                                                    <span className='text-sky-500 text-sm pt-1'>ধারণ-ক্ষমতা সর্বনিম্ন ৫০০ জন</span>

                                                    <span className="relative flex h-2 w-2 items-center justify-center">
                                                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-200 opacity-75"></span>
                                                        <span className="relative inline-flex rounded-full h-1.5 w-1.5 bg-sky-500"></span>
                                                    </span>
                                                </span>
                                            }
                                        </>
                                    }
                                </div>

                                <div>
                                    <div>
                                        {
                                            institutionType === '1' &&
                                            <div className='mt-6'>
                                                <div className='w-full h-24'>
                                                    <label htmlFor="username" className="block text-lg  color">কেন্দ্রীয়/বড় মসজিদের নাম<span className='text-red-500'>*</span></label>
                                                    <input placeholder='কেন্দ্রীয়/বড় মসজিদের নাম লিখুন' maxLength={150} value={watch('central_masjid_name')} type="text" className={`block bg-white mt-2 w-full  rounded-md border border-lime-500 px-5 py-3.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ${errors.central_masjid_name && 'border-red-500 ring ring-red-200 focus:ring-red-300  focus:border-red-500'}`}
                                                        {...register("central_masjid_name", { required: "এই তথ্যটি পূরণ করা আবশ্যক" })}
                                                    />
                                                    {errors.central_masjid_name &&
                                                        <span
                                                            className='text-sm text-red-500 pt-2'
                                                        >{errors.central_masjid_name.message}</span>}
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div>
                                        {
                                            institutionType === '2' &&
                                            <div className='mt-6'>
                                                <div className='w-full h-24'>
                                                    <label htmlFor="username" className="block text-lg  color">বিশ্ববিদ্যালয়/কলেজ/মেডিকেল কলেজের নাম<span className='text-red-500'>*</span></label>
                                                    <input placeholder='বিশ্ববিদ্যালয়/কলেজ/মেডিকেল কলেজের নাম লিখুন' maxLength={150} value={watch('educational_institute_name')} type="text" className={`block bg-white mt-2 w-full  rounded-md border border-lime-500 px-5 py-3.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ${errors.educational_institute_name && 'border-red-500 ring ring-red-200 focus:ring-red-300  focus:border-red-500'}`}
                                                        {...register("educational_institute_name", { required: "এই তথ্যটি পূরণ করা আবশ্যক" })}
                                                    />
                                                    {errors.educational_institute_name &&
                                                        <span
                                                            className='text-sm text-red-500 pt-2'
                                                        >{errors.educational_institute_name.message}</span>}
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div>
                                        {
                                            institutionType === '3' &&
                                            <div className='mt-6'>
                                                <div className='w-full h-24'>
                                                    <label htmlFor="username" className="block text-lg  color">মিলনায়তনের নাম<span className='text-red-500'>*</span></label>
                                                    <input placeholder='মিলনায়তনের নাম লিখুন' type="text" maxLength={150} value={watch('auditorium_name')} className={`block bg-white mt-2 w-full  rounded-md border border-lime-500 px-5 py-3.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ${errors.auditorium_name && 'border-red-500 ring ring-red-200 focus:ring-red-300  focus:border-red-500'}`}
                                                        {...register("auditorium_name", { required: "এই তথ্যটি পূরণ করা আবশ্যক" })}
                                                    />
                                                    {errors.auditorium_name &&
                                                        <span
                                                            className='text-sm text-red-500 pt-2'
                                                        >{errors.auditorium_name.message}</span>}
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div>
                                        {
                                            institutionType === '4' &&
                                            <div className='mt-6'>
                                                <div>
                                                    <div className='w-full'>
                                                        <label htmlFor="username" className="block text-lg  color">মাদরাসার নাম<span className='text-red-500'>*</span></label>
                                                        <input placeholder='মাদরাসার নাম লিখুন' type="text" maxLength={150} value={watch('madrashaw_name')} className={`block bg-white mt-2 w-full  rounded-md border border-lime-500 px-5 py-3.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ${errors.madrashaw_name && 'border-red-500 ring ring-red-200 focus:ring-red-300  focus:border-red-500'}`}
                                                            {...register("madrashaw_name", { required: "এই তথ্যটি পূরণ করা আবশ্যক" })}
                                                        />
                                                        {errors.madrashaw_name &&
                                                            <span
                                                                className='text-sm text-red-500 pt-2'
                                                            >{errors.madrashaw_name.message}</span>}
                                                    </div>

                                                    <div className='w-full h-24'>
                                                        <label htmlFor="username" className="block text-lg  color">বর্তমান ছাত্র সংখ্যা<span className='text-red-500'>*</span></label>
                                                        <input placeholder='বর্তমান ছাত্র সংখ্যা লিখুন' type="number" value={watch('present_students_number') > 0 ? watch('present_students_number') : watch('present_students_number') < 0 ? 0 : null} className={`block bg-white mt-2 w-full rounded-md border border-lime-500 px-5 py-3.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ${errors.present_students_number && 'border-red-500 ring ring-red-200 focus:ring-red-300  focus:border-red-500'}`}
                                                            {...register('present_students_number', {
                                                                required: 'এই তথ্যটি পূরণ করা আবশ্যক',
                                                                pattern: {
                                                                    value: /^[0-9]+$/,
                                                                    message: 'ইংরেজি সংখ্যায় লিখুন'
                                                                },
                                                                min: {
                                                                    value: 1,
                                                                    message: `সর্বনিম্ন 1 প্রদান করুন`
                                                                }
                                                            })}
                                                        />
                                                        {errors.present_students_number &&
                                                            <span
                                                                className='text-sm text-red-500 pt-2'
                                                            >{errors.present_students_number.message}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div>
                                    <div className=' mt-6'>

                                        <div className='flex items-center flex-col lg:flex-row gap-6 lg:gap-7  w-full'>
                                            <div className='w-full h-24'>
                                                <label htmlFor="username" className="block text-lg  color">বিভাগ<span className='text-red-500'>*</span></label>
                                                <div className='relative w-full'>
                                                    <select id="division"
                                                        value={watch('division')}
                                                        className={`block bg-white mt-2 w-full appearance-none placeholder-gray-400/70  rounded-md border border-lime-500 px-5 py-3.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ${errors.district && 'border-red-500 ring ring-red-200 focus:ring-red-300 focus:border-red-500'}`}
                                                        {...register('division', { required: 'এই তথ্যটি পূরণ করা আবশ্যক' })}

                                                    >
                                                        < option value="">বিভাগ নির্বাচন করুন</option>
                                                        {Object.values(divisions).map((division) => (
                                                            <option key={division.id} value={division.id}>
                                                                {division.bn_name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                                                        <svg className="fill-current h-4 w-4" viewBox="0 0 20 20">
                                                            <path d="M10 12l-6-6 1.41-1.41L10 9.17l4.59-4.58L16 6l-6 6z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                                {errors.district &&
                                                    <span
                                                        className='text-sm text-red-500 pt-2'
                                                    >{errors.district.message}</span>}
                                            </div>
                                            <div className='w-full h-24'>
                                                <label htmlFor="username" className="block text-lg  color">জেলা<span className='text-red-500'>*</span></label>
                                                <div className='relative w-full'>
                                                    <select id="district"
                                                        value={watch('district')}
                                                        className={`block bg-white mt-2 w-full appearance-none placeholder-gray-400/70  rounded-md border border-lime-500 px-5 py-3.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ${!watch('division') && 'cursor-not-allowed'}  ${errors.district && 'border-red-500 ring ring-red-200 focus:ring-red-300 focus:border-red-500'}`}
                                                        {...register('district', { required: 'এই তথ্যটি পূরণ করা আবশ্যক' })}
                                                        disabled={!watch('division')}
                                                    >
                                                        <option value="">জেলা নির্বাচন করুন</option>
                                                        {districtData.map((district) => (
                                                            <option key={district.id} value={district.id}>
                                                                {district.bn_name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                                                        <svg className="fill-current h-4 w-4" viewBox="0 0 20 20">
                                                            <path d="M10 12l-6-6 1.41-1.41L10 9.17l4.59-4.58L16 6l-6 6z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                                {errors.district &&
                                                    <span
                                                        className='text-sm text-red-500 pt-2'
                                                    >{errors.district.message}</span>}
                                            </div>
                                            <div className='w-full h-24 '>
                                                <label htmlFor="username" className="block text-lg  color">উপজেলা<span className='text-red-500'>*</span></label>
                                                <div className='relative w-full'>
                                                    <select id="subDistrict"
                                                        value={watch('subDistrict')}
                                                        className={`block bg-white mt-2 w-full appearance-none placeholder-gray-400/70  rounded-md border border-lime-500 px-5 py-3.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ${!watch('district') && 'cursor-not-allowed'} ${errors.subDistrict && 'border-red-500 ring ring-red-200 focus:ring-red-300 focus:border-red-500'}`}

                                                        {...register('subDistrict', { required: "এই তথ্যটি পূরণ করা আবশ্যক" })}
                                                        disabled={!watch('district')}
                                                    >
                                                        <option value="">উপজেলা নির্বাচন করুন</option>
                                                        {cityCorporationData?.length > 0 && (
                                                            <option value="city-corporation">সিটি কর্পোরেশন</option>
                                                        )}
                                                        {subDistrictData.map((subDistrict) => (
                                                            <option key={subDistrict.id} value={subDistrict.id}>
                                                                {subDistrict.bn_name}
                                                            </option>
                                                        ))}

                                                    </select>
                                                    <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                                                        <svg className="fill-current h-4 w-4" viewBox="0 0 20 20">
                                                            <path d="M10 12l-6-6 1.41-1.41L10 9.17l4.59-4.58L16 6l-6 6z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                                {errors.subDistrict &&
                                                    <span
                                                        className='text-sm text-red-500 pt-2'
                                                    >{errors.subDistrict.message}</span>}
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        watch('subDistrict') === 'city-corporation' &&
                                        <div className='w-full h-24 mt-6'>
                                            <label htmlFor="username" className="block text-lg  color">সিটি কর্পোরেশন এলাকা<span className='text-red-500'>*</span></label>
                                            <div className='relative w-full'>
                                                <select id="district"
                                                    value={watch('cityCorporation')}
                                                    className={`block bg-white mt-2 w-full appearance-none placeholder-gray-400/70  rounded-md border border-lime-500 px-5 py-3.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40  ${errors.cityCorporation && 'border-red-500 ring ring-red-200 focus:ring-red-300 focus:border-red-500'}`}
                                                    {...register('cityCorporation', { required: 'এই তথ্যটি পূরণ করা আবশ্যক' })}
                                                >
                                                    <option value="">নির্বাচন করুন</option>
                                                    {cityCorporationData.map((itme) => (
                                                        <option key={itme} value={itme}>
                                                            {itme}
                                                        </option>
                                                    ))}
                                                </select>
                                                <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                                                    <svg className="fill-current h-4 w-4" viewBox="0 0 20 20">
                                                        <path d="M10 12l-6-6 1.41-1.41L10 9.17l4.59-4.58L16 6l-6 6z" />
                                                    </svg>
                                                </div>
                                            </div>
                                            {errors.cityCorporation &&
                                                <span
                                                    className='text-sm text-red-500 pt-2'
                                                >{errors.cityCorporation.message}</span>}

                                        </div>
                                    }

                                    <div className='w-full mt-6'>
                                        <label htmlFor="Description" className="block text-lg  text-color">হালাকাহর স্থানের বিস্তারিত ঠিকানা<span className='text-red-500'>*</span></label>

                                        <textarea
                                            value={watch('details_address') || ''}
                                            placeholder='বিস্তারিত ঠিকানা লিখুন'
                                            className={`block resize-none  mt-2 w-full  placeholder-text-xl  rounded-md border border-lime-500 px-5 py-3.5 bg-white h-24 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ${errors.details_address && ' border-red-500 ring ring-red-200 focus:ring-red-300  focus:border-red-500'}`}

                                            {...register('details_address', {
                                                required: 'এই তথ্যটি পুরণ করা আবশ্যক',
                                                maxLength: { value: 200, message: 'আপনি সর্বাধিক ২০০ টি অক্ষর লিখতে পারেন' },
                                            })}

                                            onInput={(event) => {
                                                const value = event.target.value;
                                                if (value.length > 200) {
                                                    event.target.value = value.slice(0, 200);
                                                }
                                            }}
                                        ></textarea>
                                        {errors?.details_address && <span className="text-red-500 text-sm">{errors?.details_address?.message}</span>}
                                    </div>
                                </div>


                                {
                                    institutionType === '4' &&
                                    <div className='mt-6 relative h-44 lg:h-40'>
                                        <label htmlFor="username" className="block text-lg  color">মাদরাসার বৈশিষ্ট্য<span className='text-red-500'>*</span></label>
                                        <textarea
                                            value={watch('madrasa_speciality') || ''}
                                            placeholder='মাদরাসার বৈশিষ্ট্য লিখুন'
                                            className={`block resize-none  mt-2 w-full  placeholder-text-xl  rounded-md border border-lime-500 px-5 py-3.5 bg-white h-32 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ${errors.madrasa_speciality && ' border-red-500 ring ring-red-200 focus:ring-red-300  focus:border-red-500'}`}

                                            {...register('madrasa_speciality', {
                                                required: 'এই তথ্যটি পুরণ করা আবশ্যক',
                                                maxLength: { value: 250, message: 'আপনি সর্বাধিক ৫০০ টি অক্ষর লিখতে পারেন' },
                                            })}

                                            onInput={(event) => {
                                                const value = event.target.value;
                                                if (value.length > 250) {
                                                    event.target.value = value.slice(0, 250);
                                                }
                                            }}
                                        ></textarea>
                                        {errors?.madrasa_speciality && <span className="text-red-500 text-sm">{errors?.madrasa_speciality?.message}</span>}

                                        <span className='text-sm text-gray-500 absolute -bottom-7 right-0'>
                                            {madrasa_speciality_Value.length}/{250}
                                        </span>
                                    </div>
                                }


                                <div className='mt-6 relative h-44 lg:h-40'>
                                    <label htmlFor="Description" className="block text-lg  text-color">আপনার প্রতিষ্ঠানে হালাকাহ আয়োজনের প্রয়োজনীয়তা লিখুন<span className='text-red-500'>*</span></label>

                                    <textarea
                                        value={watch('goals_of_halaka') || ''}
                                        placeholder='হালাকাহ আয়োজনের উদ্দেশ্য লিখুন'
                                        className={`block resize-none  mt-2 w-full  placeholder-text-xl  rounded-md border border-lime-500 px-5 py-3.5 bg-white h-32 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ${errors.goals_of_halaka && ' border-red-500 ring ring-red-200 focus:ring-red-300  focus:border-red-500'}`}

                                        {...register('goals_of_halaka', {
                                            required: 'এই তথ্যটি পুরণ করা আবশ্যক',
                                            maxLength: { value: 500, message: 'আপনি সর্বাধিক ৫০০ টি অক্ষর লিখতে পারেন' },
                                        })}

                                        onInput={(event) => {
                                            const value = event.target.value;
                                            if (value.length > 500) {
                                                event.target.value = value.slice(0, 500);
                                            }
                                        }}
                                    ></textarea>
                                    {errors?.goals_of_halaka && <span className="text-red-500 text-sm">{errors?.goals_of_halaka?.message}</span>}

                                    <span className='text-sm text-gray-500 absolute -bottom-7 right-0'>
                                        {goals_of_halaka_Value.length}/{500}
                                    </span>

                                </div>
                                <div className='flex items-center flex-col lg:flex-row gap-6 lg:gap-7 mt-12 w-full'>
                                    <div className='w-full h-24'>
                                        <label htmlFor="Description" className="block text-lg  text-color">প্রোগ্রাম স্থলের ধারণ-ক্ষমতা কত?<span className='text-red-500'>*</span></label>

                                        <input
                                            type="number"
                                            value={(watch('people_container_ability') > 0 && Number(watch('people_container_ability')) < 100001) ? watch('people_container_ability') : watch('people_container_ability') < 0 ? 0 : watch('people_container_ability') > 100000 ? 100000 : null}
                                            className={`appearance-none block bg-white mt-2 w-full rounded-md border border-lime-500 px-5 py-3.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ${errors.people_container_ability && 'border-red-500 ring ring-red-200 focus:ring-red-300  focus:border-red-500'}`}
                                            placeholder="প্রোগ্রাম স্থলের ধারণ-ক্ষমতা সংখ্যায় লিখুন"
                                            {...register('people_container_ability', {
                                                required: 'এই তথ্যটি পূরণ করা আবশ্যক',
                                                pattern: {
                                                    value: /^[0-9]+$/,
                                                    message: 'ইংরেজি সংখ্যায় লিখুন'
                                                },
                                                min: {
                                                    value: institutionType === '1' ? 1500 : institutionType === '3' ? 500 : 1,
                                                    message: `সর্বনিম্ন ${institutionType === '1' ? 1500 : institutionType === '3' ? 500 : 1} প্রদান করুন`
                                                },
                                                max: {
                                                    value: 100000,
                                                    message: `সর্বোচ্চ ${100000} প্রদান করুন`
                                                },
                                            })}
                                            onKeyPress={(e) => {
                                                const charCode = e.which ? e.which : e.keyCode;
                                                if (charCode !== 8 && charCode !== 0 && charCode < 48 || charCode > 57) {
                                                    e.preventDefault();
                                                }
                                            }}
                                        />

                                        {errors.people_container_ability &&
                                            <span
                                                className='text-sm text-red-500 pt-2'
                                            >{errors.people_container_ability.message}</span>}

                                        {
                                            <>
                                                {
                                                    institutionType === '1' && !errors?.people_container_ability?.message &&
                                                    watch("people_container_ability") < 1498 &&
                                                    <span className='flex items-center gap-2 mt-1'>
                                                        <span className='text-sky-500 text-sm'>সর্বনিম্ন ১৫০০ প্রদান করুন</span>

                                                        <span className="relative flex h-2 w-2 items-center justify-center">
                                                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-200 opacity-75"></span>
                                                            <span className="relative inline-flex rounded-full h-1.5 w-1.5 bg-sky-500"></span>
                                                        </span>
                                                    </span>
                                                }
                                                {
                                                    institutionType === '3' && !errors?.people_container_ability?.message &&
                                                    watch("people_container_ability") < 498 &&
                                                    <span className='flex items-center gap-2 mt-1'>
                                                        <span className='text-sky-500 text-sm'>সর্বনিম্ন ৫০০ প্রদান করুন</span>

                                                        <span className="relative flex h-2 w-2 items-center justify-center">
                                                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-200 opacity-75"></span>
                                                            <span className="relative inline-flex rounded-full h-1.5 w-1.5 bg-sky-500"></span>
                                                        </span>
                                                    </span>
                                                }
                                                {
                                                    !errors?.people_container_ability?.message &&
                                                    watch("people_container_ability") > 100000 &&
                                                    <span className='flex items-center gap-2 mt-1'>
                                                        <span className='text-sky-500 text-sm'>সর্বোচ্চ 100000 প্রদান করুন</span>

                                                        <span className="relative flex h-2 w-2 items-center justify-center">
                                                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-200 opacity-75"></span>
                                                            <span className="relative inline-flex rounded-full h-1.5 w-1.5 bg-sky-500"></span>
                                                        </span>
                                                    </span>
                                                }

                                            </>
                                        }
                                    </div>
                                </div>

                                {/* select first prothistaner dhoron */}
                                <div className='mt-6'>
                                    <label htmlFor="username" className="block text-lg  color">হালাকাহতে কারা অংশগ্রহণ করবেন?<span className='text-red-500'>*</span></label>

                                    <div className='relative w-full'>
                                        <select
                                            value={watch('participantType')}
                                            type="text" className={`block bg-white mt-2 w-full appearance-none placeholder-gray-400/70 rounded-md border border-lime-500 px-5 py-3.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ${errors.participantType && 'border-red-500 ring ring-red-200 focus:ring-red-300 focus:border-red-500'}`}

                                            {...register('participantType', { required: 'এই তথ্যটি পূরণ করা আবশ্যক' })}
                                        >
                                            <option className='text-gray-500' value="">অংশগ্রহণকারীদের ধরন লিখুন</option>
                                            <option value={participant.male}>{participant.male}</option>
                                            <option value={participant.female}>{participant.female}</option>
                                            <option value={participant.both}>{participant.both}</option>
                                        </select>
                                        <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                                            <svg className="fill-current h-4 w-4" viewBox="0 0 20 20">
                                                <path d="M10 12l-6-6 1.41-1.41L10 9.17l4.59-4.58L16 6l-6 6z" />
                                            </svg>
                                        </div>
                                    </div>
                                    {errors.participantType &&
                                        <span
                                            className='text-sm text-red-500 pt-2'
                                        >{errors.participantType.message}</span>}
                                </div>

                                {participantType === participant.both && (
                                    <div className="rounded mt-6 flex items-center gap-5">
                                        <h3 className={`text-lg color${errors.fimaleCondition && 're text-red-500 '}`}>
                                            <span className='text-red-500'>*</span>
                                            নারীদের অংশগ্রহণের জন্য পর্দার সাথে স্বতন্ত্র ব্যবস্থা থাকবে কি না?</h3>
                                        <div className='flex items-center gap-5 text-lg'>
                                            <label>
                                                <input
                                                    type="radio"
                                                    className="mr-2"
                                                    value={femaleCondition.yes}
                                                    {...register('fimaleCondition', { required: true })}
                                                />
                                                {femaleCondition.yes}
                                            </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    className="mr-2"
                                                    value={femaleCondition.no}
                                                    {...register('fimaleCondition', { required: true })}
                                                />
                                                {femaleCondition.no}
                                            </label>
                                        </div>
                                    </div>
                                )}


                                {/* based on user selection showing options */}
                                <div>
                                    <div>
                                        {
                                            institutionType === '1' &&
                                            <>
                                                <div className='mt-6 h-40 relative'>
                                                    <label htmlFor="Description" className="block text-lg  text-color">মসজিদের বিশেষত্ব<span className='text-red-500'>*</span></label>

                                                    <textarea
                                                        value={watch('masjid_quality')}
                                                        placeholder='মসজিদের বিশেষত্ব সম্পর্কে লিখুন'
                                                        className={`block mt-2 w-full  placeholder-text-xl rounded-md border border-lime-500 px-5 py-3.5 bg-white h-32 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ${errors.masjid_quality && 'border-red-500 ring ring-red-200 focus:ring-red-300  focus:border-red-500'}`}

                                                        {...register('masjid_quality', {
                                                            required: 'এই তথ্যটি পুরণ করা আবশ্যক',
                                                            maxLength: { value: 500, message: 'আপনি ৫০০ টি অক্ষরের সর্বাধিক লিখতে পারেন' },
                                                        })}
                                                        onInput={(event) => {
                                                            const value = event.target.value;
                                                            if (value.length > 500) {
                                                                event.target.value = value.slice(0, 500);
                                                            }
                                                        }}
                                                    ></textarea>
                                                    {errors.masjid_quality &&
                                                        <span
                                                            className='text-sm text-red-500 pt-2'
                                                        >{errors.masjid_quality.message}</span>}
                                                    <span className='text-sm text-gray-500 absolute -bottom-7 right-0'>
                                                        {masjid_quality_value.length}/{500}
                                                    </span>
                                                </div>
                                            </>
                                        }
                                    </div>

                                    <div>
                                        {
                                            institutionType === '2' ?
                                                <>
                                                    <div className='mt-6'>
                                                        <label htmlFor="username" className="block text-lg  color">প্রোগ্রাম স্থল<span className='text-red-500'>*</span></label>

                                                        <div className='relative w-full'>
                                                            <select
                                                                value={watch('programePlace')}
                                                                type="text" className={`block bg-white mt-2 w-full appearance-none placeholder-gray-400/70  rounded-md border border-lime-500 px-5 py-3.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 
                                                                ${errors.programePlace && 'border-red-500 ring ring-red-200 focus:ring-red-300  focus:border-red-500'}`}

                                                                {...register('programePlace', { required: 'এই তথ্যটি পূরণ করা আবশ্যক' })}
                                                            >
                                                                <option value="">প্রোগ্রাম স্থলের ধরন</option>
                                                                <option value="কেন্দ্রীয় মসজিদ">কেন্দ্রীয় মসজিদ</option>
                                                                <option value="অডিটোরিয়াম">অডিটোরিয়াম</option>
                                                                <option value="অন্যান্য">অন্যান্য</option>
                                                            </select>
                                                            <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                                                                <svg className="fill-current h-4 w-4" viewBox="0 0 20 20">
                                                                    <path d="M10 12l-6-6 1.41-1.41L10 9.17l4.59-4.58L16 6l-6 6z" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        {errors.programePlace &&
                                                            <span
                                                                className='text-sm text-red-500 pt-2'
                                                            >{errors.programePlace.message}</span>}
                                                    </div>
                                                </>
                                                :
                                                undefined
                                        }
                                    </div>

                                    <div>
                                        {
                                            programePlace === 'অন্যান্য' ?
                                                <>
                                                    <div className='mt-6'>
                                                        <label htmlFor="Description" className="block text-lg  text-color">প্রোগ্রাম স্থল সম্পর্কে বিস্তারিত<span className='text-red-500'>*</span></label>

                                                        <textarea
                                                            value={watch('programme_place_description')}
                                                            placeholder='প্রোগ্রাম স্থল সম্পর্কে বিস্তারিত লিখুন*'
                                                            className={`block bg-white mt-2 w-full rounded-md border border-lime-500 px-5 py-3.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 h-32 ${errors.programme_place_description && 'border-red-500 ring ring-red-200 focus:ring-red-300  focus:border-red-500'}`}
                                                            {...register('programme_place_description', { required: 'এই তথ্যটি পূরণ করা আবশ্যক' })}
                                                        ></textarea>
                                                        {errors.programme_place_description &&
                                                            <span
                                                                className='text-sm text-red-500 pt-2'
                                                            >{errors.programme_place_description.message}</span>}
                                                    </div>
                                                </>
                                                :
                                                undefined
                                        }
                                    </div>


                                </div>

                                {/* authority section  */}
                                <div className={`${institutionType === '1' ? 'mt-8' : 'mt-6'}`}>
                                    <div className=''>
                                        <h3 className='text-lg color'><span className='text-red-500'>*</span>হালাকাহর প্রস্তাবিত তারিখ (তারিখ চূড়ান্ত করার দায়িত্ব আস-সুন্নাহ ফাউন্ডেশনের)</h3>
                                        <div className='flex items-start items-center gap-4 flex-col md:flex-row w-full '>

                                            <div className='w-full md:w-44 lg:w-56 md:h-20 h-14'>
                                                <input
                                                    id='dateFrom'
                                                    type='date'
                                                    min={startDate}
                                                    onChange={handleDateFromChange}
                                                    className={`block bg-white mt-2 w-full rounded-md border border-lime-500 px-5 py-3.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ${errors.dateFrom && 'border-red-500 ring ring-red-200 focus:ring-red-300 focus:border-red-500'
                                                        } date-input`}
                                                    {...register('dateFrom', { required: 'এই তথ্যটি পূরণ করা আবশ্যক' })}
                                                />
                                                {errors.dateFrom && (
                                                    <span className='text-sm text-red-500 pt-2'>{errors.dateFrom.message}</span>
                                                )}
                                            </div>

                                            <label htmlFor='dateFrom' className='block text-lg color'>
                                                হতে<span className='text-red-500'></span>
                                            </label>

                                            <div className='w-full md:w-44 lg:w-56 md:h-20 h-14'>
                                                <input
                                                    id='dateTo'
                                                    placeholder='মোবাইল'
                                                    type='date'
                                                    min={formatDate(moment(new Date(watch('dateFrom'))).add(7, 'days'))}
                                                    disabled={!watch('dateFrom')}
                                                    className={`block bg-white mt-2 w-full appearance-none placeholder-gray-400/70  rounded-md border border-lime-500 px-5 py-3.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ${!watch('dateFrom') && 'cursor-not-allowed'}  ${errors.dateTo && 'border-red-500 ring ring-red-200 focus:ring-red-300 focus:border-red-500'}`}
                                                    {...register('dateTo', {
                                                        required: 'এই তথ্যটি পূরণ করা আবশ্যক',
                                                    })}
                                                />
                                                {errors.dateTo && (
                                                    <span className='text-sm text-red-500 pt-2'>{errors.dateTo.message}</span>
                                                )}
                                            </div>

                                            <label htmlFor='dateTo' className='block text-lg color'>
                                                এর মধ্যে
                                                <span className='text-red-500'></span>
                                            </label>
                                        </div>
                                    </div>


                                </div>

                                <div className='mt-6 relative'>
                                    <label htmlFor="username" className="block text-lg  color ">তারিখ নির্বাচনের ক্ষেত্রে বিশেষ কারণ থাকলে লিখুন<span className='text-red-500'>*</span></label>
                                    <div className='w-full'>
                                        <textarea
                                            value={watch('date_selection_reason') || ''}
                                            placeholder='তারিখ নির্বাচনের ক্ষেত্রে বিশেষ কারণ থাকলে লিখুন'
                                            className={`block resize-none  mt-2 w-full  placeholder-text-xl  rounded-md border border-lime-500 px-5 py-3.5 bg-white h-32 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ${errors.date_selection_reason && ' border-red-500 ring ring-red-200 focus:ring-red-300  focus:border-red-500'}`}

                                            {...register('date_selection_reason', {
                                                required: 'এই তথ্যটি পুরণ করা আবশ্যক',
                                                maxLength: { value: 200, message: 'আপনি সর্বাধিক ২০০ টি অক্ষর লিখতে পারেন' },
                                            })}

                                            onInput={(event) => {
                                                const value = event.target.value;
                                                if (value.length > 200) {
                                                    event.target.value = value.slice(0, 200);
                                                }
                                            }}
                                        ></textarea>
                                        {errors?.date_selection_reason && <span className="text-red-500 text-sm">{errors?.date_selection_reason?.message}</span>}

                                        <span className='text-sm text-gray-500 absolute -bottom-7 right-0'>
                                            {date_selection_reason_value.length}/{200}
                                        </span>
                                    </div>
                                </div>

                                <div>
                                    {
                                        institutionType === '3' &&
                                        <>
                                            <div className='mt-6'>
                                                <label htmlFor="username" className="block text-lg  color ">রেফারকারীর নাম<span className='text-red-500'>*</span></label>

                                                <div className='flex items-center gap-6 flex-col lg:flex-row w-full'>
                                                    <div className='w-full'>
                                                        <input placeholder='রেফারকারীর নাম লিখুন' type="text" value={watch('referance_name') || ''} className={`block bg-white mt-2 w-full rounded-md border border-lime-500 px-5 py-3.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ${errors.referance_name && 'border-red-500 ring ring-red-200 focus:ring-red-300  focus:border-red-500'}`}
                                                            {...register('referance_name', { required: 'এই তথ্যটি পূরণ করা আবশ্যক' })}
                                                        />
                                                        {errors.referance_name &&
                                                            <span
                                                                className='text-sm text-red-500 pt-2'
                                                            >{errors.referance_name.message}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>

                                <div className=''>
                                    <div>
                                        {/* applicant section */}
                                        <div>
                                            <div>
                                                {
                                                    institutionType === '1' &&
                                                    <>
                                                        <div className='mt-6'>
                                                            <label htmlFor="Description" className={`block text-lg  text-color`}>আবেদনকারীর পদবী<span className='text-red-500'>*</span></label>

                                                            <div className='flex items-center gap-5 text-lg '>
                                                                <label>
                                                                    <input
                                                                        type="radio"
                                                                        value="ইমাম/খতীব"
                                                                        className="mr-2"
                                                                        {...register('designation', { required: true })}
                                                                    />
                                                                    ইমাম/খতীব
                                                                </label>
                                                                <label>
                                                                    <input
                                                                        type="radio"
                                                                        value="মুতাওয়াল্লী/সভাপতি"
                                                                        className="mr-2"
                                                                        {...register('designation', { required: true })}
                                                                    />
                                                                    মুতাওয়াল্লী/সভাপতি
                                                                </label>
                                                                <label>
                                                                    <input
                                                                        type="radio"
                                                                        value="সাধারণ সম্পাদক"
                                                                        className="mr-2"
                                                                        {...register('designation', { required: true })}
                                                                    />
                                                                    সাধারণ সম্পাদক
                                                                </label>
                                                            </div>
                                                            {errors.designation &&
                                                                <p
                                                                    className='text-red-500 text-sm'
                                                                >এই তথ্যটি পূরণ করা আবশ্যক</p>}
                                                        </div>
                                                    </>
                                                }
                                            </div>

                                            <div>
                                                {
                                                    institutionType === '2' &&
                                                    <>
                                                        <div className='mt-6'>
                                                            <label htmlFor="Description" className={`block text-lg  text-color `}>আবেদনকারীর পদবী<span className='text-red-500'>*</span></label>
                                                            <div className='flex items-center gap-5 text-lg'>
                                                                <label>
                                                                    <input
                                                                        type="radio"
                                                                        value="ভিসি/অধ্যক্ষ"
                                                                        className="mr-2"
                                                                        {...register('designation', { required: true })}
                                                                    />
                                                                    ভিসি/অধ্যক্ষ
                                                                </label>
                                                                <label>
                                                                    <input
                                                                        type="radio"
                                                                        value="প্রক্টর"
                                                                        className="mr-2"
                                                                        {...register('designation', { required: true })}
                                                                    />
                                                                    প্রক্টর
                                                                </label>

                                                                <label>
                                                                    <input
                                                                        type="radio"
                                                                        value="বিভাগীয় প্রধান"
                                                                        className="mr-2"
                                                                        {...register('designation', { required: true })}
                                                                    />
                                                                    বিভাগীয় প্রধান
                                                                </label>

                                                                <label>
                                                                    <input
                                                                        type="radio"
                                                                        className="mr-2"
                                                                        value="শিক্ষক"
                                                                        {...register('designation', { required: true })}
                                                                    />
                                                                    শিক্ষক
                                                                </label>
                                                                <label>
                                                                    <input
                                                                        type="radio"
                                                                        className="mr-2"
                                                                        value="ছাত্র"
                                                                        {...register('designation', { required: true })}
                                                                    />
                                                                    ছাত্র
                                                                </label>
                                                            </div>
                                                            {errors.designation &&
                                                                <p
                                                                    className='text-red-500 text-sm '
                                                                >এই তথ্যটি পূরণ করা আবশ্যক</p>}
                                                        </div>
                                                    </>
                                                }
                                            </div>


                                            <div>
                                                {
                                                    institutionType === '3' &&
                                                    <>
                                                        <div className='flex items-center flex-col lg:flex-row gap-6 lg:gap-7 mt-6'>
                                                            <div className='w-full h-24'>
                                                                <label htmlFor="username" className="block text-lg  color">আবেদনকারীর পেশা<span className='text-red-500'>*</span></label>

                                                                <input placeholder='আবেদনকারীর পেশা লিখুন' type="text" value={watch('designation') || ''} className={`block bg-white mt-2 w-full  rounded-md border border-lime-500 px-5 py-3.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ${errors.designation && 'border-red-500 ring ring-red-200 focus:ring-red-300  focus:border-red-500'}`}

                                                                    {...register("designation", { required: "এই তথ্যটি পূরণ করা আবশ্যক" })}
                                                                />
                                                                {errors.designation &&
                                                                    <span
                                                                        className='text-sm text-red-500 pt-2'
                                                                    >{errors.designation.message}</span>}
                                                            </div>

                                                            <div className='w-full h-24'>

                                                                <label htmlFor="username" className="block text-lg  color">মিলনায়তনের ভাড়ার উৎস<span className='text-red-500'>*</span></label>

                                                                <input placeholder='মিলনায়তনের ভাড়ার উৎস লিখুন' type="text" value={watch('place_renting_source') || ''} className={`block bg-white mt-2 w-full   rounded-md border border-lime-500 px-5 py-3.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ${errors.place_renting_source && 'border-red-500 ring ring-red-200 focus:ring-red-300  focus:border-red-500'}`}
                                                                    {...register("place_renting_source", { required: "এই তথ্যটি পূরণ করা আবশ্যক" })}
                                                                />
                                                                {errors.place_renting_source &&
                                                                    <span
                                                                        className='text-sm text-red-500 pt-2'
                                                                    >{errors.place_renting_source.message}</span>}
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </div>


                                            <div>
                                                {
                                                    institutionType === '4' &&
                                                    <>
                                                        <div className='mt-6'>
                                                            <label htmlFor="Description" className="block text-lg  text-color">আবেদনকারীর পদবী<span className='text-red-500'>*</span></label>
                                                            <div className='flex items-center gap-5 text-lg '>
                                                                <label>
                                                                    <input
                                                                        type="radio"
                                                                        value="সভাপতি/সাধারণ সম্পাদক"
                                                                        className="mr-2"
                                                                        {...register('designation', { required: true })}
                                                                    />
                                                                    সভাপতি/সাধারণ সম্পাদক
                                                                </label>
                                                                <label>
                                                                    <input
                                                                        type="radio"
                                                                        value="মুহতামিম/প্রিন্সিপাল"
                                                                        className="mr-2"
                                                                        {...register('designation', { required: true })}
                                                                    />
                                                                    মুহতামিম/প্রিন্সিপাল
                                                                </label>
                                                                <label>
                                                                    <input
                                                                        type="radio"
                                                                        className="mr-2"
                                                                        value="শিক্ষক"
                                                                        {...register('designation', { required: true })}
                                                                    />
                                                                    শিক্ষক
                                                                </label>
                                                            </div>
                                                            {errors.designation &&
                                                                <p
                                                                    className='text-red-500 text-sm '
                                                                >এই তথ্যটি পূরণ করা আবশ্যক</p>}
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>

                                        <div className=' mt-6'>

                                            <div className='flex items-center flex-col lg:flex-row gap-6 lg:gap-7 w-full'>
                                                <div className='w-full h-24'>
                                                    <label htmlFor="username" className="block text-lg  color">আবেদনকারীর নাম<span className='text-red-500'>*</span></label>

                                                    <input placeholder='আবেদনকারীর নাম লিখুন' type="text" value={watch('applicante_name') || ''} maxLength={75} className={`block bg-white mt-2 w-full  rounded-md border border-lime-500 px-5 py-3.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ${errors.applicante_name && 'border-red-500 ring ring-red-200 focus:ring-red-300  focus:border-red-500'}`}
                                                        {...register("applicante_name", { required: "এই তথ্যটি পূরণ করা আবশ্যক" })}
                                                    />
                                                    {errors.applicante_name &&
                                                        <span
                                                            className='text-sm text-red-500 pt-2'
                                                        >{errors.applicante_name.message}</span>}
                                                </div>

                                                <div className='w-full h-24 mt-6 lg:mt-0'>
                                                    <label htmlFor="username" className="block text-lg  color">আবেদনকারীর মোবাইল নম্বর<span className='text-red-500'>*</span></label>
                                                    <div
                                                    >
                                                        <div className='relative'>
                                                            <div className={` h-full w-[50px] md:w-16 absolute rounded-s border-r border-lime-500 flex items-center justify-center focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ${errors.applicante_mobile && 'border-red-500 ring ring-red-200 focus:ring-red-300  focus:border-red-500'} `}>
                                                                <p className=''>+88</p>
                                                            </div>
                                                            <input
                                                                placeholder="মোবাইল নম্বর লিখুন"
                                                                type="text"
                                                                value={watch('applicante_mobile') || ''}
                                                                className={`block bg-white mt-2 w-full appearance-none rounded-md border border-lime-500 ps-[59px] md:ps-[75px] py-3.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ${errors.applicante_mobile && 'border-red-500 ring ring-red-200 focus:ring-red-300  focus:border-red-500'}`}
                                                                {...register('applicante_mobile', {
                                                                    required: 'এই তথ্যটি পূরণ করা আবশ্যক',
                                                                    pattern: {
                                                                        value: /^01\d{9}$/,
                                                                        message: 'সঠিক মেবাইল নম্বর প্রদান করুন',
                                                                    },
                                                                })}
                                                                onKeyPress={(e) => {
                                                                    const charCode = e.which ? e.which : e.keyCode;
                                                                    if (charCode !== 8 && charCode !== 0 && (charCode < 48 || charCode > 57)) {
                                                                        e.preventDefault();
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>


                                                    {applicante_mobile && (applicante_mobile.length > 0 && (applicante_mobile.slice(0, 2) !== '01' || applicante_mobile.length !== 11)) ? (
                                                        <p className="text-red-500 text-sm pt-1">
                                                            সঠিক মোবাইল নম্বর প্রদান করুন।
                                                        </p>
                                                    ) : errors.applicante_mobile?.message ? <span
                                                        className='text-sm text-red-500 pt-2'
                                                    >{errors.applicante_mobile?.message}</span> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* rules for apply */}
                                <ApplyRules />


                                {/* submit section  */}
                                <div className='flex items-center justify-between flex-col md:flex-row mt-11 text-black'>
                                    <label className='text-black lg:w-1/2'>
                                        <input
                                            type="checkbox"
                                            checked={agreeTerms}
                                            onChange={handleTermsCondition}
                                            className="mr-2 "
                                        />
                                        উল্লিখিত শর্তাবলি পড়েছি এবং সকল শর্ত মেনে আবেদন করছি।
                                    </label>

                                    <div
                                        className='flex items-center gap-3 mt-5 md:mt-0'
                                    >
                                        <button
                                            type='button'
                                            onClick={handlePreview}
                                            className={`lab-btn-previous-button bg-blue-500
                                            }`}
                                        >
                                            {
                                                isLoading ?
                                                    <div className="w-6 h-6 border-2 border-dashed rounded-full animate-spin border-white"></div>
                                                    :
                                                    "একনজর দেখুন"
                                            }
                                        </button>
                                        <button type="submit" disabled={!agreeTerms || loader} className={`lab-btn-submit-form ${!agreeTerms || loader ? 'opacity-50 cursor-not-allowed' : ''
                                            }`}>

                                            {
                                                loader ?
                                                    <div className="w-6 h-6 border-2 border-dashed rounded-full animate-spin border-white"></div>
                                                    :
                                                    "প্রেরণ করুন"
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div>
                            {
                                formData &&
                                <PreviewModal
                                    isOpen={isOpen}
                                    openModal={openModal}
                                    closeModal={closeModal}
                                    formData={formData}
                                    editBtn={editBtn}
                                    setEditBtn={setEditBtn}
                                    applicationNumber={applicationNumber}
                                />
                            }

                            <div>
                                <SubmitModal
                                    open={open}
                                    formData={formData}
                                    onModal={onModal}
                                    offModal={offModal}
                                    applicationNumber={applicationNumber}
                                    handleDownload={handleDownload}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TwoAppoinment;