import './App.css';
import { RouterProvider } from 'react-router-dom';
import routes from './Router/Routes';
import { Toaster } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { getCurrentUser } from './redux/actions/authActions';
import { useEffect } from 'react';
const accessToken = localStorage.getItem('accessToken');

function App() {
  const dispatch = useDispatch()
  useEffect(() => {
    if (accessToken) {
      dispatch(getCurrentUser())
    }
  }, [])

  return (
    <div className="App">
      <RouterProvider router={routes}></RouterProvider>
      <Toaster />
    </div>
  );
}

export default App;
