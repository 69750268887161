import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AdminRoutes = ({ children }) => {
    const location = useLocation();
    const { isAuthenticated, loading, user } = useSelector(state => state.auth);

    if (loading) {
        return <></>
    }

    if (isAuthenticated && user?.role === 'admin') {
        return children;
    }

    return <Navigate to="/signin" state={{ from: location }} replace></Navigate>;
};

export default AdminRoutes;