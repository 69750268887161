import React from 'react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { engToBanPage } from '../utils/helpers';

const renderPageButtons = ({ pages, page, handleSetPage }) => {
    const visiblePages = 3;
    const buttons = [];

    if (pages <= visiblePages) {
        // If total pages are less than or equal to visiblePages
        for (let number = 0; number < pages; number++) {
            buttons.push(
                <button
                    key={number}
                    className={
                        page == number
                            ? 'flex items-center justify-center px-4 py-2 mx-1 text-white transition-colors duration-300 transform bg-blue-500 rounded-md rtl:-scale-x-100   hover:bg-blue-500  hover:text-white '
                            : 'flex items-center justify-center px-4 py-2 mx-1 text-gray-700 transition-colors duration-300 transform bg-white rounded-md rtl:-scale-x-100   hover:bg-blue-500  hover:text-white  border-2'
                    }
                    onClick={() => handleSetPage(number)}
                >
                    {engToBanPage(number + 1)}
                </button>
            );
        }
    } else {
        // If total pages are greater than visiblePages
        const startRange = Math.min(Math.max(0, page - Math.floor(visiblePages / 2)), pages - visiblePages);
        const endRange = startRange + visiblePages;

        if (startRange > 0) {
            buttons.push(
                <span key="ellipsis-start" className="text-gray-700">
                    ...
                </span>
            );
        }

        for (let number = startRange; number < endRange; number++) {
            buttons.push(
                <button
                    key={number}
                    className={
                        page == number
                            ? 'flex items-center justify-center px-4 py-2 mx-1 text-white transition-colors duration-300 transform bg-blue-500 rounded-md rtl:-scale-x-100   hover:bg-blue-500  hover:text-white '
                            : 'flex items-center justify-center px-4 py-2 mx-1 text-gray-700 transition-colors duration-300 transform bg-white rounded-md rtl:-scale-x-100  hover:bg-blue-500  hover:text-white  border-2'
                    }
                    onClick={() => handleSetPage(number)}
                >
                    {engToBanPage(number + 1)}
                </button>
            );
        }

        if (endRange < pages) {
            buttons.push(
                <span key="ellipsis-end" className="text-gray-700">
                    ...
                </span>
            );
        }
    }

    return buttons;
};

const Pagination = ({
    page,
    pages,
    numOfApplications,
    handlePrevClick,
    handleNextClick,
    limit,
    handleSizeChange,
    handleFirstPageClick,
    handleLastPageClick,
    handleSetPage
}) => {
    return (
        <div className="my-11 px-4">
            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <div className="relative">
                        <select className="border px-5 py-2 w-20 rounded outline-none appearance-none bg-transparent "
                            value={limit} onChange={handleSizeChange}
                        >
                            <option value="1">1</option>
                            <option value="10">১০</option>
                            <option value="15">১৫</option>
                            <option value="20">২০</option>
                            <option value="25">২৫</option>
                            <option value="50">৫০</option>
                            <option value="75">৭৫</option>
                            <option value="100">১০০</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-600 ">
                            <svg className="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M10 12L6 8h8l-4 4z" />
                            </svg>
                        </div>
                    </div>
                    <h3 className='ml-4 text-md'>সারি</h3>
                </div>
                <div className="flex items-center">
                    <div>
                        <h3 className='text-md'>{engToBanPage(page * limit + 1)} থেকে {engToBanPage(Math.min((page + 1) * limit, numOfApplications))} দেখানো হচ্ছে  {engToBanPage(numOfApplications)} এর মধ্যে</h3>
                    </div>
                    <button
                        disabled={page == 0}
                        onClick={handlePrevClick}
                        href="#"
                        className={`flex items-center justify-center px-4 py-2 mx-1 text-gray-500 capitalize bg-white rounded-md  rtl:-scale-x-100 ${page == 0 ? 'cursor-not-allowed' : undefined
                            }`}
                    >
                        <FaChevronLeft className="w-5 h-5" />
                    </button>

                    <div className="flex items-center gap-3">{renderPageButtons({ page, pages, handleSetPage })}</div>

                    <button
                        disabled={page == pages - 1}
                        onClick={handleNextClick}
                        href="#"
                        className={`flex items-center justify-center px-4 py-2 mx-1 text-gray-700 transition-colors duration-300 transform bg-white rounded-md rtl:-scale-x-100 ${page == pages - 1 ? 'cursor-not-allowed' : undefined
                            }`}
                    >
                        <FaChevronRight className="w-5 h-5" />
                    </button>

                    <div className='flex items-center'>
                        <button
                            onClick={handleFirstPageClick}
                            className={`flex items-center justify-center px-4 py-2 mx-1  transform rounded-md rtl:-scale-x-100 ml-3  transition-all duration-500 ease-in-out border ${page == 0 ? 'bg-gray-100 cursor-not-allowed text-gray-300' : 'bg-white hover:bg-blue-500 hover:text-white text-gray-700'}`}
                        >
                            প্রথম পাতা
                        </button>
                        <button
                            onClick={handleLastPageClick}
                            className={`flex items-center justify-center px-4 py-2 mx-1  transform  rounded-md rtl:-scale-x-100 ml-3  transition-all duration-500 ease-in-out border ${page == pages - 1 ? 'bg-gray-100 cursor-not-allowed text-gray-300' : 'bg-white hover:bg-blue-500 hover:text-white text-gray-700'}`}
                        >
                            শেষ পাতা
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pagination