import React, { useState } from 'react';
import logo from '../../../assets/shaikh_ahmadullah_logo.png'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { FiLogOut } from "react-icons/fi";
import { AiOutlineHome } from "react-icons/ai";
import application_btn from '../../../assets/icon/all_applications.png';
import accepted_icon from '../../../assets/svgIcons/approved_application.svg';
import rejected_icon from '../../../assets/svgIcons/rejected_applications.svg';

import trash from '../../../assets/icon/delete.png';
import { singOut } from '../../../redux/actions/authActions';
import { useDispatch } from 'react-redux';
import { clearLocalStorage } from '../../../utils/helpers';
import { applicationType, version } from '../../../utils/constants';

const Sidebar = () => {
    const dispatch = useDispatch();
    const [isActive, setActive] = useState(false)
    const navigate = useNavigate()
    const handleToggle = () => {
        setActive(!isActive)
    }
    const handleLogout = () => {
        dispatch(singOut())
        clearLocalStorage();
        localStorage.removeItem('accessToken');
        navigate('/signin')
    }

    const naviagtion = [
        {
            icon: <AiOutlineHome />,
            link: '/asf-admin',
            name: "হোম",
            path: '/asf-admin/',
        },
        {
            icon: application_btn,
            link: '/asf-admin/applications',
            name: "অনির্ধারিত",
            path: '/asf-admin/applications',
        },
        {
            icon: accepted_icon,
            link: '/asf-admin/accepted',
            name: "গৃহীত",
            path: '/asf-admin/accepted',
        },
        {
            icon: accepted_icon,
            link: '/asf-admin/next',
            name: "পরবর্তী",
            path: '/asf-admin/next',
        },
        {
            icon: accepted_icon,
            link: '/asf-admin/completed',
            name: "সম্পন্ন",
            path: '/asf-admin/completed',
        },
        {
            icon: rejected_icon,
            link: '/asf-admin/rejected',
            name: "বাতিল",
            path: '/asf-admin/rejected',
        },
        {
            icon: trash,
            link: '/asf-admin/trash',
            name: "ট্র্যাশ",
            path: '/asf-admin/trash',
        }
    ];

    const location = useLocation();
    const currentPath = location.pathname;
    const [searchParams] = useSearchParams();
    const type = searchParams.get("type")

    const handleMenuItemClick = (link) => {
        localStorage.setItem('previousRoute', link);
    };

    return (
        <div>
            <div className='bg-gray-100 text-gray-800 flex justify-between md:hidden px-5'>
                <div>
                    <div className='block cursor-pointer p-4 font-bold'>
                        <a className="font-bold normal-case sm:text-4xl text-2xl lg:text-5xl logo"></a>
                    </div>
                </div>

                <button
                    onClick={handleToggle}
                    className='mobile-menu-button  p-2 m-2  rounded-full focus:outline-none focus:bg-gray-200'
                >
                    {
                        isActive ?
                            <i className="fa-solid fa-bars-progress text-2xl font-bold"></i>
                            :
                            <i className="fa-solid fa-xmark text-2xl font-bold"></i>
                    }
                </button>
            </div>
            <aside
                className={`z-10 md:fixed flex flex-col justify-between overflow-x-hidden bg-gray-100 w-48 space-y-6 px-5 py-4 absolute inset-y-0 left-0 transform overflow-y-auto border-r rtl:border-r-0 rtl:border-l ${isActive && '-translate-x-full'
                    }  md:translate-x-0  transition duration-200 ease-in-out`}
            >
                <a href="#">
                    <img className="w-auto h-11 md:h-20" src={logo} alt="" />
                </a>

                <div className="flex flex-col justify-between flex-1 mt-6">
                    <nav className="-mx-3 space-y-3">
                        {naviagtion.map((item, i) => {
                            let path;
                            if (item.path.split('/')[2] === 'applications') {
                                path = applicationType.all
                            } else {
                                path = item.path.split('/')[2]
                            }
                            return <Link
                                key={item.link}
                                to={item.link}
                                className={`flex items-center px-3 py-2 text-gray-600 transition-colors duration-300 transform rounded-lg hover:bg-gray-200 hover:text-gray-700 ${(item.link === currentPath || path == type) && 'bg-gray-200'} ${currentPath && currentPath === '/asf-admin' && 'bg-gray-100'}`}
                                onClick={() => handleMenuItemClick(item.link)}
                            >
                                {i === 0 ? (
                                    <AiOutlineHome className="text-lg" />
                                ) : (
                                    <img src={item.icon} className="h-4 w-4" alt="" />
                                )}
                                <span className="mx-2 text-sm font-medium">{item.name}</span>
                            </Link>
                        })}
                    </nav>

                    <div className="mt-6 dashboard">
                        <h2 className='text-sm text-gray opacity-[0.4]'>v{version}</h2>
                        <div className="flex items-center justify-start gap-3 mt-2">
                            <h3 onClick={handleLogout} className="dashboard flex items-center gap-x-2 hover:text-red-600 transition-all ease-in-out duration-500 inline cursor-pointer">
                                <span className="text-sm font-medium">সাইন আউট</span>
                                <FiLogOut />
                            </h3>
                        </div>
                    </div>
                </div>
            </aside>
        </div>
    );
};

export default Sidebar;