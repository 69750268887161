import React from 'react';

const EmptyCompoWIthCLear = ({ districtId, subDistrictId, institutionId, search }) => {
    const text = (districtId || subDistrictId || institutionId || search) ? 'কোনো ফলাফল পাওয়া যায়নি!' : 'এখানে কোনো তথ্য নেই!';
    return (
        <div className="flex items-center mx-4 mt-6 text-center border rounded-lg h-96 ">
            <div className="flex flex-col w-full  px-4 ">
                <h1 className="mt-3 text-lg text-gray-800"> {text} </h1>
                <div className="flex items-center mt-4 sm:mx-auto">
                </div>
            </div>
        </div>
    );
};

export default EmptyCompoWIthCLear;