import { toast } from 'react-hot-toast';
import { applicationType } from '../../utils/constants';
import api from '../api';
import {
    APPLICATION_SUMMARY_REQIEST,
    APPLICATION_SUMMARY_SUCCESS,
    APPLICATION_SUMMARY_FAIL,
    ALL_APPLICATIONS_REQIEST,
    ALL_APPLICATIONS_SUCCESS,
    ALL_APPLICATIONS_FAIL,
    ACCEPTED_APPLICATIONS_REQIEST,
    ACCEPTED_APPLICATIONS_SUCCESS,
    ACCEPTED_APPLICATIONS_FAIL,
    NEXT_APPLICATIONS_REQIEST,
    NEXT_APPLICATIONS_SUCCESS,
    NEXT_APPLICATIONS_FAIL,
    COMPLETED_APPLICATIONS_REQIEST,
    COMPLETED_APPLICATIONS_SUCCESS,
    COMPLETED_APPLICATIONS_FAIL,
    REJECTED_APPLICATIONS_REQIEST,
    REJECTED_APPLICATIONS_SUCCESS,
    REJECTED_APPLICATIONS_FAIL,
    TRASHED_APPLICATIONS_REQIEST,
    TRASHED_APPLICATIONS_SUCCESS,
    TRASHED_APPLICATIONS_FAIL,
    SINGLE_APPLICATIONS_REQIEST,
    SINGLE_APPLICATIONS_SUCCESS,
    SINGLE_APPLICATIONS_FAIL,
    ACCEPT_APPLICATION_REQIEST,
    ACCEPT_APPLICATION_SUCCESS,
    ACCEPT_APPLICATION_FAIL,
    REJECT_APPLICATION_REQIEST,
    REJECT_APPLICATION_SUCCESS,
    REJECT_APPLICATION_FAIL,
    UPDATE_APPLICATION_REQIEST,
    UPDATE_APPLICATION_SUCCESS,
    UPDATE_APPLICATION_FAIL,
    TRASH_APPLICATION_REQIEST,
    TRASH_APPLICATION_SUCCESS,
    TRASH_APPLICATION_FAIL,
    TRASH_BACK_APPLICATION_REQIEST,
    TRASH_BACK_APPLICATION_SUCCESS,
    TRASH_BACK_APPLICATION_FAIL,
    DELETE_APPLICATION_REQIEST,
    DELETE_APPLICATION_SUCCESS,
    DELETE_APPLICATION_FAIL,
    SET_SELECTED_APPLICATION,
    UNSET_SELECTED_APPLICATION,
    SET_LOADER,
    CLEAR_LOADER,
    SET_LOADER_FUNCTION,
    CLEAR_LOADER_FUNCTION
} from '../constants';

export const getApplicationSummary = () => async (dispatch) => {
    try {
        dispatch({ type: APPLICATION_SUMMARY_REQIEST })
        const { data } = await api.get(`/program/applications/summary`)
        dispatch({ type: APPLICATION_SUMMARY_SUCCESS, payload: data })
    } catch (err) {
        console.log('err: ', err)
        dispatch({ type: APPLICATION_SUMMARY_FAIL })
    }
};

export const getAllApplications = (query) => async (dispatch) => {
    try {
        const { type } = query;
        if (query.subDistrictId === 'city-corporation')
            delete query.subDistrictId;
        let queryString = '';
        for (const key in query) {
            if (query[key]) {
                queryString += `${key}=${query[key]}&`
            }
        }
        dispatch({ type: ALL_APPLICATIONS_REQIEST })
        dispatch(unsetDelayLoader())
        dispatch(setDelayLoader())
        const { data } = await api.get(`/program/applications/all?${queryString}`)
        data.type = type;
        dispatch({ type: ALL_APPLICATIONS_SUCCESS, payload: data })
        dispatch(unsetDelayLoader())
    } catch (err) {
        console.log('err: ', err)
        dispatch({ type: ALL_APPLICATIONS_FAIL, payload: err })
        dispatch(unsetDelayLoader())
    }
};

export const getAcceptedApplications = (query) => async (dispatch) => {
    try {
        const { type } = query;
        if (query.subDistrictId === 'city-corporation')
            delete query.subDistrictId;

        let queryString = '';
        for (const key in query) {
            if (query[key]) {
                queryString += `${key}=${query[key]}&`
            }
        }
        dispatch({ type: ACCEPTED_APPLICATIONS_REQIEST })
        dispatch(unsetDelayLoader())
        dispatch(setDelayLoader())
        const { data } = await api.get(`/program/applications/all?${queryString}`)
        data.type = type;
        dispatch({ type: ACCEPTED_APPLICATIONS_SUCCESS, payload: data })
        dispatch(unsetDelayLoader())
    } catch (err) {
        console.log('err: ', err)
        dispatch({ type: ACCEPTED_APPLICATIONS_FAIL, payload: err })
        dispatch(unsetDelayLoader())
    }
};

export const getNextApplications = (query) => async (dispatch) => {
    try {
        const { type } = query;
        if (query.subDistrictId === 'city-corporation')
            delete query.subDistrictId;
        let queryString = '';
        for (const key in query) {
            if (query[key]) {
                queryString += `${key}=${query[key]}&`
            }
        }
        dispatch({ type: NEXT_APPLICATIONS_REQIEST })
        dispatch(unsetDelayLoader())
        dispatch(setDelayLoader())
        const { data } = await api.get(`/program/applications/all?${queryString}`)
        data.type = type;
        dispatch({ type: NEXT_APPLICATIONS_SUCCESS, payload: data })
        dispatch(unsetDelayLoader())
    } catch (err) {
        console.log('err: ', err)
        dispatch({ type: NEXT_APPLICATIONS_FAIL, payload: err })
        dispatch(unsetDelayLoader())
    }
};

export const getCompletedApplications = (query) => async (dispatch) => {
    try {
        const { type } = query;
        if (query.subDistrictId === 'city-corporation')
            delete query.subDistrictId;
        let queryString = '';
        for (const key in query) {
            if (query[key]) {
                queryString += `${key}=${query[key]}&`
            }
        }
        dispatch({ type: COMPLETED_APPLICATIONS_REQIEST })
        dispatch(unsetDelayLoader())
        dispatch(setDelayLoader())
        const { data } = await api.get(`/program/applications/all?${queryString}`)
        data.type = type;
        dispatch({ type: COMPLETED_APPLICATIONS_SUCCESS, payload: data })
        dispatch(unsetDelayLoader())
    } catch (err) {
        console.log('err: ', err)
        dispatch({ type: COMPLETED_APPLICATIONS_FAIL, payload: err })
        dispatch(unsetDelayLoader())
    }
};

export const getRejectedApplications = (query) => async (dispatch) => {
    try {
        const { type } = query;
        if (query.subDistrictId === 'city-corporation')
            delete query.subDistrictId;
        let queryString = '';
        for (const key in query) {
            if (query[key]) {
                queryString += `${key}=${query[key]}&`
            }
        }
        dispatch({ type: REJECTED_APPLICATIONS_REQIEST })
        dispatch(unsetDelayLoader())
        dispatch(setDelayLoader())
        const { data } = await api.get(`/program/applications/all?${queryString}`)
        data.type = type;
        dispatch({ type: REJECTED_APPLICATIONS_SUCCESS, payload: data })
        dispatch(unsetDelayLoader())
    } catch (err) {
        console.log('err: ', err)
        dispatch({ type: REJECTED_APPLICATIONS_FAIL, payload: err })
        dispatch(unsetDelayLoader())
    }
};

export const getTrashedApplications = (query) => async (dispatch) => {
    try {
        const { type } = query;
        if (query.subDistrictId === 'city-corporation')
            delete query.subDistrictId;
        let queryString = '';
        for (const key in query) {
            if (query[key]) {
                queryString += `${key}=${query[key]}&`
            }
        }
        dispatch({ type: TRASHED_APPLICATIONS_REQIEST })
        dispatch(unsetDelayLoader())
        dispatch(setDelayLoader())
        const { data } = await api.get(`/program/applications/all?${queryString}`)
        data.type = type;
        dispatch({ type: TRASHED_APPLICATIONS_SUCCESS, payload: data })
        dispatch(unsetDelayLoader())
    } catch (err) {
        console.log('err: ', err)
        dispatch({ type: TRASHED_APPLICATIONS_FAIL, payload: err })
        dispatch(unsetDelayLoader())
    }
};

export const getSingleApplication = (id) => async (dispatch) => {
    try {
        dispatch({ type: SINGLE_APPLICATIONS_REQIEST })
        dispatch(unsetDelayLoader())
        dispatch(setDelayLoader())
        const { data } = await api.get(`/program/data/${id}`)
        dispatch({ type: SINGLE_APPLICATIONS_SUCCESS, payload: data?.data })
        dispatch(unsetDelayLoader())
    } catch (err) {
        console.log('err: ', err)
        dispatch({ type: SINGLE_APPLICATIONS_FAIL, payload: err })
        dispatch(unsetDelayLoader())
    }
};


export const setSelectedApplication = (data) => async (dispatch) => {
    try {
        dispatch({ type: UNSET_SELECTED_APPLICATION, })
        dispatch({ type: SET_SELECTED_APPLICATION, payload: data })
    } catch (err) {
        console.log('err: ', err)
    }
};

export const unsetSelectedApplication = (data) => async (dispatch) => {
    try {
        dispatch({ type: UNSET_SELECTED_APPLICATION })
    } catch (err) {
        console.log('err: ', err)
    }
};

export const acceptApplication = ({ id, type, confirmCloseModal, setIsLoading, navigate, previousRoute }) => async (dispatch) => {
    try {
        dispatch({ type: ACCEPT_APPLICATION_REQIEST })
        const { data } = await api.patch(`/program/status/${id}?type=${applicationType.accepted}`)
        data.type = type;
        dispatch({ type: ACCEPT_APPLICATION_SUCCESS, payload: data })
        confirmCloseModal()
        setIsLoading(false)
        toast.success(data?.message)
        navigate(previousRoute)
    } catch (err) {
        console.log('err: ', err)
        dispatch({ type: ACCEPT_APPLICATION_FAIL, payload: err })
        setIsLoading(false)
    }
};

export const acceptApplicationFromModal = ({ id, type, closeAcceptModal, closeModal }) => async (dispatch) => {
    try {
        dispatch({ type: ACCEPT_APPLICATION_REQIEST })
        const { data } = await api.patch(`/program/status/${id}?type=${applicationType.accepted}`)
        data.type = type;
        dispatch({ type: ACCEPT_APPLICATION_SUCCESS, payload: data })
        // toast.success(program.message)
        toast.success(data?.message)
        closeAcceptModal()
        closeModal()
    } catch (err) {
        console.log('err: ', err)
        dispatch({ type: ACCEPT_APPLICATION_FAIL, payload: err })
        closeAcceptModal()
    }
};

export const rejectApplicationFromModal = ({ id, type, closeRejectModal, closeModal }) => async (dispatch) => {
    try {
        dispatch({ type: REJECT_APPLICATION_REQIEST })
        const { data } = await api.patch(`/program/status/${id}?type=${applicationType.rejected}`)
        data.type = type;
        dispatch({ type: REJECT_APPLICATION_SUCCESS, payload: data })
        toast.success(data?.message)
        closeRejectModal()
        closeModal()
    } catch (err) {
        console.log('err: ', err)
        dispatch({ type: REJECT_APPLICATION_FAIL, payload: err })
        closeRejectModal()
    }
};

export const rejectApplication = ({ id, type, confirmCloseModal, setIsLoading, navigate, previousRoute }) => async (dispatch) => {
    try {
        dispatch({ type: REJECT_APPLICATION_REQIEST })
        const { data } = await api.patch(`/program/status/${id}?type=${applicationType.rejected}`)
        data.type = type;
        dispatch({ type: REJECT_APPLICATION_SUCCESS, payload: data })
        confirmCloseModal()
        setIsLoading(false)
        navigate(previousRoute)
        toast.success(data?.message)
    } catch (err) {
        console.log('err: ', err)
        dispatch({ type: REJECT_APPLICATION_FAIL, payload: err })
        setIsLoading(false)
    }
};

export const updateApplication = ({ id, payload, reset, closeModal, navigate, previousRoute, type }) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_APPLICATION_REQIEST })
        const { data } = await api.patch(`/program/update/${id}`, payload)
        if (payload.selected_date) {
            data.selectedDate = true;
        }
        data.type = type;
        if (payload.program_status) {
            data.program_status = applicationType.completed;
        }
        dispatch({ type: UPDATE_APPLICATION_SUCCESS, payload: data })
        reset()
        closeModal()
        // navigate(previousRoute);
        toast.success(data?.message)
    } catch (err) {
        console.log('err: ', err)
        dispatch({ type: UPDATE_APPLICATION_FAIL, payload: err })
        return err;
    }
};

export const trashApplication = ({ id, setOpen, type }) => async (dispatch) => {
    try {
        dispatch({ type: TRASH_APPLICATION_REQIEST })
        const { data } = await api.patch(`/program/status/${id}?type=${applicationType.trashed}`)
        data.type = type;
        dispatch({ type: TRASH_APPLICATION_SUCCESS, payload: data })
        toast.success(data?.message)
        setOpen(prev => !prev)
    } catch (err) {
        console.log('err: ', err)
        dispatch({ type: TRASH_APPLICATION_FAIL, payload: err })
    }
};

export const trashBackApplication = ({ id, setOpen }) => async (dispatch) => {
    try {
        dispatch({ type: TRASH_BACK_APPLICATION_REQIEST })
        const { data } = await api.patch(`/program/status/${id}?type=${applicationType.trashBack}`)
        dispatch({ type: TRASH_BACK_APPLICATION_SUCCESS, payload: data })
        toast.success(data?.message)
        setOpen(prev => !prev)
    } catch (err) {
        console.log('err: ', err)
        dispatch({ type: TRASH_BACK_APPLICATION_FAIL, payload: err })
    }
};

export const deleteApplication = ({ id, setOpen }) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_APPLICATION_REQIEST })
        const { data } = await api.delete(`/program/delete/${id}`)
        dispatch({ type: DELETE_APPLICATION_SUCCESS, payload: data })
        toast.success(data?.message)
        setOpen(prev => !prev)
    } catch (err) {
        console.log('err: ', err)
        dispatch({ type: DELETE_APPLICATION_FAIL, payload: err })
    }
};

export const setDelayLoader = t => dispatch => {
    const delayFunction = setTimeout(() => {
        dispatch({ type: SET_LOADER })
    }, 500);
    dispatch({ type: SET_LOADER_FUNCTION, payload: delayFunction })
}

export const unsetDelayLoader = t => dispatch => {
    dispatch({ type: CLEAR_LOADER })
    dispatch({ type: CLEAR_LOADER_FUNCTION })
}
