import { createBrowserRouter } from "react-router-dom";
import DashboardLayout from "../Layout/DashboardLayout";
import Main from "../Layout/Main";
import Home from "../Pages/Home/Home/Home";
import Signin from "../Pages/Registration/Signin";
import Signup from "../Pages/Registration/Signup";
import TwoAppoinment from "../Pages/Home/Appoinment/TwoAppoinment";
import DashboardHome from "../Pages/Home/Dashboard/DashboardHome";
import Applications from "../Pages/Home/Dashboard/DashboardPages/Applications";
import PrivateRoutes from "./PrivateRoutes";
import AdminRoutes from "./AdminRoutes";
import { Page } from "@react-pdf/renderer";
import EditApplicationForm from "../Pages/Home/Dashboard/ActionApplicationForm/EditApplicationForm";
import AcceptedProgram from "../Pages/Home/Dashboard/AcceptedProgram/AcceptedProgram";
import RejectedProgram from "../Pages/Home/Dashboard/RejectedProgram/RejectedProgram";
import Trash from "../Pages/Home/Dashboard/Trash/Trash";
import NextProgram from "../Pages/Home/Dashboard/NextProgram/NextProgram";
import CompletedProgram from "../Pages/Home/Dashboard/CompletedProgram/CompletedProgram";
import { applicationType } from "../utils/constants";
import NotFound from "../Shared/NotFoundPage";
const routes = createBrowserRouter([
    {
        path: '/',
        element: <Main />,
        children: [
            {
                path: '/',
                element: <Home></Home>
            },
            {
                path: '/signin',
                element: <Signin />
            },
            {
                path: '/signup',
                element: <PrivateRoutes><Signup /></PrivateRoutes>
            },
            {
                path: 'bo',
                element: <TwoAppoinment />
            },
            {
                path: 'bok',
                element: <Page />
            },
        ]
    },
    {
        path: '*',
        element: <NotFound />
    },
    {
        path: '/asf-admin',
        element: <PrivateRoutes><AdminRoutes><DashboardLayout /></AdminRoutes></PrivateRoutes>,
        children: [
            {
                path: '/asf-admin',
                element: <DashboardHome />
            },
            {
                path: '/asf-admin/not-found',
                element: <NotFound />
            },
            {
                path: '/asf-admin/applications',
                element: <Applications type={applicationType.all} />
            },
            {
                path: '/asf-admin/accepted',
                element: <AcceptedProgram type={applicationType.accepted} />,
            },
            {
                path: '/asf-admin/next',
                element: <NextProgram type={applicationType.next} />
            },
            {
                path: '/asf-admin/completed',
                element: <CompletedProgram type={applicationType.completed} />
            },
            {
                path: '/asf-admin/rejected',
                element: <RejectedProgram type={applicationType.rejected} />
            },
            {
                path: '/asf-admin/trash',
                element: <Trash type={applicationType.trashed} />
            },
            {
                path: '/asf-admin/edit/:id',
                element: <EditApplicationForm />,
            }
        ]
    }
])

export default routes;