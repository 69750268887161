import React from 'react';

const Spinner = () => {
    return (
        <div className='flex justify-center items-center h-screen'>
            <div className="wave">
                <div className="ball"></div>
                <div className="ball"></div>
                <div className="ball"></div>
                <div className="ball"></div>
                <div className="ball"></div>
            </div>
        </div>
    );
};

export default Spinner;