const cityCorporations = {
    "1": [
        "আদাবর",
        "বাড্ডা",
        "বনানী",
        "বংশাল",
        "বিমানবন্দর",
        "ভাসানটেক",
        "ক্যান্টনমেন্ট",
        "চকবাজার",
        "দক্ষিণখান",
        "ডেমরা",
        "ধানমন্ডি",
        "গেন্ডারিয়া",
        "গুলশান",
        "হাজারীবাগ",
        "যাত্রাবাড়ী",
        "কাফরুল",
        "কলাবাগান",
        "কামরাঙ্গীরচর",
        "খিলগাঁও",
        "খিলক্ষেত",
        "কদমতলী",
        "কোতয়ালী",
        "লালবাগ",
        "মিরপুর",
        "মোহাম্মদপুর",
        "নিউ মার্কেট",
        "পল্লবী",
        "পল্টন",
        "রমনা",
        "রামপুরা",
        "রূপনগর",
        "সবুজবাগ",
        "শাহ আলী",
        "শাহবাগ",
        "শাহজাহানপুর",
        "শেরে বাংলা নগর",
        "শ্যামপুর",
        "শুত্রাপুর",
        "তেজগাঁও",
        "তুরাগ",
        "উত্তরখান",
        "উত্তরা",
        "ভাটারা",
        "ওয়ারী"
    ],
    "3": [
        "বাসন",
        "কোনাবাড়ী",
        "কাশিমপুর",
        "গাছা",
        "পূবাইল",
        "টঙ্গী পূর্ব",
        "টঙ্গী পশ্চিম"
    ],
    "11": [
        "ফতুল্লা",
        "সিদ্ধিরগঞ্জ"
    ],
    "24": [
        "বোয়ালিয়া",
        "রাজপাড়া",
        "মতিহার",
        "শাহমখদুম",
        "চন্দিমা",
        "কাসিয়াডাঙ্গা",
        "কাটাখালী",
        "বেলপুকুর",
        "এয়ারপোর্ট",
        "করমহর",
        "দামকু‌ঁড়া"
    ],
    "32": [
        "কোতয়ালী",
        "পরশুরাম",
        "তাজহাট",
        "মাহিগঞ্জ",
        "হারাগাছ",
        "হাজীরহাট"
    ],
    "35": [
        "কাউনিয়া",
        "কোতয়ালী",
        "বিমানবন্দর",
        "রুপাতলী",
        "বরিশাল",
        "বিশ্ববিদ্যালয়",
        "চরমোনাই",
        "বরিশাল"
    ],
    "43": [
        "চান্দঁগাও",
        "বন্দর",
        "ডাবলমুরিং",
        "কোতয়ালী",
        "পাহাড়তলী",
        "হলিশহর",
        "খুলশী",
        "বাকলিয়া",
        "কর্ণফুলি",
        "চকবাজার",
        "আকবর শাহ",
        "সদরঘাট",
        "ইপিজেড"
    ],
    "54": [
        "কোতয়ালী",
        "জালালাবাদ",
        "এয়ারপোর্ট",
        "মোগলাবাজার",
        "শাহপরান",
        "দক্ষিণ সুরমা"
    ],
    "59": [
        "কোতয়ালী",
        "সোনাডাঙ্গা",
        "দৌলতপুর",
        "খালিশপুর",
        "খান জাহান আলী",
        "লবণচরা",
        "হরিণটানা",
        "আড়ংঘাটা"
    ]
}


export default cityCorporations;