import React from 'react';

const NotFound = () => {

    const goBack = () => {
        window.history.back();
    };
    return (
        <div className="min-h-screen flex items-center justify-center">
            <div className="text-center">
                <h1 className="text-5xl font-semibold text-gray-800 mb-4">404 - Page Not Found</h1>
                <button
                    className="bg-blue-500 hover:bg-blue-700 text-white text-xl font-bold py-2 px-4 rounded mt-4"
                    onClick={goBack}
                >
                    Go Back
                </button>
            </div>
        </div>
    );
};

export default NotFound;
