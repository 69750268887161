import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import AcceptIcon from '../../../../../src/assets/icon/accept.png';
import RejectIcon from '../../../../../src/assets/icon/reject.png';
import SaveIcon from '../../../../../src/assets/icon//save.png';
import './Edit.css';
import ConfromationModal from '../../../../components/ConformaionModal';
import ConformationSubmitModal from '../../../../components/ConformationSubmitModal';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleApplication, updateApplication } from '../../../../redux/actions/applicationsActions';
import districts from '../../../../json/districts';
import subDistricts from '../../../../json/subDistricts';
import divisions from '../../../../json/divisions'
import { formatDate, formatDateForViewers } from '../../../../utils/helpers';
import { applicationStatus, applicationType, femaleCondition, participantType as participant } from '../../../../utils/constants';

const EditApplicationForm = () => {

    const dispatch = useDispatch();
    const { id } = useParams();
    const { selectedApplication, actionLoading } = useSelector(state => state.applications)

    const [searchParams] = useSearchParams();
    const type = searchParams.get("type")



    useEffect(() => {
        if (Object.keys(selectedApplication).length === 0) {
            dispatch(getSingleApplication(id))
        }
    }, [])

    const navigate = useNavigate()

    const { register, handleSubmit, watch, formState: { errors, isDirty, isValid }, reset } = useForm();

    const institutionType = watch("institutionType")
    const participantType = watch("participantType")
    const programePlace = watch("programePlace")
    const applicante_mobile = watch("applicante_mobile")

    // preview and open modal // loadin effect
    let [isConfirmOpen, setConfirmIsOpen] = useState(false)
    const [btnTrue, setBtnTrue] = useState(false)


    // modal
    function confirmCloseModal() {
        setConfirmIsOpen(false)
        setBtnTrue(false)
    }

    function acceptConfirmOpenModal() {
        setBtnTrue(true)
        setConfirmIsOpen(true)
    }
    function rejectConfirmOpenModal() {
        setConfirmIsOpen(true)
    }

    function confirmOpenModal() {
        setConfirmIsOpen(true)
    }

    const [division, setSelectedDivision] = useState(null);
    const [district, setSelectedDistrict] = useState(null);
    const [subDistrict, setSelectedSubDistrict] = useState(null);

    useEffect(() => {
        const fetchSelectedData = async () => {
            try {
                const subDistrictId = selectedApplication?.subDistrict;

                setSelectedDivision(divisions[selectedApplication?.division]?.bn_name || 'নাই');

                setSelectedDistrict(districts[selectedApplication?.district]?.bn_name || 'নাই');

                setSelectedSubDistrict(selectedApplication?.subDistrict == 'city-corporation' ? 'সিটি কর্পোরেশন' : (subDistricts[subDistrictId]?.bn_name || 'নাই'));

            } catch (error) {
                console.log(error);
            }
        };

        fetchSelectedData();
    }, [selectedApplication]);

    // date formating with javascript
    const dateFrom = selectedApplication?.dateFrom;
    const dateTo = selectedApplication?.dateTo;
    let formattedDateFrom = '';
    let formattedDateTo = '';

    if (dateFrom) {
        const [year, month, day] = dateFrom.split('-');
        formattedDateFrom = `${day}/${month}/${year}`;
    }

    if (dateTo) {
        const [year, month, day] = dateTo.split('-');
        formattedDateTo = `${day}/${month}/${year}`;
    }

    const [isModalOpen, setIsModalOpen] = useState(false);

    const onSubmit = (data) => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    // new implementations 
    const previousRoute = localStorage.getItem('previousRoute');

    const handleConfirmation = () => {
        handleSubmit((data) => {

            if (!isValid) {
                toast.error('আবশ্যক তথ্যগুলি পূরণ করুন');
                return;
            }

            const payload = {}
            for (const key in data) {
                if (data[key]) {
                    payload[key] = data[key]
                }
            }

            if (payload.participantType !== participant.both) {
                delete payload.fimaleCondition
            }

            dispatch(updateApplication({ id: selectedApplication._id, payload, reset, closeModal, navigate, previousRoute, type }))
        })();
    }

    const makeEmpty = () => {
        localStorage.clear('previousRoute')
    }

    return (
        <div className='w-full bg-gray-100'>
            {/* For form submit using here React hook form library */}
            {Object.keys(selectedApplication).length && <div className='w-full px-3  mx-auto px-7 pb-7'>
                <div className=''>
                    <div className=''>
                        <form className=''
                            onSubmit={handleSubmit(onSubmit)}
                        >

                            <div className='flex items-center justify-between mb-9 sticky top-0 w-full h-20 bg-gray-100 z-10'>
                                <div className="bg-gray-200 ">
                                    <div className="flex items-center px-4 py-2 mx-auto overflow-x-auto whitespace-nowrap">
                                        <Link to='/asf-admin' className="text-gray-600 "
                                            onClick={makeEmpty}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                                                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                                            </svg>
                                        </Link>

                                        <span className="mx-5 text-gray-500  rtl:-scale-x-100">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                                                <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                            </svg>
                                        </span>


                                        <Link to={previousRoute} className="text-gray-600 hover:underline">
                                            {previousRoute === '/asf-admin/applications' ? 'আবেদন' :
                                                previousRoute === '/asf-admin/accepted' ? 'গৃহীত' :
                                                    previousRoute === '/asf-admin/rejected' ? 'বাতিল' :
                                                        previousRoute === '/asf-admin/trash' ? 'ট্র্যাশ' :
                                                            previousRoute === '/asf-admin/next' ? 'পরবর্তী' :
                                                                previousRoute === '/asf-admin/completed' ? 'সম্পন্ন' : ''

                                            }

                                        </Link>

                                        <span className="mx-5 text-gray-500  rtl:-scale-x-100">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                                                <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                            </svg>
                                        </span>
                                    </div>
                                </div>


                                <div className="flex overflow-hidden  divide-x  rtl:flex-row-reverse gap-3">
                                    {
                                        previousRoute === '/asf-admin/rejected' &&
                                        <button
                                            disabled={isDirty}
                                            type="button"
                                            onClick={confirmOpenModal}
                                            className={`flex items-center w-full px-4 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-300 transform  rounded-md sm:w-auto sm:mt-0  ${!isDirty ? 'bg-lime-600  hover:bg-lime-700' : 'bg-gray-400 cursor-not-allowed'}`}
                                        >
                                            <img
                                                src={AcceptIcon}
                                                className='h-4 w-4'
                                                alt=''
                                            />
                                            <span className="mx-2">গ্রহণ</span>
                                        </button>
                                    }
                                    {
                                        previousRoute === '/asf-admin/accepted' &&
                                        <button
                                            disabled={isDirty}
                                            onClick={confirmOpenModal}
                                            type="button"
                                            className={`flex items-center w-full px-4 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-300 transform rounded-md sm:w-auto sm:mt-0 ${!isDirty ? 'bg-red-600  hover:bg-red-700' : 'bg-gray-400 cursor-not-allowed'}`}
                                        >
                                            <img
                                                src={RejectIcon}
                                                className='h-4 w-4'
                                                alt=''
                                            />
                                            <span className="mx-2 text-sm">বাতিল</span>
                                        </button>
                                    }
                                    {
                                        previousRoute === '/asf-admin/applications' &&
                                        <>
                                            <button
                                                disabled={isDirty}
                                                onClick={rejectConfirmOpenModal}
                                                type="button"
                                                className={`flex items-center w-full px-4 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-300 transform  rounded-md sm:w-auto sm:mt-0  ${!isDirty ? 'bg-red-600  hover:bg-red-700' : 'bg-gray-400 cursor-not-allowed'}`}
                                            >
                                                <img
                                                    src={RejectIcon}
                                                    className='h-4 w-4'
                                                    alt=''
                                                />
                                                <span className="mx-2 text-sm">বাতিল</span>
                                            </button>
                                            <button
                                                disabled={isDirty}
                                                type="button"
                                                onClick={acceptConfirmOpenModal}
                                                className={`flex items-center w-full px-4 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-300 transform rounded-md sm:w-auto sm:mt-0 ${!isDirty ? 'bg-lime-600  hover:bg-lime-700' : 'bg-gray-400 cursor-not-allowed'}`}
                                            >
                                                <img
                                                    src={AcceptIcon}
                                                    className='h-4 w-4'
                                                    alt=''
                                                />
                                                <span className="mx-2">গ্রহণ</span>
                                            </button>

                                        </>

                                    }



                                    <button
                                        type="submit"
                                        className={`flex items-center w-full px-4 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-300 transform  rounded-md sm:w-auto  ${isDirty ? 'bg-blue-600  hover:bg-blue-700' : 'bg-gray-400 cursor-not-allowed'}`}
                                        disabled={!isDirty}
                                    >
                                        <img
                                            src={SaveIcon}
                                            className='h-4 w-4'
                                            alt=''
                                        />
                                        <span className="mx-2 text-sm ">সংরক্ষণ করুন</span>
                                    </button>

                                </div>
                            </div>

                            <div>

                                {/* select first prothistaner dhoron */}
                                <div className=' '>
                                    <div>
                                        <label htmlFor="username" className="block text-lg  text-color">যে ধরনের প্রতিষ্ঠানে হালাকাহ আয়োজন করতে চান</label>

                                        <div className='relative w-full'>
                                            <select
                                                placeholder='প্রতিষ্ঠানের নাম লিখুন'
                                                type="text" className={`block bg-gray-50 cursor-not-allowed mt-2 w-full appearance-none placeholder-gray-400/70 rounded-md border border-gray-200 px-4 py-2    text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ${errors.institutionType && ' border-red-500 ring ring-red-200 focus:ring-red-300  focus:border-red-500'}`}
                                                defaultValue={selectedApplication?.institutionType}
                                                disabled
                                            >
                                                <option value="">প্রতিষ্ঠানের ধরন</option>
                                                <option value="1">কেন্দ্রীয়/বড় মসজিদ</option>
                                                <option value="2">বিশ্ববিদ্যালয়/কলেজ/মেডিকেল কলেজ</option>
                                                <option value="3">মিলনায়তন</option>
                                                <option value="4">মাদরাসা</option>
                                            </select>

                                            {

                                                errors.institutionType &&
                                                <span
                                                    className='text-sm text-red-500 pt-2'
                                                >{errors.institutionType.message}</span>

                                            }


                                            <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                                                <svg className="fill-current h-4 w-4" viewBox="0 0 20 20">
                                                    <path d="M10 12l-6-6 1.41-1.41L10 9.17l4.59-4.58L16 6l-6 6z" />
                                                </svg>
                                            </div>

                                        </div>
                                        {
                                            <>
                                                {
                                                    institutionType === '1' &&
                                                    <span className='flex items-center gap-2'>
                                                        <span className='text-sky-500 text-sm pt-1'>মসজিদের সর্বনিম্ন ধারণ-ক্ষমতা হতে হবে ১৫০০ জন।</span>

                                                        <span className="relative flex h-2 w-2 items-center justify-center">
                                                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-200 opacity-75"></span>
                                                            <span className="relative inline-flex rounded-full h-1.5 w-1.5 bg-sky-500"></span>
                                                        </span>
                                                    </span>
                                                }
                                                {
                                                    institutionType === '3' &&
                                                    <span className='flex items-center gap-2'>
                                                        <span className='text-sky-500 text-sm pt-1'>ধারণ-ক্ষমতা সর্বনিম্ন ৫০০ জন।</span>

                                                        <span className="relative flex h-2 w-2 items-center justify-center">
                                                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-200 opacity-75"></span>
                                                            <span className="relative inline-flex rounded-full h-1.5 w-1.5 bg-sky-500"></span>
                                                        </span>
                                                    </span>
                                                }
                                                {
                                                    institutionType === '4' &&

                                                    <span className='flex items-center gap-2'>
                                                        <span className='text-sky-500 text-sm pt-1'>তাকমীল/ফাযিল কামিল সমমান ও ছাত্র সংখ্যা ৫০০ জনের অধিক।</span>

                                                        <span className="relative flex h-3 w-3 items-center justify-center">
                                                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-200 opacity-75"></span>
                                                            <span className="relative inline-flex rounded-full h-2 w-2 bg-sky-500"></span>
                                                        </span>
                                                    </span>

                                                }
                                            </>
                                        }
                                    </div>
                                </div>

                                {/* abedoner karon */}
                                <div className='mt-6'>
                                    <label htmlFor="Description" className="block text-lg  text-color">আপনার প্রতিষ্ঠানে হালাকাহ আয়োজনের প্রয়োজনীয়তা লিখুন:</label>

                                    <textarea
                                        placeholder='হালাকাহ আয়োজনের উদ্দেশ্য লিখুন'
                                        className={`block resize-none  mt-2 w-full  placeholder-text-xl rounded-md border border-gray-200 px-4 py-2 bg-gray-50 cursor-not-allowed h-32 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ${errors.goals_of_halaka && ' border-red-500 ring ring-red-200 focus:ring-red-300  focus:border-red-500'}`}
                                        defaultValue={selectedApplication?.goals_of_halaka}
                                        readOnly
                                        disabled
                                    ></textarea>
                                    {errors?.goals_of_halaka && <span className="text-red-500 text-sm">{errors?.goals_of_halaka?.message}</span>}

                                </div>



                                <div className='flex items-center flex-col lg:flex-row gap-6 lg:gap-7 mt-6 w-full'>
                                    <div className='w-full h-24'>
                                        <label htmlFor="Description" className="block text-lg  text-color">প্রোগ্রাম স্থলের ধারণ-ক্ষমতা কত?</label>

                                        <input
                                            disabled
                                            type="number"
                                            className={`appearance-none block bg-gray-50 cursor-not-allowed mt-2 w-full rounded-md border border-gray-200 px-4 py-2    text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ${errors.people_container_ability && 'border-red-500 ring ring-red-200 focus:ring-red-300  focus:border-red-500'}`}
                                            placeholder="প্রোগ্রাম স্থলের ধারণ-ক্ষমতা লিখুন"
                                            defaultValue={selectedApplication?.people_container_ability}
                                            value={watch('people_container_ability')}
                                            onKeyPress={(e) => {
                                                const charCode = e.which ? e.which : e.keyCode;
                                                if (charCode !== 8 && charCode !== 0 && charCode < 48 || charCode > 57) {
                                                    e.preventDefault();
                                                }
                                            }}
                                        />

                                        {errors.people_container_ability &&
                                            <span
                                                className='text-sm text-red-500 pt-2'
                                            >{errors.people_container_ability.message}</span>}

                                        {
                                            <>
                                                {
                                                    institutionType === '1' &&
                                                    watch("people_container_ability") < 1498 &&
                                                    <span className='flex items-center gap-2'>
                                                        <span className='text-sky-500 text-sm'>সর্বনিম্ন ১৫০০ প্রদান করুন</span>

                                                        <span className="relative flex h-2 w-2 items-center justify-center">
                                                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-200 opacity-75"></span>
                                                            <span className="relative inline-flex rounded-full h-1.5 w-1.5 bg-sky-500"></span>
                                                        </span>
                                                    </span>
                                                }
                                                {
                                                    institutionType === '3' &&
                                                    watch("people_container_ability") < 498 &&
                                                    <span className='flex items-center gap-2'>
                                                        <span className='text-sky-500 text-sm'>সর্বনিম্ন ৫০০ প্রদান করুন</span>

                                                        <span className="relative flex h-2 w-2 items-center justify-center">
                                                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-200 opacity-75"></span>
                                                            <span className="relative inline-flex rounded-full h-1.5 w-1.5 bg-sky-500"></span>
                                                        </span>
                                                    </span>
                                                }

                                            </>
                                        }
                                    </div>
                                </div>

                                {/* select first prothistaner dhoron */}
                                <div className='mt-6'>
                                    <label htmlFor="username" className="block text-lg  color"><span className='text-blue-500'>*</span>হালাকাহতে কারা অংশগ্রহণ করবেন?</label>

                                    <div className='relative w-full'>
                                        <select
                                            type="text" className={`block bg-white mt-2 w-full appearance-none placeholder-gray-400/70 rounded-md border border-gray-200 px-4 py-2    text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ${errors.participantType && 'border-red-500 ring ring-red-200 focus:ring-red-300 focus:border-red-500'}`}
                                            value={watch('participantType') || selectedApplication?.participantType}
                                            {...register('participantType', { required: 'এই তথ্যটি পূরণ করা আবশ্যক' })}
                                        >
                                            <option className='text-gray-500' value="">অংশগ্রহণকারীদের ধরন লিখুন</option>
                                            <option value={participant.male}>{participant.male}</option>
                                            <option value={participant.female}>{participant.female}</option>
                                            <option value={participant.both}>{participant.both}</option>
                                        </select>

                                        {errors.participantType &&
                                            <span
                                                className='text-sm text-red-500 pt-2'
                                            >{errors.participantType.message}</span>}
                                        <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                                            <svg className="fill-current h-4 w-4" viewBox="0 0 20 20">
                                                <path d="M10 12l-6-6 1.41-1.41L10 9.17l4.59-4.58L16 6l-6 6z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                {participantType === participant.both && (
                                    <div className="rounded mt-6 flex items-center gap-5">
                                        <h3 className={`text-lg color${errors.fimaleCondition && 're text-red-500 '}`}>
                                            <span className='text-blue-500'>*</span>
                                            নারীদের অংশগ্রহণের জন্য পর্দার সাথে স্বতন্ত্র ব্যবস্থা থাকবে কি না?</h3>
                                        <div className='flex items-center gap-5 text-lg'>
                                            <label>
                                                <input
                                                    type="radio"
                                                    className="mr-2"
                                                    value={femaleCondition.yes}
                                                    defaultChecked={selectedApplication?.fimaleCondition === femaleCondition.yes ? true : false}
                                                    {...register('fimaleCondition', { required: true })}
                                                />
                                                {femaleCondition.yes}
                                            </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    className="mr-2"
                                                    value={femaleCondition.no}
                                                    defaultChecked={selectedApplication?.fimaleCondition === femaleCondition.no ? true : false}
                                                    {...register('fimaleCondition', { required: true })}
                                                />
                                                {femaleCondition.no}
                                            </label>
                                        </div>
                                    </div>
                                )}


                                {/* based on user selection showing options */}

                                <div>
                                    <div>
                                        {
                                            selectedApplication?.institutionType == '1' &&
                                            <>
                                                <div className='mt-6'>
                                                    <label htmlFor="Description" className="block text-lg  text-color">মসজিদের বিশেষত্ব</label>

                                                    <textarea
                                                        disabled
                                                        placeholder='মসজিদের বিশেষত্ব সম্পর্কে লিখুন'
                                                        className={`block mt-2 w-full  placeholder-text-xl rounded-md border border-gray-200 px-4 py-2    bg-gray-50 cursor-not-allowed h-32 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ${errors.masjid_quality && 'border-red-500 ring ring-red-200 focus:ring-red-300  focus:border-red-500'}`}
                                                        defaultValue={selectedApplication?.masjid_quality}
                                                    ></textarea>
                                                    {errors.masjid_quality &&
                                                        <span
                                                            className='text-sm text-red-500 pt-2'
                                                        >{errors.masjid_quality.message}</span>}
                                                </div>
                                            </>
                                        }
                                    </div>

                                    <div>
                                        {
                                            institutionType === '2' ?
                                                <>
                                                    <div className='mt-6'>
                                                        <label htmlFor="username" className="block text-lg  color">প্রোগ্রাম স্থল</label>

                                                        <div className='relative w-full'>
                                                            <select
                                                                disabled
                                                                type="text" className={`block bg-gray-50 cursor-not-allowed mt-2 w-full appearance-none placeholder-gray-400/70  rounded-md border border-gray-200 px-4 py-2    text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 
                                                                ${errors.programePlace && 'border-red-500 ring ring-red-200 focus:ring-red-300  focus:border-red-500'}`}
                                                                defaultValue={selectedApplication?.programePlace}
                                                            >
                                                                <option value="">প্রোগ্রাম স্থলের ধরন</option>
                                                                <option value="কেন্দ্রীয় মসজিদ">কেন্দ্রীয় মসজিদ</option>
                                                                <option value="অডিটোরিয়াম">অডিটোরিয়াম</option>
                                                                <option value="অন্যান্য">অন্যান্য</option>
                                                            </select>
                                                            <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                                                                <svg className="fill-current h-4 w-4" viewBox="0 0 20 20">
                                                                    <path d="M10 12l-6-6 1.41-1.41L10 9.17l4.59-4.58L16 6l-6 6z" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                undefined
                                        }
                                    </div>

                                    <div>
                                        {
                                            programePlace === 'অন্যান্য' ?
                                                <>
                                                    <div className='mt-6'>
                                                        <label htmlFor="Description" className="block text-lg  text-color">প্রোগ্রাম স্থল সম্পর্কে বিস্তারিত</label>

                                                        <textarea placeholder='প্রোগ্রাম স্থল সম্পর্কে বিস্তারিত লিখুন*' className={`block bg-gray-50 cursor-not-allowed mt-2 w-full rounded-md border border-gray-200 px-4 py-2    text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 h-32 ${errors.programme_place_description && 'border-red-500 ring ring-red-200 focus:ring-red-300  focus:border-red-500'}`}
                                                            defaultValue={selectedApplication?.programme_place_description}
                                                        ></textarea>
                                                        {errors.programme_place_description &&
                                                            <span
                                                                className='text-sm text-red-500 pt-2'
                                                            >{errors.programme_place_description.message}</span>}
                                                    </div>
                                                </>
                                                :
                                                undefined
                                        }
                                    </div>

                                    <div>
                                        {
                                            institutionType === '3' &&
                                            <>
                                                <div className='mt-6'>
                                                    <label htmlFor="username" className="block text-lg  color ">রেফারকারীর নাম</label>

                                                    <div className='flex items-center gap-6 flex-col lg:flex-row w-full'>
                                                        <div className='w-full'>
                                                            <input disabled placeholder='রেফারকারীর নাম লিখুন' type="text" className={`block bg-gray-50 cursor-not-allowed mt-2 w-full rounded-md border border-gray-200 px-4 py-2    text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ${errors.referance_name && 'border-red-500 ring ring-red-200 focus:ring-red-300  focus:border-red-500'}`}
                                                                defaultValue={selectedApplication?.referance_name}
                                                            />
                                                            {errors.referance_name &&
                                                                <span
                                                                    className='text-sm text-red-500 pt-2'
                                                                >{errors.referance_name.message}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>

                                    <div>
                                        {
                                            institutionType === '4' ?
                                                <>
                                                    <div className='mt-6'>

                                                        <div className='flex items-center gap-6 flex-col lg:flex-row w-full'>
                                                            <div className='w-full h-24'>
                                                                <label htmlFor="username" className="block text-lg  color">মাদরাসার নাম</label>
                                                                <input disabled placeholder='মাদরাসার নাম লিখুন' type="text" className={`block bg-gray-50 cursor-not-allowed mt-2 w-full  rounded-md border border-gray-200 px-4 py-2    text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ${errors.madrashaw_name && 'border-red-500 ring ring-red-200 focus:ring-red-300  focus:border-red-500'}`}
                                                                    defaultValue={selectedApplication?.madrashaw_name}
                                                                />
                                                                {errors.madrashaw_name &&
                                                                    <span
                                                                        className='text-sm text-red-500 pt-2'
                                                                    >{errors.madrashaw_name.message}</span>}
                                                            </div>

                                                            <div className='w-full h-24'>
                                                                <label htmlFor="username" className="block text-lg  color">বর্তমান ছাত্র সংখ্যা</label>
                                                                <input disabled placeholder='বর্তমান ছাত্র সংখ্যা লিখুন' type="number" className={`block bg-gray-50 cursor-not-allowed mt-2 w-full rounded-md border border-gray-200 px-4 py-2    text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ${errors.present_students_number && 'border-red-500 ring ring-red-200 focus:ring-red-300  focus:border-red-500'}`}
                                                                    defaultValue={selectedApplication?.present_students_number}
                                                                />
                                                                {errors.present_students_number &&
                                                                    <span
                                                                        className='text-sm text-red-500 pt-2'
                                                                    >{errors.present_students_number.message}</span>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                undefined
                                        }
                                    </div>
                                </div>



                                <div className=' mt-6'>

                                    <div className='flex items-center flex-col lg:flex-row gap-6 lg:gap-7 w-full'>
                                        <div className='w-full h-24'>
                                            <label htmlFor="username" className="block text-lg  color">আবেদনকারীর নাম</label>

                                            <input disabled placeholder='আবেদনকারীর নাম লিখুন' type="text" className={`block bg-gray-50 cursor-not-allowed mt-2 w-full  rounded-md border border-gray-200 px-4 py-2    text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ${errors.applicante_name && 'border-red-500 ring ring-red-200 focus:ring-red-300  focus:border-red-500'}`}
                                                defaultValue={selectedApplication?.applicante_name}
                                            />
                                            {errors.applicante_name &&
                                                <span
                                                    className='text-sm text-red-500 pt-2'
                                                >{errors.applicante_name.message}</span>}
                                        </div>

                                        <div className='w-full h-24 mt-6 lg:mt-0'>
                                            <label htmlFor="username" className="block text-lg  color">আবেদনকারীর মোবাইল নম্বর</label>

                                            <input
                                                placeholder="মোবাইল নম্বর লিখুন"
                                                type="text"
                                                className={`block bg-gray-50 cursor-not-allowed  w-full placeholder-gray-400/70  rounded-md border border-gray-200 px-4 py-2  mt-2  text-gray-700 outline-none ${errors.applicante_mobile && 'border-red-500 ring ring-red-200 focus:ring-red-300  focus:border-red-500'}`}
                                                defaultValue={selectedApplication?.applicante_mobile && selectedApplication?.applicante_mobile.slice(2)}
                                                onKeyPress={(e) => {
                                                    const charCode = e.which ? e.which : e.keyCode;
                                                    if (charCode !== 8 && charCode !== 0 && (charCode < 48 || charCode > 57)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                disabled
                                            />


                                            {applicante_mobile && (applicante_mobile.length > 0 && (applicante_mobile.slice(0, 2) !== '01' || applicante_mobile.length !== 11)) ? (
                                                <p className="text-red-500 text-sm pt-1">
                                                    সঠিক মোবাইল নম্বর প্রদান করুন।
                                                </p>
                                            ) : (
                                                undefined
                                            )}
                                        </div>
                                    </div>
                                </div>

                                {/* applicant section */}

                                <div>
                                    <div>
                                        {
                                            selectedApplication?.institutionType === '1' ?
                                                <>
                                                    <div className='mt-6'>
                                                        <label htmlFor="Description" className={`block text-lg  text-color`}><span className='text-blue-500'>*</span>আবেদনকারীর পদবী</label>
                                                        {errors.designation &&
                                                            <p
                                                                className='text-red-500 text-sm'
                                                            >এই তথ্যটি পূরণ করা আবশ্যক</p>}

                                                        <div className='flex items-center gap-5 text-lg '>
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    value="ইমাম/খতীব"
                                                                    defaultChecked={selectedApplication?.designation == 'ইমাম/খতীব' ? true : false}
                                                                    className="mr-2"
                                                                    {...register('designation', { required: true })}

                                                                />
                                                                ইমাম/খতীব
                                                            </label>
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    value="মুতাওয়াল্লী/সভাপতি"
                                                                    defaultChecked={selectedApplication?.designation == 'মুতাওয়াল্লী/সভাপতি' ? true : false}
                                                                    className="mr-2"
                                                                    {...register('designation', { required: true })}

                                                                />
                                                                মুতাওয়াল্লী/সভাপতি
                                                            </label>
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    value="সাধারণ সম্পাদক"
                                                                    defaultChecked={selectedApplication?.designation == 'সাধারণ সম্পাদক' ? true : false}
                                                                    className="mr-2"
                                                                    {...register('designation', { required: true })}
                                                                />
                                                                সাধারণ সম্পাদক
                                                            </label>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                undefined
                                        }
                                    </div>

                                    <div>
                                        {
                                            selectedApplication?.institutionType === '2' ?
                                                <>
                                                    <div className='mt-6'>
                                                        <label htmlFor="Description" className={`block text-lg  text-color `}><span className='text-blue-500'>*</span>আবেদনকারীর পদবী</label>
                                                        {errors.designation &&
                                                            <p
                                                                className='text-red-500 text-sm '
                                                            >এই তথ্যটি পূরণ করা আবশ্যক</p>}

                                                        <div className='flex gap-10 text-lg '>
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    value="ভিসি/অধ্যক্ষ"
                                                                    defaultChecked={selectedApplication?.designation == 'ভিসি/অধ্যক্ষ' ? true : false}
                                                                    className="mr-2"
                                                                    {...register('designation', { required: true })}

                                                                />
                                                                ভিসি/অধ্যক্ষ
                                                            </label>
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    value="প্রক্টর"
                                                                    defaultChecked={selectedApplication?.designation == 'প্রক্টর' ? true : false}
                                                                    className="mr-2"
                                                                    {...register('designation', { required: true })}

                                                                />
                                                                প্রক্টর
                                                            </label>
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    value="বিভাগীয় প্রধান"
                                                                    defaultChecked={selectedApplication?.designation == 'বিভাগীয় প্রধান' ? true : false}
                                                                    className="mr-2"
                                                                    {...register('designation', { required: true })}

                                                                />
                                                                বিভাগীয় প্রধান
                                                            </label>
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    className="mr-2"
                                                                    value="শিক্ষক"
                                                                    defaultChecked={selectedApplication?.designation == 'শিক্ষক' ? true : false}
                                                                    {...register('designation', { required: true })}

                                                                />
                                                                শিক্ষক
                                                            </label>
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    className="mr-2"
                                                                    value="ছাত্র"
                                                                    defaultChecked={selectedApplication?.designation == 'ছাত্র' ? true : false}
                                                                    {...register('designation', { required: true })}

                                                                />
                                                                ছাত্র
                                                            </label>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                undefined
                                        }
                                    </div>


                                    <div>
                                        {
                                            selectedApplication?.institutionType === '3' &&
                                            <>
                                                <div className='flex items-center flex-col lg:flex-row gap-6 lg:gap-7 mt-6'>
                                                    <div className='w-full h-24'>
                                                        <label htmlFor="username" className="block text-lg  color">আবেদনকারীর পেশা</label>

                                                        <input placeholder='আবেদনকারীর পেশা লিখুন' type="text" className={`block bg-gray-50 cursor-not-allowed mt-2 w-full  rounded-md border border-gray-200 px-4 py-2    text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ${errors.designation && 'border-red-500 ring ring-red-200 focus:ring-red-300  focus:border-red-500'}`}
                                                            defaultValue={selectedApplication?.designation}
                                                            {...register("designation", { required: "এই তথ্যটি পূরণ করা আবশ্যক" })}
                                                        />
                                                        {errors.designation &&
                                                            <span
                                                                className='text-sm text-red-500 pt-2'
                                                            >{errors.designation.message}</span>}
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>


                                    <div>
                                        {
                                            selectedApplication?.institutionType === '4' ?
                                                <>
                                                    <div className='mt-6'>
                                                        <label htmlFor="Description" className="block text-lg  text-color"><span className='text-blue-500'>*</span>আবেদনকারীর পদবী</label>
                                                        {errors.designation &&
                                                            <p
                                                                className='text-red-500 text-sm '
                                                            >এই তথ্যটি পূরণ করা আবশ্যক</p>}

                                                        <div className='flex items-center gap-5 text-lg '>
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    value="সভাপতি/সাধারণ সম্পাদক"
                                                                    defaultChecked={selectedApplication?.designation == 'সভাপতি/সাধারণ সম্পাদক' ? true : false}
                                                                    className="mr-2"
                                                                    {...register('designation', { required: true })}

                                                                />
                                                                সভাপতি/সাধারণ সম্পাদক
                                                            </label>
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    value="মুহতামিম/প্রিন্সিপাল"
                                                                    defaultChecked={selectedApplication?.designation == 'মুহতামিম/প্রিন্সিপাল' ? true : false}
                                                                    className="mr-2"
                                                                    {...register('designation', { required: true })}

                                                                />
                                                                মুহতামিম/প্রিন্সিপাল
                                                            </label>
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    className="mr-2"
                                                                    value="শিক্ষক"
                                                                    defaultChecked={selectedApplication?.designation == 'শিক্ষক' ? true : false}
                                                                    {...register('designation', { required: true })}

                                                                />
                                                                শিক্ষক
                                                            </label>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                undefined
                                        }
                                    </div>
                                </div>


                                <div className='flex items-start flex-col 2xl:flex-row 2xl:items-center mt-6 gap-5 2xl:gap-7'>
                                    <div className='h-24'>
                                        <h3 className='text-lg color'>হালাকাহর প্রস্তাবিত তারিখ (তারিখ চূড়ান্ত করার দায়িত্ব আস-সুন্নাহ ফাউন্ডেশনের)</h3>


                                        <div className='mt-2 flex items-start lg:items-center gap-5 flex-col md:flex-row  w-full ' >
                                            <div className='w-full md:w-44 lg:w-56'>
                                                <input
                                                    id='dateFrom'
                                                    type='text'
                                                    defaultValue={dateFrom ? formattedDateFrom : "নাই"}
                                                    disabled
                                                    className={`block bg-gray-50 w-full placeholder-gray-400/70  rounded-md border border-gray-200 px-4 py-2  text-gray-700 outline-none cursor-not-allowed ${errors.dateFrom && 'border-red-500 ring ring-red-200 focus:ring-red-300 focus:border-red-500'
                                                        }`}
                                                />
                                                {errors.dateFrom && (
                                                    <span className='text-sm text-red-500 pt-2'>{errors.dateFrom.message}</span>
                                                )}
                                            </div>

                                            <label htmlFor='dateFrom' className='block text-lg color'>
                                                হতে<span className='text-red-500'></span>
                                            </label>

                                            <div className='w-full md:w-44 lg:w-56 '>
                                                <input
                                                    id='dateTo'
                                                    type='text'
                                                    defaultValue={dateTo ? formattedDateTo : "নাই"}
                                                    disabled
                                                    className={`block bg-gray-50 w-full placeholder-gray-400/70  rounded-md border border-gray-200 px-4 py-2    text-gray-700 outline-none cursor-not-allowed ${errors.dateTo && 'border-red-500 ring ring-red-200 focus:ring-red-300 focus:border-red-500'
                                                        }`}
                                                />
                                                {errors.dateTo && (
                                                    <span className='text-sm text-red-500 pt-2'>{errors.dateTo.message}</span>
                                                )}
                                            </div>

                                            <label htmlFor='dateTo' className='block text-lg color'>
                                                এর মধ্যে।
                                                <span className='text-red-500'></span>
                                            </label>
                                        </div>
                                    </div>
                                    {/* authority section  */}
                                    <div className='flex items-center gap-7'>
                                        <div className='lg:w-64 w-full h-24'>

                                            {
                                                (previousRoute === "/asf-admin/completed" || selectedApplication.status === applicationStatus.completed) ? <>
                                                    <label htmlFor="Description" className="block text-lg  text-color">হালাকাহ সম্পন্নের তারিখ</label>

                                                    <input
                                                        id='dateFrom'
                                                        type='text'
                                                        defaultValue={formatDateForViewers(selectedApplication?.selected_date)}
                                                        disabled
                                                        className={`block bg-gray-50 w-full placeholder-gray-400/70 mt-2 rounded-md border border-gray-200 px-4 py-2  text-gray-700 outline-none cursor-not-allowed ${errors.dateFrom && 'border-red-500 ring ring-red-200 focus:ring-red-300 focus:border-red-500'
                                                            }`}
                                                    />
                                                </>
                                                    : <>
                                                        <label htmlFor="Description" className="block text-lg  text-color"><span className='text-blue-500'>*</span>তারিখ নির্বাচন করুন</label>
                                                        <input type="date" className={`appearance-none block bg-white mt-2 w-full rounded-md border border-gray-200 px-4 py-2    text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ${errors?.selected_date && ' border-red-500 ring ring-red-200 focus:ring-red-300  focus:border-red-500'}`}
                                                            {...register('selected_date', {
                                                            })}
                                                            min={selectedApplication.status !== applicationStatus.next && formatDate(new Date())}
                                                            defaultValue={selectedApplication?.selected_date}
                                                        />
                                                    </>
                                            }

                                            {errors?.selected_date &&
                                                <span
                                                    className='text-sm text-red-500 pt-2'
                                                >{errors?.selected_date.message}</span>}
                                        </div>
                                        {
                                            (selectedApplication.status === applicationStatus.next) &&
                                            < div className="w-full h-24">
                                                <label htmlFor="programStatus" className="block text-lg text-color">
                                                    <span className='text-blue-500'>*</span>
                                                    হালাকাহ সম্পন্ন হয়েছে কি?

                                                </label>

                                                <div className="flex items-center mt-4">
                                                    <input
                                                        type="checkbox"
                                                        id="programStatusCheckbox"
                                                        className="mr-2"
                                                        value={applicationType.completed}
                                                        disabled={new Date(watch('selected_date')) > new Date() ? true : false}
                                                        {...register("program_status")}
                                                    />
                                                    <label htmlFor="programStatusCheckbox" className="text-gray-700">
                                                        হালাকাহ সম্পন্ন হয়েছে
                                                    </label>
                                                </div>

                                                {errors?.program_status && (
                                                    <span className="text-sm text-red-500 pt-2">
                                                        {errors.program_status.message}
                                                    </span>
                                                )}
                                            </div>
                                        }
                                    </div>

                                </div>

                                {
                                    previousRoute === "/asf-admin/completed" ||
                                    <div className='mt-6'>
                                        <label htmlFor="Description" className="block text-lg  text-color">তারিখ নির্বাচনের ক্ষেত্রে বিশেষ কারণ</label>

                                        <textarea
                                            disabled
                                            placeholder='তারিখ নির্বাচনের ক্ষেত্রে বিশেষ কারণ'
                                            className={`block mt-2 w-full  placeholder-text-xl rounded-md border border-gray-200 px-4 py-2    bg-gray-50 cursor-not-allowed h-32 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ${errors.date_selection_reason && 'border-red-500 ring ring-red-200 focus:ring-red-300  focus:border-red-500'}`}
                                            defaultValue={selectedApplication?.date_selection_reason}
                                        ></textarea>
                                        {errors.masjid_quality &&
                                            <span
                                                className='text-sm text-red-500 pt-2'
                                            >{errors.date_selection_reason.message}</span>}
                                    </div>
                                }

                                <div className='mt-6'>
                                    <div className=' mt-6'>
                                        <div className='flex items-center flex-col lg:flex-row gap-6 lg:gap-7  w-full'>
                                            <div className='w-full h-24'>
                                                <label htmlFor="username" className="block text-lg  color">বিভাগ</label>
                                                <div className='relative w-full'>
                                                    <input id="division"
                                                        readOnly
                                                        className={`block bg-gray-50 w-full placeholder-gray-400/70  rounded-md border border-gray-200 px-4 py-2  text-gray-700 outline-none cursor-not-allowed  ${errors.division && 'border-red-500 ring ring-red-200 focus:ring-red-300 focus:border-red-500'}`}
                                                        value={division}
                                                    />
                                                </div>
                                                {errors.division &&
                                                    <span
                                                        className='text-sm text-red-500 pt-2'
                                                    >{errors.district.message}</span>}
                                            </div>
                                            <div className='w-full h-24'>
                                                <label htmlFor="username" className="block text-lg  color">জেলা</label>
                                                <div className='relative w-full'>
                                                    <input id="district"
                                                        readOnly
                                                        className={`block bg-gray-50 w-full placeholder-gray-400/70  rounded-md border border-gray-200 px-4 py-2  text-gray-700 outline-none cursor-not-allowed  ${errors.district && 'border-red-500 ring ring-red-200 focus:ring-red-300 focus:border-red-500'}`}
                                                        value={district}
                                                    />

                                                </div>
                                                {errors.district &&
                                                    <span
                                                        className='text-sm text-red-500 pt-2'
                                                    >{errors.district.message}</span>}
                                            </div>
                                            {!selectedApplication?.cityCorporation &&
                                                <div className='w-full h-24 '>
                                                    <label htmlFor="username" className="block text-lg  color">উপজেলা</label>
                                                    <div className='relative w-full'>
                                                        <input id="subDistrict"
                                                            readOnly
                                                            disabled
                                                            className={`block bg-gray-50 w-full placeholder-gray-400/70  rounded-md border border-gray-200 px-4 py-2  text-gray-700 outline-none cursor-not-allowed ${errors.subDistrict && 'border-red-500 ring ring-red-200 focus:ring-red-300 focus:border-red-500'}`}
                                                            value={subDistrict}
                                                        />
                                                    </div>
                                                    {errors.subDistrict &&
                                                        <span
                                                            className='text-sm text-red-500 pt-2'
                                                        >{errors.subDistrict.message}</span>}
                                                </div>
                                            }


                                            {
                                                selectedApplication?.cityCorporation?.length > 0 &&
                                                <div className='w-full h-24'>
                                                    <label htmlFor="username" className="block text-lg  color">সিটি কর্পোরেশন এলাকা</label>
                                                    <div className='relative w-full'>
                                                        <input id="district"
                                                            readOnly
                                                            disabled
                                                            className={`block bg-gray-50 cursor-not-allowed mt-2 w-full appearance-none placeholder-gray-400/70  rounded-md border border-gray-200 px-4 py-2    text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40  ${errors.cityCorporation && 'border-red-500 ring ring-red-200 focus:ring-red-300 focus:border-red-500'}`}
                                                            value={selectedApplication?.cityCorporation}
                                                        />

                                                    </div>
                                                    {errors.cityCorporation &&
                                                        <span
                                                            className='text-sm text-red-500 pt-2'
                                                        >{errors.cityCorporation.message}</span>}
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div className='mt-6'>
                                        <label htmlFor="username" className="block text-lg  color"><span className='text-blue-500'>*</span>হালাকাহর স্থানের বিস্তারিত ঠিকানা</label>

                                        <textarea
                                            placeholder='হালাকাহ আয়োজনের উদ্দেশ্য লিখুন'
                                            className={`block resize-none  mt-2 w-full  placeholder-text-xl  rounded-md border border-gray-200 px-4 py-2    bg-white h-32 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ${errors.details_address && ' border-red-500 ring ring-red-200 focus:ring-red-300  focus:border-red-500'}`}
                                            defaultValue={selectedApplication?.details_address}
                                            {...register("details_address")}
                                        ></textarea>
                                        {errors.details_address &&
                                            <span
                                                className='text-sm text-red-500 pt-2'
                                            >{errors.details_address.message}</span>}

                                    </div>

                                    <div className='mt-6'>
                                        <label htmlFor="username" className="block text-lg  color"><span className='text-blue-500'>*</span>নোট</label>

                                        <textarea
                                            placeholder='প্রয়োজনীয় নোট লিখুন'
                                            className={`block resize-none  mt-2 w-full  placeholder-text-xl  rounded-md border border-gray-200 px-4 py-2    bg-white h-32 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ${errors.note && ' border-red-500 ring ring-red-200 focus:ring-red-300  focus:border-red-500'}`}
                                            defaultValue={selectedApplication?.note}
                                            {...register("note")}
                                        ></textarea>
                                        {errors.note &&
                                            <span
                                                className='text-sm text-red-500 pt-2'
                                            >{errors.note.message}</span>}

                                    </div>

                                </div>
                            </div>

                        </form>
                    </div>
                </div>
                {
                    <ConfromationModal
                        confirmCloseModal={confirmCloseModal}
                        isConfirmOpen={isConfirmOpen}
                        id={selectedApplication?._id}
                        applicationNumber={selectedApplication?.applicationNumber}
                        previousRoute={previousRoute}
                        btnTrue={btnTrue}
                        onSubmit={onSubmit}
                    />
                }

                {
                    <ConformationSubmitModal
                        isOpen={isModalOpen}
                        onClose={closeModal}
                        onConfirmation={handleConfirmation}
                        actionLoading={actionLoading}
                    />
                }
            </div >}
        </div >
    );
};

export default EditApplicationForm;