import React, { useEffect, useState } from 'react';
import { MdOutlineMobileScreenShare } from "react-icons/md";
import { HiOutlineLocationMarker, HiOutlineMailOpen } from "react-icons/hi";
import { BsFillSendCheckFill } from "react-icons/bs";
import { RiSendPlaneFill } from "react-icons/ri";
import logo from '../assets/ahmadullah.png';
import Mobile from '../assets/mobile.png';
import Email from '../assets/email.png';
import Location from '../assets/location.png';
import { Link, useLocation } from 'react-router-dom';
import './Footer.css';
import { toast } from 'react-hot-toast';
import { useForm } from 'react-hook-form';


const isEmailValid = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
};



const NewFooter = () => {
    const [isDisabled, setIsDisabled] = useState(true)
    const { register, handleSubmit, reset, formState: { errors, isDirty, isValid }, setValue } = useForm();

    const contactInfo = [
        {
            icon: Mobile,
            details: "০৯৬১০-০০১০৮৯"
        },
        {
            icon: Email,
            details: "assunnahfoundationbd@gmail.com"
        },
        {
            icon: Location,
            details: "আস-সুন্নাহ ফাউন্ডেশন, প্লট-সি ৭০, রোড নং ৩, ব্লক-সি, আফতাবনগর, ঢাকা-১২১২।"
        }
    ]


    const aboutInfo = [
        {
            title: "আমাদের সম্পর্কে",
            details: "শায়খ আহমাদুল্লাহ বাংলাদেশের স্বনামধন্য ইসলামী ব্যক্তিত্ব। বিদগ্ধ আলোচক, লেখক ও খতীব। ইসলামের খেদমতে তিনি নানামুখী কাজ করেন। লেখালেখি, গবেষণা ও সভা-সেমিনারে লেকচারসহ নানামুখী দাওয়াতি কার্যক্রম পরিচালনা করেন। উন্মুক্ত ইসলামিক প্রোগ্রাম ও প্রশ্নোত্তরমূলক অনুষ্ঠানে অংশগ্রহণ এবং টিভি অনুষ্ঠানে সময় দেওয়াসহ বহুমুখী সেবামূলক কাজে সপ্রতিভ গুণী ও স্বনামধন্য এই আলেমে দীন। দেশে-বিদেশে শিক্ষা, সেবা ও দাওয়াহ— ছড়িয়ে দিতে শায়খ আহমাদুল্লাহ প্রতিষ্ঠা করেছেন আস-সুন্নাহ ফাউন্ডেশন।"
        },
        {
            title: "অন্যান্য সাইট",
            sites: [
                {
                    siteName: "আস-সুন্নাহ ফাউন্ডেশন",
                    link: "https://assunnahfoundation.org/",
                },
                {
                    siteName: "ইসলামী প্রশ্নোত্তর সাইট",
                    link: "https://www.iqa.info/",
                }
            ]


        },
        {
            title: "নিউজলেটার",
            details: "আমাদের আপডেট নিয়মিত পেতে চাইলে লগইন করুন"
        }
    ]

    const onSubmit = (data) => {



        if (!data.email) {
            toast.error('Please provide an email.');
        } else {

            if (isEmailValid(data.email)) {
                toast.success('Thank you for your submission!');
            } else {
                toast.error('Please provide a valid email.');
            }
        }
        const resetForm = () => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                }, 1000);
            });
        };

        resetForm().then(() => {
            reset();
        });
    }
    const location = useLocation();
    const isSigninRoute = location.pathname === '/signin';


    return (
        <div className={`footer ${isSigninRoute && 'hidden'}`}>
            <div className=''>
                <div className='md:hidden pt-20'>
                    <div className=' grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 max-w-[1320px] mx-auto px-3  xl:px-28 gap-4 lg:gap-7 md:gap-0'>
                        {
                            contactInfo.map((item, i) =>
                                <div key={i} className={`py-5 md:py-9 px-5 footer-border  border-gray-700 flex items-center justify-center flex-col ${i == 2 ? 'footer-right' : undefined}`}>
                                    <img className='h-11'
                                        src={item.icon}
                                    />
                                    <h3 className={`${i == 1 ? 'text-lg' : 'text-lg'} text-white text-center ${i === 1 && 'emailName '}`}>{item.details}</h3>
                                </div>
                            )
                        }
                    </div>

                </div>
                <div className='footer-top hidden md:block'>
                    <div className=' grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 max-w-[1320px] mx-auto px-7  xl:px-28 gap-7 md:gap-0'>
                        {
                            contactInfo.map((item, i) =>
                                <div key={i} className={`py-9 px-5 footer-left border-gray-700 flex items-center justify-center flex-col ${i == 2 ? 'footer-right' : undefined}`}>
                                    <img className='h-11'
                                        src={item.icon}
                                    />
                                    <h3 className={`${i == 1 ? 'text-lg' : 'text-xl'} text-white text-center ${i === 1 && 'emailName '}`}>{item.details}</h3>
                                </div>
                            )
                        }
                    </div>

                </div>
                <div className='max-w-[1320px] mx-auto px-4 xl:px-28  py-12'>
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7'>
                        {
                            aboutInfo.map((item, i) =>
                                <div key={i} className={` flex flex-col  items-center lg:items-start  w-full  ${i === 5 && 'hidden'}`}>
                                    <h3 className='text-3xl textColorLime text-center lg:text-left'>{item.title}</h3>
                                    {
                                        item.details &&
                                        <p className='text-lg text-white pt-7 leading-6 text-center lg:text-left'>{item.details}</p>
                                    }
                                    <div>
                                        {
                                            i === 0 &&
                                            <img
                                                src={logo}
                                                className='h-24 mt-3'
                                            />
                                        }
                                    </div>
                                    <div>
                                        {
                                            i === 1 &&
                                            <div className='grid grid-col-1 gap-7 pt-7'>
                                                {
                                                    item?.sites?.map((site, i) =>
                                                        <a
                                                            key={i}
                                                            href={site?.link}
                                                            target={'_blank'}
                                                            className='text-lg text-white hover:text-lime-600 transiton-all duration-300 ease-in-all'
                                                        >{site?.siteName}</a>
                                                    )
                                                }
                                            </div>
                                        }
                                    </div>
                                    <div className='w-full'>
                                        {
                                            i === 2 &&
                                            <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col justify-center lg:justify-start items-center lg:items-start gap-5 pt-7 w-full'>
                                                <input
                                                    type='text'
                                                    placeholder='ইমেইল'
                                                    className={`bg-white px-5 py-3 md:py-5 rounded focus:border-lime-400 focus:outline-none focus:ring focus:ring-lime-300 focus:ring-opacity-40 w-full`}
                                                    {...register('email')}
                                                />
                                                {/* {
                                                    errors.email && <span
                                                        className='text-red-500 text-sm -mt-3'
                                                    >Please Provide a Valid email</span>
                                                } */}
                                                <button
                                                    type='submit'
                                                    className={`lab-btn-footer`}

                                                >

                                                    সাবমিট
                                                    <RiSendPlaneFill
                                                        className='text-xl'
                                                    />
                                                </button>
                                            </form>
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className='footer_bt py-7 px-6'>
                    <p className='text-center text:lg md:text-lg text-white'>©২০২৩ শায়খ আহমাদুল্লাহ | কারিগরি সহায়তায়  <a
                        href='https://assunnahfoundation.org/'
                        target={'_blank'}
                        className='textColorLime font-bold'
                    >আস-সুন্নাহ ফাউন্ডেশন আইটি</a></p>
                </div>
            </div>
        </div>
    );
};

export default NewFooter;