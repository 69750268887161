import React, { useEffect, useRef, useState } from 'react';
import Spinner from '../../../../components/Spinner';
import EmptyCompoWIthCLear from '../../../../components/EmptyCompoWIthCLear';
import { useDispatch, useSelector } from 'react-redux';
import { getRejectedApplications, getApplicationSummary } from '../../../../redux/actions/applicationsActions';
import subDistricts from '../../../../json/subDistricts'
import Header from '../../../../components/Header';
import Filter from '../../../../components/Filter';
import Search from '../../../../components/Search';
import Table from '../../../../components/ApplicationTable';
import Pagination from '../../../../components/Pagination';
import cityCorporations from '../../../../json/cityCorporations';

const RejectedApplications = ({ type }) => {
    const dispatch = useDispatch();
    const [subDistrictArray, setSubDistrictArray] = useState([]);
    const [cityCorporationArray, setCityCorporationArray] = useState([]);
    const serarchRef = useRef()
    const [search, setSearch] = useState("")
    const [sort, setSort] = useState(1)
    const [sortBy, setSortBy] = useState('createdAt')
    const { loading, rejectedApplications, rejectedApplicationCount, rejectedCount } = useSelector(state => state.applications)

    const [numOfApplications, setNumOfApplications] = useState()

    const [page, setPage] = useState(Number(localStorage.getItem("rejectedAppPage")) || 0);
    const [limit, setLimit] = useState(Number(localStorage.getItem("rejectedAppItem")) || 10);
    const pages = Math.ceil(rejectedCount ? rejectedCount / limit : rejectedApplicationCount / limit);

    const [isFirstRun, setIsFirstRun] = useState(true);

    const [districtId, setDistrictId] = useState()
    const [subDistrictId, setSubDistrictId] = useState()
    const [cityCorporation, setCityCorporation] = useState('')
    const [institutionId, setInstitutionId] = useState()
    const [timeoutFunction, setTimeoutFunction] = useState(false)

    useEffect(() => {
        if (rejectedApplicationCount === 0) {
            dispatch(getApplicationSummary());
        }
    }, [])

    useEffect(() => {
        setNumOfApplications(rejectedCount ?? rejectedApplicationCount)
    }, [rejectedCount, rejectedApplicationCount])

    useEffect(() => {
        dispatch(getRejectedApplications({ page: page.toString(), limit, type, sort, sortBy }))
    }, [])

    useEffect(() => {
        if (isFirstRun) {
            setIsFirstRun(false);
            return;
        }
        if (page || limit || districtId || subDistrictId || institutionId || cityCorporation)
            if ((subDistrictId !== 'city-corporation' && !cityCorporation) || (subDistrictId === 'city-corporation' && cityCorporation)) { dispatch(getRejectedApplications({ page: page.toString(), limit, subDistrictId, districtId, institutionId, search, type, sort, sortBy, cityCorporation })) }
    }, [page, limit, districtId, subDistrictId, institutionId, sort, cityCorporation])


    // after updating application list, if list is empty then go prev page if page is not first page
    useEffect(() => {
        if (isFirstRun) {
            setIsFirstRun(false);
            return;
        }
        if (page != 0 && rejectedApplications.length === 0) {
            handleSetPage(Number(page) - 1)
        }
    }, [rejectedApplications])

    const handleSearch = (e) => {
        setSearch(e.target.value)
        setPage(0)
    }

    useEffect(() => {
        if (isFirstRun) {
            setIsFirstRun(false);
            return;
        }
        debounceSearch()
    }, [search])

    const debounceSearch = () => {
        clearTimeout(timeoutFunction);
        const fn = setTimeout(() => {
            dispatch(getRejectedApplications({ page: page.toString(), limit, subDistrictId, districtId, institutionId, search, type, sort, sortBy }))
        }, 1000)
        setTimeoutFunction(fn);
    }

    useEffect(() => {
        const subDistArray = [];
        for (const key in subDistricts) {
            if (subDistricts[key].district_id == districtId) {
                subDistArray.push({ subDistrictId: subDistricts[key].id, subDistrictName: subDistricts[key].bn_name })
                setSubDistrictArray(prev => subDistArray);
            }
        }
        setCityCorporationArray(cityCorporations[districtId] ?? [])
    }, [districtId])

    const handleSizeChange = (event) => {
        const newSize = parseInt(event.target.value);
        setLimit(newSize);
        setPage(0);
        localStorage.setItem('rejectedAppItem', newSize)
    };

    // next and prev of pagination
    const handlePrevClick = () => {
        setPage(page - 1);
    };

    const handleNextClick = () => {
        setPage(page + 1);
    };

    const handleFirstPageClick = () => {
        setPage(0);
        localStorage.setItem("rejectedAppPage", 0);
    };

    const handleLastPageClick = () => {
        setPage(pages - 1);
        const lastpage = pages - 1;
        localStorage.setItem("rejectedAppPage", lastpage);
    };

    const handleSetPage = (num) => {
        setPage(num)
        localStorage.setItem("rejectedAppPage", num);
    }

    return (
        <section className="w-full">
            <Header allApplicationCount={rejectedApplicationCount} />
            <div className="mt-6 md:flex md:items-center md:justify-between mx-4">
                <Filter
                    subDistrictArray={subDistrictArray}
                    setSubDistrictArray={setSubDistrictArray}
                    setInstitutionId={setInstitutionId}
                    setDistrictId={setDistrictId}
                    setPage={setPage}
                    setSubDistrictId={setSubDistrictId}
                    districtId={districtId}
                    subDistrictId={subDistrictId}
                    institutionId={institutionId}
                    cityCorporationArray={cityCorporationArray}
                    setCityCorporationArray={setCityCorporationArray}
                    cityCorporation={cityCorporation}
                    setCityCorporation={setCityCorporation}
                />
                <Search
                    serarchRef={serarchRef}
                    search={search} setSearch={setSearch}
                    handleSearch={handleSearch}
                />
            </div>
            {
                loading ?
                    <Spinner />
                    :
                    rejectedApplications?.length === 0 ?
                        <EmptyCompoWIthCLear
                            search={search}
                            institutionId={institutionId}
                            districtId={districtId}
                            subDistrictId={subDistrictId}
                        />
                        :
                        <Table
                            sort={sort}
                            setSort={setSort}
                            setSortBy={setSortBy}
                            applications={rejectedApplications}
                            applicationCount={rejectedApplicationCount}
                            page={page}
                            limit={limit}
                            type={type}
                        />
            }

            {numOfApplications ?
                <Pagination
                    page={page}
                    pages={pages}
                    numOfApplications={numOfApplications}
                    handlePrevClick={handlePrevClick}
                    handleNextClick={handleNextClick}
                    limit={limit}
                    handleSizeChange={handleSizeChange}
                    handleFirstPageClick={handleFirstPageClick}
                    handleSetPage={handleSetPage}
                    handleLastPageClick={handleLastPageClick}
                />
                : ''}
        </section>
    );
};

export default RejectedApplications;