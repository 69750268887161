import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { applicationReducer } from './reducers/applicationsReducers'
import { authReducer } from './reducers/authReducers'


const reducers = combineReducers({
    applications: applicationReducer,
    auth: authReducer
})

const initialState = {

}

const middlewares = [thunk]

const store = createStore(reducers, initialState, composeWithDevTools(applyMiddleware(...middlewares)))

export default store